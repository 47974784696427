import React, { useEffect, useCallback, useState } from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
  Select,
  TextArea,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as groupPermissionsService from "../../services/configuration_permissions/groupsPermissionsService";
import PermissionSubmoduleContent from "../PermissionSubmoduleContent";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { USERS_ROLE_ENUMS } from "../../constants";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Le nom doit contenir au moins 2 caractères")
    .required("Le nom est requis"),
  description: yup
    .string()
    .max(100, "La description ne doit pas dépasser 100 caractères"),
  permissions: yup.object().shape({}),
  selectedPermissions: yup.object().shape({}),
  type: yup.string().required("Le type est requis"),
});

function GroupPermissionEditModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedGroupPermissionId,
  handleClose = () => {},
}) {
  const [moduleAccordions, setModuleAccordions] = useState({});
  const [submoduleAccordions, setSubmoduleAccordions] = useState({});
  const authValue = useRecoilValue(authState);

  const formik = useFormik({
    initialValues: {
      nom: "",
      description: "",
      permissions: {},
      selectedPermissions: {},
      type: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedGroupPermissionId;
        const dataToSend = {
          name: values.name,
          description: values.description,
          permissions: formik.values.selectedPermissions,
          type: values.type,
        };
        const serviceCall = isEditMode
          ? groupPermissionsService.update(
              selectedGroupPermissionId,
              dataToSend
            )
          : groupPermissionsService.create(dataToSend);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          toast.success("Groupe de permissions modifié avec succès");
          callback();
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchBasePermissions = async (type) => {
    try {
      const response = await groupPermissionsService.fetchBasePermissions(type);
      formik.setFieldValue("permissions", response.data);

      // Si on est en mode création, réinitialiser les permissions sélectionnées
      if (!selectedGroupPermissionId) {
        formik.setFieldValue("selectedPermissions", {});
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération des permissions de base: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const fetchGroupPermission = useCallback(async () => {
    if (!selectedGroupPermissionId) return;

    try {
      const response = await groupPermissionsService.fetchOne(
        selectedGroupPermissionId
      );
      const groupPermissionData = response.data;

      // Mise à jour des valeurs du formulaire
      formik.setValues({
        name: groupPermissionData.name,
        description: groupPermissionData.description,
        type: groupPermissionData.type,
        selectedPermissions: groupPermissionData.permissions || {},
        permissions: formik.values.permissions,
      });

      if (groupPermissionData.type) {
        await fetchBasePermissions(groupPermissionData.type);
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération du groupe de permissions: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  }, [selectedGroupPermissionId]);

  const hasPermissionChanged = (oldPermissions, newPermissions) => {
    return JSON.stringify(oldPermissions) !== JSON.stringify(newPermissions);
  };

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  useEffect(() => {
    if (selectedGroupPermissionId) {
      fetchGroupPermission();
    } else {
      formik.resetForm();
      setModuleAccordions({});
      setSubmoduleAccordions({});
    }
  }, [selectedGroupPermissionId, fetchGroupPermission]);

  const getPermissionTypeOptions = () => {
    let options = [
      { text: "Choisir le type", value: "" },
      { text: "POOL", value: "pool" },
      { text: "INTERMEDIAIRE (COURTIER)", value: "broker" },
      { text: "COMPAGNIE", value: "company" }
    ];
    if (authValue?.user?.user_role == USERS_ROLE_ENUMS.COMPANY_USER) {
      options = [
        { text: "Choisir le type", value: "" },
        { text: "INTERMEDIAIRE (AGENT GENERAL)", value: "agent_general" },
        { text: "COMPAGNIE", value: "company" },
        { text: "AGENCE", value: "agency" },
      ];
    } else if (
      authValue?.user?.user_role == USERS_ROLE_ENUMS.AGENT_GENERAL_USER
    ) {
      options = [
        { text: "Choisir le type", value: "" },
        { text: "ENTREPRISE", value: "agent_general" },
        { text: "AGENCE", value: "agency" },
      ];
    } else if (
      authValue?.user?.user_role == USERS_ROLE_ENUMS.COURTIER_USER
    ) {
      options = [
        { text: "Choisir le type", value: "" },
        { text: "ENTREPRISE", value: "broker" },
        { text: "AGENCE", value: "agency" },
      ];
    }

    return options;
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size="fullscreen"
      closeIcon
      style={{
        width: "100%",
        height: "100%",
        margin: "0",
        padding: "0",
        top: "0",
        left: "0",
        borderRadius: "0",
        overflow: "hidden",
      }}
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <Modal.Header>
        {selectedGroupPermissionId
          ? "Modifier un groupe de permissions"
          : "Ajouter un groupe de permissions"}
      </Modal.Header>
      <Modal.Content style={{ height: "100%" }}>
        <Modal.Description>
          <Grid>
            <Grid.Column width={4}>
              <Segment raised>
                <Form.Field required error={!!formik.errors.name}>
                  <label>Nom</label>
                  <Input
                    placeholder="Entrez le nom"
                    value={formik.values.name}
                    onChange={(e, { value }) => {
                      formik.setFieldValue("name", value);
                    }}
                    error={formik.touched.name && formik.errors.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <small className="field-error" style={{ color: "red" }}>
                      {formik.errors.name}
                    </small>
                  )}
                </Form.Field>
                <Form.Field required error={!!formik.errors.type}>
                  <label>Type</label>
                  <Select
                    options={getPermissionTypeOptions()}
                    value={formik.values.type}
                    onChange={(e, { value }) => {
                      formik.setFieldValue("type", value);
                      fetchBasePermissions(value);
                    }}
                    error={formik.touched.type && formik.errors.type}
                  />
                </Form.Field>
                <Form.Field required error={!!formik.errors.description}>
                  <label>Description</label>
                  <TextArea
                    placeholder="Entrez la description"
                    value={formik.values.description}
                    onChange={(e, { value }) => {
                      formik.setFieldValue("description", value);
                    }}
                    error={
                      formik.touched.description && formik.errors.description
                    }
                  />
                  {formik.touched.description && formik.errors.description && (
                    <small className="field-error" style={{ color: "red" }}>
                      {formik.errors.description}
                    </small>
                  )}
                </Form.Field>
                <Button
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  positive
                  loading={formik.isSubmitting}
                  disabled={!formik.isValid || formik.isSubmitting}
                  fluid
                >
                  {selectedGroupPermissionId ? "Modifier" : "Enregistrer"}
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment
                raised
                className="max-h-[calc(100vh-100px)] overflow-y-auto"
              >
                {formik.values.type !== "" &&
                Object.keys(formik.values.permissions).length > 0 ? (
                  <>
                    <Header dividing as="h3">
                      Permissions
                    </Header>

                    {Object.entries(formik.values.permissions).map(
                      ([moduleKey, moduleData], index) => (
                        <Accordion
                          key={`${moduleKey}-${index}`}
                          fluid
                          styled
                          className="!mb-2"
                        >
                          <Accordion.Title
                            active={moduleAccordions[moduleKey]}
                            onClick={() => {
                              setModuleAccordions((prev) => ({
                                ...prev,
                                [moduleKey]: !prev[moduleKey],
                              }));
                            }}
                          >
                            <Icon name="dropdown" />
                            {moduleData.label}
                          </Accordion.Title>
                          <Accordion.Content
                            active={moduleAccordions[moduleKey]}
                          >
                            {/* Permissions du module principal */}
                            {moduleData.permissions &&
                              moduleData.permissions.length > 0 && (
                                <div className="mb-4 ml-4">
                                  <Header as="h5">Permissions générales</Header>
                                  <div className="p-2 flex flex-row flex-wrap gap-4">
                                    {moduleData.permissions.map(
                                      (permission, index) => (
                                        <div
                                          key={`${moduleKey}-${permission.key}-${index}`}
                                          className="mb-2"
                                        >
                                          <Checkbox
                                            toggle
                                            label={`${permission.description}`}
                                            checked={
                                              formik.values
                                                .selectedPermissions?.[
                                                moduleKey
                                              ]?.[permission.key] || false
                                            }
                                            onChange={(e, { checked }) => {
                                              formik.setFieldValue(
                                                `selectedPermissions.${moduleKey}.${permission.key}`,
                                                checked
                                              );
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* Sous-modules avec récursivité */}
                            {moduleData.submodules &&
                              moduleData.submodules.map((submodule, index) => (
                                <PermissionSubmoduleContent
                                  key={`${moduleKey}-${submodule.key}-${index}`}
                                  moduleKey={moduleKey}
                                  submodule={submodule}
                                  formik={formik}
                                  submoduleAccordions={submoduleAccordions}
                                  setSubmoduleAccordions={
                                    setSubmoduleAccordions
                                  }
                                />
                              ))}
                          </Accordion.Content>
                        </Accordion>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <Header dividing as="h3">
                      Permissions
                    </Header>
                    <p>Veuillez choisir le type du groupe de permissions</p>
                  </>
                )}
              </Segment>
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
}

export default GroupPermissionEditModal;
