import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Grid,
  GridRow,
  Image,
  Form,
  Input,
  Header,
  Icon,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as yup from "yup";
import * as authService from "../../services/authService";
import * as userService from "../../services/usersService";
import { useNavigate } from "react-router-dom";
import "../../styles/loginPage.css";
import { useRecoilState } from "recoil";
import { authState } from "../../atoms/authState";
import { getUserBaseRoute, saveUserFromStorage } from "../../utils/auth";
import { TWO_FACTOR_AUTHENTICATION_TIMER } from "../../constants";

const validationSchema = yup.object().shape({
  email: yup.string().email("Email invalide").required("Email requis"),
  password: yup
    .string()
    .min(8, "Mot de passe trop court")
    .max(64, "Mot de passe trop long")
    .required("Mot de passe requis"),
  newPassword: yup
    .string()
    .min(8, "Mot de passe trop court")
    .when("mustChangePassword", {
      is: true,
      then: yup.string().required("Nouveau mot de passe requis"),
      otherwise: yup.string().notRequired(),
    }),
  code: yup.string().when("mustVerifyCode", {
    is: true,
    then: yup.string().required("Code de verification requis"),
    otherwise: yup.string().notRequired(),
  }),
});

const LoginPage = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [mustVerifyCode, setMustVerifyCode] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);
  const [timer, setTimer] = useState(TWO_FACTOR_AUTHENTICATION_TIMER); // 5 minutes in seconds
  const navigate = useNavigate();

  useEffect(() => {
    let interval;

    if (mustVerifyCode && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setCodeExpired(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [mustVerifyCode, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} : ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      newPassword: "",
      code: "",
      mustChangePassword: mustChangePassword,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!mustChangePassword) {
        authService
          .login({
            email: formik.values.email,
            password: formik.values.password,
            two_factor_authentication_code: formik.values.code
              ? formik.values.code
              : undefined,
          })
          .then(
            (response) => {
              let baseRoute = getUserBaseRoute(response.data);
              if (baseRoute === false) {
                return toast(`Type d'utilisateur non supporté!`, {
                  type: "error",
                  theme: "colored",
                });
              }
              saveUserFromStorage(response.data);
              setAuthValue(response.data);
              navigate(`/${baseRoute}`);
            },
            (reason) => {
              let changePassword = reason?.response?.data?.must_change_password;
              let codeSent =
                reason?.response?.data?.two_factor_authentication_code_sent;
              let codeExpired =
                reason?.response?.data?.two_factor_authentication_code_expired;
              let codeIncorrect =
                reason?.response?.data
                  ?.two_factor_authentication_code_incorrect;
              if (changePassword) {
                setMustChangePassword(changePassword);
                toast(
                  `Veuillez renseigner votre nouveau mot de passe pour continuer.`,
                  {
                    type: "success",
                    theme: "colored",
                  }
                );
              } else if (codeSent) {
                setMustVerifyCode(true);
                setTimer(TWO_FACTOR_AUTHENTICATION_TIMER);
                toast(
                  `Veuillez renseigner votre code de verification envoyé a votre e-mail.`,
                  {
                    type: "success",
                    theme: "colored",
                  }
                );
              } else if (codeExpired) {
                setCodeExpired(true);
                toast(
                  `Votre code de vérification a expiré. Veuillez demander un nouveau code de vérification.`,
                  {
                    type: "error",
                    theme: "colored",
                  }
                );
              } else if (codeIncorrect) {
                toast(
                  `Le code de vérification est incorrect. Veuillez réessayer.`,
                  {
                    type: "error",
                    theme: "colored",
                  }
                );
              } else {
                toast(`Votre email ou votre mot de passe est incorrect.`, {
                  type: "error",
                  theme: "colored",
                });
              }
            }
          )
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        // change password
        userService
          .changePassword({
            email: formik.values.email,
            oldPassword: formik.values.password,
            newPassword: formik.values.newPassword,
          })
          .then(
            (response) => {
              toast(
                `Mot de passe changé avec succès. Veuillez vous connecter avec le nouveau mot de passe.`,
                {
                  type: "success",
                  theme: "colored",
                }
              );
              setMustChangePassword(false);
              formik.setValues({
                ...formik.values,
                password: "",
              });
            },
            (reason) => {
              toast(
                `Échec de la modification de votre mot de passe. Veuillez réessayer.`,
                {
                  type: "error",
                  theme: "colored",
                }
              );
            }
          )
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;
  return (
    <Fragment>
      <Helmet>
        <title>SIGAS - TPV | Connexion</title>
      </Helmet>
      <GridRow className="LoginPage" style={{ backgroundColor: "#FFFF" }}>
        <Grid style={{ height: "100%" }}>
          <Grid.Column className="left-box">
            <Header as={"h1"} className="!mb-1">
              SIGAS - TPV
            </Header>
            <Header as={"h2"} className="!text-white !mt-0">
              Portail de coassurance
            </Header>
          </Grid.Column>
          <Grid.Column className="right-box">
            <Grid.Row>
              <div className="text center">
                <Icon name="lock" color="blue" size="huge" circular />
              </div>
              <Header as={"h6"} className="text center" color={"grey"}>
                Connectez vous à votre espace d'administration.
              </Header>
              <Form>
                {!mustVerifyCode && (
                  <Form.Field required error={"email" in errors}>
                    <label>Email</label>
                    <Input
                      value={values.email}
                      disabled={mustChangePassword || mustVerifyCode}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      type="text"
                    />
                    <small className="field-error">{errors.email}</small>
                  </Form.Field>
                )}
                {!mustVerifyCode && (
                  <Form.Field required error={"password" in errors}>
                    <label>Mot de passe</label>
                    <Input
                      value={values.password}
                      disabled={mustChangePassword || mustVerifyCode}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      type={secureTextEntry ? "password" : "text"}
                      icon={
                        <Icon
                          name={secureTextEntry ? "eye" : "eye slash"}
                          link
                          onClick={() => setSecureTextEntry(!secureTextEntry)}
                        />
                      }
                    />
                    <small className="field-error">{errors.password}</small>
                  </Form.Field>
                )}

                {mustChangePassword ? (
                  <Form.Field required error={"newPassword" in errors}>
                    <label>Nouveau mot de passe</label>
                    <Input
                      value={values.newPassword}
                      onChange={handleChange("newPassword")}
                      onBlur={handleBlur("newPassword")}
                      type={secureTextEntry ? "password" : "text"}
                      icon={
                        <Icon
                          name={secureTextEntry ? "eye" : "eye slash"}
                          link
                          onClick={() => setSecureTextEntry(!secureTextEntry)}
                        />
                      }
                    />
                    <small className="field-error">{errors.newPassword}</small>
                  </Form.Field>
                ) : null}

                {mustVerifyCode ? (
                  <Form.Field required error={"code" in errors}>
                    <label>Code de verification</label>
                    <Input
                      value={values.code}
                      onChange={handleChange("code")}
                      onBlur={handleBlur("code")}
                      type={secureTextEntry ? "password" : "text"}
                      icon={
                        <Icon
                          name={secureTextEntry ? "eye" : "eye slash"}
                          link
                          onClick={() => setSecureTextEntry(!secureTextEntry)}
                        />
                      }
                    />
                    <small className="field-error">{errors.code}</small>
                  </Form.Field>
                ) : null}

                {mustVerifyCode && timer ? (
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <p>
                      Le code expirera dans :{" "}
                      <strong> {formatTime(timer)}</strong>
                    </p>
                  </div>
                ) : null}

                {codeExpired && (
                  <div
                    style={{
                      color: "#007BFF", // A good link blue

                      textDecoration: "underline",
                      cursor: "pointer",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      formik.setValues({ ...formik.values, code: "" });
                      setCodeExpired(false);
                      formik.submitForm();
                    }}
                  >
                    <span>Demander un nouveau code de vérification</span>
                  </div>
                )}

                <Button
                  type="submit"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  color="blue"
                  disabled={mustChangePassword && !formik.values.newPassword}
                  fluid
                >
                  {mustChangePassword
                    ? "Confirmer le nouveau mot de passe"
                    : mustVerifyCode
                    ? "Verifier"
                    : "Se connecter"}
                </Button>
              </Form>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </GridRow>
    </Fragment>
  );
};

export default LoginPage;
