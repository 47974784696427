import React, { useEffect, useState } from "react";
import {
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  List,
  Segment,
  Table,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import Page from "../../../../../components/Page";
import * as magasinStockService from "../../../../../services/gestion_attestations/company/magasinsStockService";
import MagasinStockTransfertFormModal from "../../../../../components/modalForms/gestion_attestations/MagasinStockTransfertFormModal";
import numeral from "numeral";
import StockDoughnutChart from "../../../../../components/charts/StockDoughnutChart";
import PermissionWrapper from "../../../../../components/PermissionWrapper";

function MagasinStockDetails() {
  const [magasinStock, setMagasinStock] = useState(null);
  const [openTransfertModal, setOpenTransfertModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [transferts, setTransferts] = useState([]);

  const { uuid } = useParams();

  const fetchMagasinStock = async () => {
    try {
      const response = await magasinStockService.fetchOne(uuid);
      setMagasinStock(response.data);
    } catch (error) {
      toast.error("Une erreur est survenue lors du chargement du stock");
    }
  };

  const fetchTransfertsByMagasinStock = async () => {
    try {
      const response = await magasinStockService.fetchTransfertsByMagasinStock(
        uuid
      );
      setTransferts(response.data);
    } catch (error) {
      toast.error("Une erreur est survenue lors du chargement des transferts");
    }
  };

  const handleDeleteTransfert = async (uuid) => {
    try {
      Swal.fire({
        title: "Suppression du transfert",
        text: "Voulez-vous vraiment supprimer ce transfert ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await magasinStockService.transferDelete(uuid);
          toast.success("Transfert supprimé");
          fetchMagasinStock();
          fetchTransfertsByMagasinStock();
        }
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la suppression du transfert"
      );
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchMagasinStock();
      fetchTransfertsByMagasinStock();
    }
  }, [uuid]);

  return (
    <Page title="Détails du stock">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/stocks/magasins">
                Magasins
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to={`/company/stocks/magasins/stocks/${magasinStock?.attestations_magasins?.uuid}`}
              >
                Stocks
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Détails du stock</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Détails du stock</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Magasin</List.Header>
                      <List.Description>
                        {magasinStock?.attestations_magasins?.name}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Plage de série</List.Header>
                      <List.Description>
                        {magasinStock?.start_serial
                          ? `${magasinStock?.start_serial} - ${magasinStock?.end_serial}`
                          : "-"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de livraison</List.Header>
                      <List.Description>
                        {dayjs(magasinStock?.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>

          <>
            <StockDoughnutChart
              total={magasinStock?.usage?.totalQty}
              consumed={magasinStock?.usage?.consumedQty}
              remaining={magasinStock?.usage?.remainingQty}
            />
            <GridRow as={Segment}>
              <div className="ui label top attached !flex !justify-between">
                <h3>Transferts des attributions</h3>
                <PermissionWrapper
                  requiredPermissions={[
                    "company.attestations.stock.store.transfer",
                  ]}
                >
                <div
                  className="basic"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedItemId(null);
                    setOpenTransfertModal(true);
                  }}
                >
                  <Icon name="add" />
                  Transférer à un magasin
                </div>
                </PermissionWrapper>
              </div>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Numéro</Table.HeaderCell>
                    <Table.HeaderCell>Magasin</Table.HeaderCell>
                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                    <Table.HeaderCell>Plage</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {transferts.map((transfert) => (
                    <Table.Row key={transfert.idattestations_magasins_stocks}>
                      <Table.Cell>
                        {transfert.idattestations_magasins_stocks
                          .toString()
                          .padStart(6, "0")}
                      </Table.Cell>
                      <Table.Cell>
                        {transfert.attestations_magasins?.name}
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(
                          parseInt(transfert.end_serial) -
                            parseInt(transfert.start_serial) +
                            1
                        ).format("0,0")}
                      </Table.Cell>
                      <Table.Cell>
                        {transfert.start_serial
                          ? `${transfert.start_serial} - ${transfert.end_serial}`
                          : "-"}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(transfert.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>

                      <Table.Cell>
                        <Dropdown
                          icon="ellipsis vertical"
                          labeled
                          className="icon"
                          pointing="right"
                        >
                          <Dropdown.Menu>
                          <PermissionWrapper
                            requiredPermissions={[
                              "company.attestations.stock.store.details",
                            ]}
                          >
                            <Dropdown.Item
                              icon={"eye"}
                              text="Voir"
                              as={Link}
                              to={`/company/stocks/magasins/stocks/stocks-magasins/${transfert.uuid}`}
                            />
                            </PermissionWrapper>
                            <PermissionWrapper
                              requiredPermissions={[
                                "company.attestations.stock.store.transfer-edit",
                              ]}
                            >
                            <Dropdown.Item
                              icon="edit"
                              text="Modifier"
                              onClick={() => {
                                setSelectedItemId(transfert.uuid);
                                setOpenTransfertModal(true);
                              }}
                            />
                            </PermissionWrapper>
                            <PermissionWrapper
                              requiredPermissions={[
                                "company.attestations.stock.store.transfer-delete",
                              ]}
                            >
                            <Dropdown.Item
                              icon="trash"
                              text="Supprimer"
                              onClick={() => {
                                handleDeleteTransfert(transfert.uuid);
                              }}
                            />
                            </PermissionWrapper>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </GridRow>
          </>
        </>
      </GridRow>
      {magasinStock?.attestations_magasins?.uuid && openTransfertModal && (
        <MagasinStockTransfertFormModal
          open={openTransfertModal}
          setOpen={setOpenTransfertModal}
          selectedItemId={selectedItemId}
          magasinStockUuid={uuid}
          handleCloseModal={() => {
            setSelectedItemId(null);
            setOpenTransfertModal(false);
          }}
          attestationMagasinUuid={magasinStock?.attestations_magasins?.uuid}
          callback={() => {
            fetchMagasinStock();
            fetchTransfertsByMagasinStock();
          }}
        />
      )}
    </Page>
  );
}

export default MagasinStockDetails;
