import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  GridRow,
  Header,
  Input,
  Segment,
  Form,
  Divider,
} from "semantic-ui-react";
import DaylyProductionChart from "../../components/charts/DaylyProductionLineChart";
import DaylyProductionPieChart from "../../components/charts/DaylyProductionPieChart";
import DaylyProductionBarChart from "../../components/charts/DaylyProductionBarChart";
import ValidatedSouscriptionsChart from "../../components/charts/ValidatedSouscriptionsChart";
import { authState } from "../../atoms/authState";
import Page from "../../components/Page";
import { useFormik } from "formik";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import * as statisticsService from "../../services/statisticsService";
import dayjs from "dayjs";
import currency from "currency.js";
import { USERS_ROLE_ENUMS } from "../../constants";

const validationSchema = yup.object().shape({
  company: yup.number().notRequired(),
  start_date: yup.date().required("La selection de date est obligatoire"),
  end_date: yup.date().required("La selection de date est obligatoire"),
});

function Dashboard() {
  const [statisticsData, setStatisticsData] = useState();
  const [companies, setCompanies] = useState([]);
  const [intermediaires, setIntermediaires] = useState([]);
  const authValue = useRecoilValue(authState);

  // Get default dates
  const today = dayjs().format("YYYY-MM-DD"); // Current date
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD"); // First day of the current month

  const formik = useFormik({
    initialValues: {
      start_date: firstDayOfMonth, // Default to the first day of the current month
      end_date: today, // Default to today
      company: 0, // Default to today
      intermediaire: 0, // Default to today
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchStatisticsData = async () => {
    try {
      let requestData = {
        type: "PRODUCTION",
        start_date: formik.values.start_date,
        end_date: formik.values.end_date,
      };
      if (formik.values.company) {
        requestData.company_id = formik.values.company;
      }
      if (formik.values.intermediaire) {
        requestData.intermediaire_id = formik.values.intermediaire;
      }
      const response = await statisticsService.fetchStatistics(requestData);
      let data = response.data;
      if (response.status === 200 && data) {
        setStatisticsData(data);

        if (data?.production?.entities?.companies) {
          const formattedCompanies = data.production.entities.companies.map(
            (company) => ({
              key: `comp-${company.idcompagnies}`, // Unique key
              text: company.denomination, // Display text
              value: company.idcompagnies, // Value
            })
          );

          // Add default 'N/A' option
          setCompanies([
            {
              key: "Toutes les compagnies",
              text: "Toutes les compagnies",
              value: 0,
            },
            ...formattedCompanies,
          ]);
        }

        if (data?.production?.entities?.courtiers) {
          const formattedIntermediaires =
            data.production.entities.courtiers.map((courtier) => ({
              key: `court-${courtier.idintermediaires}`, // Unique key
              text: courtier.denomination, // Display text
              value: courtier.idintermediaires, // Value
            }));

          // Add default 'N/A' option
          setIntermediaires([
            {
              key: "Tous les intermediaires",
              text: "Tous les intermediaires",
              value: 0,
            },
            ...formattedIntermediaires,
          ]);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (formik.values.end_date && formik.values.end_date) {
      fetchStatisticsData();
    }
  }, [formik.values.end_date, formik.values, formik.company]);

  return (
    <Page title="Dashboard">
      <GridRow className="DashBoard">
        <Grid stackable>
          <Grid.Column
            width={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Tableau de bord</Header>
          </Grid.Column>

          <Grid.Column
            width={14}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Form>
              <Form.Group>
                <Form.Field error={"start_date" in errors}>
                  <label>Date de début</label>
                  <Input
                    type="date"
                    value={values.start_date}
                    onChange={handleChange("start_date")}
                    onBlur={handleBlur("start_date")}
                  />
                  <small className="field-error">{errors.start_date}</small>
                </Form.Field>

                <Form.Field error={"end_date" in errors}>
                  <label>Date de fin</label>
                  <Input
                    type="date"
                    value={values.end_date}
                    onChange={handleChange("end_date")}
                    onBlur={handleBlur("end_date")}
                  />
                  <small className="field-error">{errors.end_date}</small>
                </Form.Field>
                {[
                  USERS_ROLE_ENUMS.POOL_USER,
                  USERS_ROLE_ENUMS.ROOT_ADMIN,
                ].includes(authValue?.user?.user_role) ? (
                  <>
                    <Form.Field error={"company" in errors}>
                      <label>Compagnie d'assurance</label>
                      <Form.Select
                        search
                        value={values.company}
                        fluid
                        onChange={(e, data) =>
                          setFieldValue("company", data.value)
                        }
                        placeholder="Choisr..."
                        options={companies}
                      />
                    </Form.Field>
                    <Form.Field error={"intermediaire" in errors}>
                      <label>Intermediaire (Courtier)</label>
                      <Form.Select
                        search
                        value={values.intermediaire}
                        fluid
                        onChange={(e, data) =>
                          setFieldValue("intermediaire", data.value)
                        }
                        placeholder="Choisr..."
                        options={intermediaires}
                      />
                    </Form.Field>
                  </>
                ) : null}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>

        {/*<Grid stackable>
          <Grid.Column style={{ display: "flex" }}>
            <Form>
              <Form.Group>
                <Form.Field error={"start_date" in errors}>
                  <label>Date de début</label>
                  <Input
                    type="date"
                    value={values.start_date}
                    onChange={handleChange("start_date")}
                    onBlur={handleBlur("start_date")}
                  />
                  <small className="field-error">{errors.start_date}</small>
                </Form.Field>

                <Form.Field error={"end_date" in errors}>
                  <label>Date de fin</label>
                  <Input
                    type="date"
                    value={values.end_date}
                    onChange={handleChange("end_date")}
                    onBlur={handleBlur("end_date")}
                  />
                  <small className="field-error">{errors.end_date}</small>
                </Form.Field>

                 <Form.Field error={"company" in errors}>
                  <label>Compagnie d'assurance</label>
                  <Form.Select
                    search
                    value={values.company}
                    fluid
                    onChange={(e, data) => setFieldValue("company", data.value)}
                    placeholder="Choisr..."
                    options={[
                      { key: "N/A", text: "N/A", value: 0 },
                      { key: "k1", text: "Com 1", value: 23 },
                      { key: "k2", text: "Com 2", value: 43 },
                    ]}
                  />
                  <small className="field-error">{errors.company}</small>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid> */}

        {statisticsData && (
          <>
            {/*

            <label>Souscriptions</label>
            <Segment>
              <Grid
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Souscriptions Totales</label>
                  <span style={{ fontWeight: "bold", color: "#2196F3" }}>
                    {statisticsData?.production?.meta
                      ?.totalSouscriptionsCount || 0}
                  </span>
                </Grid.Column>
                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Souscriptions Payées</label>

                  <span style={{ fontWeight: "bold", color: "#4CAF50" }}>
                    {statisticsData?.production?.meta
                      ?.totalPaidSouscriptionsCount || 0}
                  </span>
                </Grid.Column>

                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Souscriptions Impayées</label>

                  <span style={{ fontWeight: "bold", color: "#F44336" }}>
                    {statisticsData?.production?.meta
                      ?.totalUnPaidSouscriptionsCount || 0}
                  </span>
                </Grid.Column>

                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Souscriptions Suspendues</label>

                  <span style={{ fontWeight: "bold", color: "#9E9E9E" }}>
                    {statisticsData?.production?.meta
                      ?.totalSuspendedSouscriptionsCount || 0}
                  </span>
                </Grid.Column>

                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Souscriptions Résiliées</label>

                  <span style={{ fontWeight: "bold", color: "#FF9800" }}>
                    {statisticsData?.production?.meta
                      ?.totalResiliatedSouscriptionsCount || 0}
                  </span>
                </Grid.Column>
              </Grid>
            </Segment>

  */}

            <Grid>
              <Grid.Column width={16}>
                <label>Chiffre d’affaires / Montant des frais de gestion</label>
                <Divider />

                <Grid
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid.Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "370px",
                    }}
                  >
                    <label>Chiffre d’affaires </label>
                    <span style={{ fontWeight: "bold", color: "#2196F3" }}>
                      {currency(
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsAmountPaid || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                    </span>
                  </Grid.Column>
                  <Grid.Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "300px",
                    }}
                  >
                    <label>Frais de gestion</label>

                    <span style={{ fontWeight: "bold", color: "#4CAF50" }}>
                      {currency(
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsFeesAmountPaid || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                    </span>
                  </Grid.Column>
                </Grid>

                <Segment>
                  <Grid>
                    <DaylyProductionChart
                      start_date={formik.values.start_date}
                      end_date={formik.values.end_date}
                      statisticsData={statisticsData}
                      data_type="souscriptions_chiffresaffaires_and_fees"
                    />
                  </Grid>

                  {[
                    USERS_ROLE_ENUMS.POOL_USER,
                    USERS_ROLE_ENUMS.ROOT_ADMIN,
                  ].includes(authValue?.user?.user_role) ? (
                    <Grid columns={2}>
                      <Grid.Column>
                        <DaylyProductionPieChart
                          start_date={formik.values.start_date}
                          end_date={formik.values.end_date}
                          statisticsData={statisticsData}
                          data_type="souscriptions_companies_and_intermediaires_chiffresaffaires"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DaylyProductionPieChart
                          start_date={formik.values.start_date}
                          end_date={formik.values.end_date}
                          statisticsData={statisticsData}
                          data_type="souscriptions_companies_and_intermediaires_fraisgestion"
                        />
                      </Grid.Column>
                    </Grid>
                  ) : null}
                </Segment>
              </Grid.Column>
            </Grid>

            {[USERS_ROLE_ENUMS.POOL_USER, USERS_ROLE_ENUMS.ROOT_ADMIN].includes(
              authValue?.user?.user_role
            ) ? (
              <Grid>
                <Grid.Column>
                  <label> Chiffre d’affaires (Compagnies/Intermidiaires)</label>
                  <Segment>
                    <DaylyProductionChart
                      start_date={formik.values.start_date}
                      end_date={formik.values.end_date}
                      statisticsData={statisticsData}
                      data_type="souscriptions_companies_and_intermediaires_chiffresaffaires"
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            ) : null}

            {[USERS_ROLE_ENUMS.POOL_USER, USERS_ROLE_ENUMS.ROOT_ADMIN].includes(
              authValue?.user?.user_role
            ) ? (
              <Grid>
                <Grid.Column>
                  <label>Frais de gestion (Compagnies/Intermidiaires)</label>
                  <Segment>
                    <DaylyProductionChart
                      start_date={formik.values.start_date}
                      end_date={formik.values.end_date}
                      statisticsData={statisticsData}
                      data_type="souscriptions_companies_and_intermediaires_fraisgestion"
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            ) : null}

            <Grid>
              <Grid.Column width={16}>
                <Divider />
                <label>Vehicules</label>

                <Grid
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid.Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "370px",
                    }}
                  >
                    <label>Nombre total de Vehicules</label>
                    <span style={{ fontWeight: "bold", color: "#390099" }}>
                      {statisticsData?.production?.meta?.totalVoituresCount ||
                        0}
                    </span>
                  </Grid.Column>
                  <Grid.Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "300px",
                    }}
                  >
                    <label>Nombre de véhicule assurée</label>

                    <span style={{ fontWeight: "bold", color: "#9e0059" }}>
                      {statisticsData?.production?.meta
                        ?.totalVoituresAssuredCount ||
                        0 -
                          statisticsData?.production?.meta
                            ?.totalSouscriptionsAEcheanceCount ||
                        0}
                    </span>
                  </Grid.Column>

                  <Grid.Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "300px",
                    }}
                  >
                    <label>Nombre de véhicule à l’échéance</label>

                    <span style={{ fontWeight: "bold", color: "#ff5400" }}>
                      {statisticsData?.production?.meta
                        ?.totalSouscriptionsAEcheanceCount || 0}
                    </span>
                  </Grid.Column>
                </Grid>

                {[
                  USERS_ROLE_ENUMS.POOL_USER,
                  USERS_ROLE_ENUMS.ROOT_ADMIN,
                ].includes(authValue?.user?.user_role) ? (
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        <DaylyProductionPieChart
                          start_date={formik.values.start_date}
                          end_date={formik.values.end_date}
                          statisticsData={statisticsData}
                          data_type="souscriptions_companies_and_intermediaires_vehicules"
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                ) : null}
              </Grid.Column>
            </Grid>

            {/*<Grid>
              <Grid.Column width={16}>
                <Segment
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    maxHeight: "300px",
                  }}
                >
                  <ValidatedSouscriptionsChart />
                </Segment>
              </Grid.Column>
            </Grid>*/}
          </>
        )}
      </GridRow>
    </Page>
  );
}

export default Dashboard;
