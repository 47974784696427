import React from "react";
import { CardGroup, Header, Segment } from "semantic-ui-react";
import GarantieItem from "./GarantieItem";

const GarantiesSection = ({
  formik,
  directivesTarifairesValues,
  companyDirectiveValues,
  avariesFacultatives,
}) => {
  return (
    <Segment>
      <Header content="Tableau des garanties" dividing />
      <CardGroup itemsPerRow={4} stackable>
        {formik.values.garanties?.map((garantie, index) => (
          <GarantieItem
            key={index}
            garantie={garantie}
            index={index}
            formik={formik}
            directivesTarifairesValues={directivesTarifairesValues}
            companyDirectiveValues={companyDirectiveValues}
            avariesFacultatives={avariesFacultatives}
          />
        ))}
      </CardGroup>
    </Segment>
  );
};

export default GarantiesSection;
