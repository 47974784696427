import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { findOne } from "../../../services/gestion_production/policesService";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
import PageContentWrapper from "../previewSections/partials/PageContentWrapper";

function ResilliationPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PageContentWrapper souscription={souscription}>
            <PreviewTemplateLayout souscription={souscription} />
            <PreviewContentWrapper souscription={souscription}>
              <View style={styles.avenantHeaderSection}>
                <Text style={styles.avenantHeaderTitle}>
                  {avenant?.bills?.payments
                    ? "Avenant de résiliation"
                    : "Projet d'avenant de résiliation"}
                </Text>
              </View>

              <View
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                  marginTop: 0,
                }}
              >
                <Text> Conditions particulières</Text>
              </View>
              <View
                style={{
                  marginTop: 5,
                  padding: 5,
                  paddingTop: 0,
                  border: "1px solid #dce1e6",
                }}
              >
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "30%",
                        textAlign: "center",
                        backgroundColor: "#dce1e6",
                        padding: 2,
                      }}
                    >
                      <Text>Avenant</Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Numéro de police</Text>
                      <Text>
                        {
                          souscriptionWithAvenant?.souscription_polices
                            ?.numero_police
                        }
                      </Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Date avenant</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(avenant?.date_avenant).format("DD/MM/YYYY")}
                        </Text>
                      </View>
                      <View>
                        <Text>Date de résiliation</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(avenant?.date_avenant)
                            .add(1, "day")
                            .format("DD/MM/YYYY")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 4,
                  padding: 5,
                  paddingTop: 0,
                  border: "1px solid #dce1e6",
                }}
              >
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "30%",
                        textAlign: "center",
                        backgroundColor: "#dce1e6",
                        padding: 2,
                      }}
                    >
                      <Text>Police</Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Bureau Direct</Text>
                      </View>
                      <View>
                        <Text>
                          {souscriptionWithAvenant?.souscription_origine
                            ?.compagnies_agencies?.denomination ||
                            souscriptionWithAvenant?.souscription_origine
                              ?.intermediaires?.denomination}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Adresse</Text>
                      </View>
                      <View>
                        <Text>
                          {souscriptionWithAvenant?.souscription_origine
                            ?.compagnies_agencies?.address ||
                            souscriptionWithAvenant?.souscription_origine
                              ?.intermediaires?.address}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Téléphone</Text>
                      </View>
                      <View>
                        <Text>
                          {souscriptionWithAvenant?.souscription_origine
                            ?.compagnies_agencies?.phone_1 ||
                            souscriptionWithAvenant?.souscription_origine
                              ?.intermediaires?.phone_1}
                        </Text>
                      </View>
                      <View>
                        <Text>Fax</Text>
                      </View>
                      <View>
                        <Text>
                          {souscriptionWithAvenant?.souscription_origine
                            ?.compagnies_agencies?.fax ||
                            souscriptionWithAvenant?.souscription_origine
                              ?.intermediaires?.fax}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Usage</Text>
                      </View>
                      <View>
                        <Text>Transport Public de Voyageurs</Text>
                      </View>
                      <View>
                        <Text>Durée</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(
                            souscriptionWithAvenant?.conditions_particulieres
                              ?.date_effet
                          )
                            .add(
                              Number(
                                souscriptionWithAvenant
                                  ?.conditions_particulieres
                                  ?.pool_directives_tarifaires_has_insurances_durations
                                  ?.insurances_durations?.months_number
                              ),
                              "months"
                            )
                            .diff(
                              dayjs(
                                souscriptionWithAvenant
                                  ?.conditions_particulieres?.date_effet
                              ),
                              "day"
                            )}{" "}
                          jours
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Date d'effet</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(
                            souscriptionWithAvenant?.conditions_particulieres
                              ?.date_effet
                          ).format("DD/MM/YYYY")}
                        </Text>
                      </View>
                      <View>
                        <Text>Date d'échéance</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(
                            souscriptionWithAvenant?.conditions_particulieres
                              ?.date_echeance
                          ).format("DD/MM/YYYY")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 4,
                  padding: 5,
                  paddingTop: 0,
                  border: "1px solid #dce1e6",
                }}
              >
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "30%",
                        textAlign: "center",
                        backgroundColor: "#dce1e6",
                        padding: 2,
                      }}
                    >
                      <Text>Assuré</Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Nom et prénom</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant?.souscripteur_assure
                              ?.assures?.nom_assure
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Téléphone</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant?.souscripteur_assure
                              ?.assures?.phone_assure
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Adresse</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant?.souscripteur_assure
                              ?.assures?.address_assure
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Activité</Text>
                      </View>
                      <View>
                        <Text></Text>
                      </View>
                      <View>
                        <Text>Profession</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant?.souscripteur_assure
                              ?.assures?.jobs?.libelle
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 4,
                  padding: 5,
                  paddingTop: 0,
                  border: "1px solid #dce1e6",
                }}
              >
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "30%",
                        textAlign: "center",
                        backgroundColor: "#dce1e6",
                        padding: 2,
                      }}
                    >
                      <Text>Caractéristiques du véhicule</Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Tarif</Text>
                      </View>
                      <View>
                        <Text>Transport Public de Voyageurs</Text>
                      </View>
                      <View>
                        <Text>Zone de circulation</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule
                              ?.zones_circulation?.libelle
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Marque</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.marque_vehicule
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Genre</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.genre_vehicule
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Carossérie</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.carrosserie
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Immatriculation</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule
                              ?.immatriculation_vehicule
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Chassis</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.numero_chassis
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Mise en circulation</Text>
                      </View>
                      <View>
                        <Text>
                          {dayjs(
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule
                              ?.date_premiere_mise_en_circulation
                          ).format("DD/MM/YYYY")}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Text>Puissance fiscale</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule
                              ?.puissance_fiscale
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Nombre de places</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.nombre_places
                          }
                        </Text>
                      </View>
                      <View>
                        <Text>Source d'énergie</Text>
                      </View>
                      <View>
                        <Text>
                          {
                            souscriptionWithAvenant
                              ?.souscription_identite_vehicule?.sources_energie
                              ?.libelle
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "30%",
                      textAlign: "center",
                      backgroundColor: "#dce1e6",
                      padding: 2,
                    }}
                  >
                    <Text>Décompte de prime</Text>
                  </View>
                </View>
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 5,
                      backgroundColor: "#dce1e6",
                    }}
                  >
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 2 }}
                    >
                      <Text>Prime nette</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 2 }}
                    >
                      <Text>Accessoires</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 2 }}
                    >
                      <Text>Taxes</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 2 }}
                    >
                      <Text>Carte rose</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 2 }}
                    >
                      <Text>Total TTC</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 5 }}
                    >
                      <Text>
                        {numeral(
                          avenant?.bills?.souscription_reimbursements?.amount
                        ).format("")}
                      </Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 5 }}
                    >
                      <Text>5 000</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 5 }}
                    >
                      <Text>0 </Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 5 }}
                    >
                      <Text>0</Text>
                    </View>
                    <View
                      style={{ width: "20%", borderColor: "#000", padding: 5 }}
                    >
                      <Text>
                        {numeral(
                          avenant?.bills?.souscription_reimbursements?.amount -
                            avenant?.bills?.amount
                        ).format("")}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 10 }}>
                <Text>
                  A la demande de l'assuré et d'un commun accord entre les
                  parties, il est noté que la police dont le numéro est indiqué
                  plus haut est résiliée dans tous ses effets à compter du{" "}
                  {dayjs(avenant?.date_avenant)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                  . La garantie ayant été effective pendant la durée indiquée
                  plus haut, la compagnie remboursera à l'assuré un pourcentage
                  de la prime émise pour la période non courue allant de la date
                  de résiliation à la date d'expiration, soit la somme dont le
                  détail ci-dessus.
                </Text>
              </View>
              <View style={{ marginTop: 10 }}>
                <Text style={{ fontWeight: "bold" }}>
                  Il n’est rien changé d’autres aux conditions tant générales
                  que particulières de la police.
                </Text>
              </View>
              <View style={{ marginTop: 10 }}>
                <Text style={{ textAlign: "right" }}>
                  Fait en 03 exemplaires à ___________________________, le{" "}
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "extrabold",
                    textTransform: "uppercase",
                  }}
                >
                  <Text
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    Pour l'assuré
                  </Text>
                  <Text
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    Pour la compagnie
                  </Text>
                </View>
              </View>
            </PreviewContentWrapper>
          </PageContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ResilliationPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 10,
    fontSize: 8,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 5,
  },
  avenantHeaderTitle: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
});
