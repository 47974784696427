import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Modal,
  Popup,
  PopupContent,
  Table,
  TableHeader,
} from "semantic-ui-react";
import * as policesService from "../../../services/gestion_production/policesService";
import dayjs from "dayjs";
import numeral from "numeral";
import { toast } from "react-toastify";
import * as paymentService from "../../../services/gestion_production/paymentService";
import Swal from "sweetalert2";
import {
  getAvenantTypeReadableText,
  getRemiseEnVigueurReadableText,
} from "../../../utils/commons";

function EncaissementModal({ open, setOpen, successCallback = () => {} }) {
  const [souscriptions, setSouscriptions] = useState([]);
  const [selectedSouscription, setSelectedSouscription] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const searchSouscriptions = async () => {
    try {
      const response = await policesService.findAll({
        search: searchTerm,
      });
      if (response.status === 200) {
        setSouscriptions(response.data.souscriptions);
      }
    } catch (error) {}
  };

  const makePayment = async (avenant) => {
    try {
      Swal.fire({
        title: `${
          avenant.bills?.souscription_reimbursements
            ? "Décaissement"
            : "Encaissement"
        }`,

        text: `Vous allez ${
          avenant.bills?.souscription_reimbursements
            ? "décaisser "
            : "encaisser "
        }
          le montant de ${
            avenant.type_avenant === "AFFAIRE_NOUVELLE"
              ? numeral(
                  souscriptions.find(
                    (souscription) => souscription.uuid === selectedSouscription
                  ).prime_ttc
                ).format("")
              : avenant.bills.souscription_reimbursements
              ? numeral(
                  avenant.bills.souscription_reimbursements.amount -
                    avenant.bills.amount
                ).format("")
              : numeral(avenant.bills.amount).format("")
          } FCFA ? voulez-vous continuer ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await paymentService.createPayment(avenant.uuid);
            if (response.status === 200) {
              toast.success("Encaissement effectué avec succès");
              successCallback();
              setOpen(false);
            }
          } catch (error) {
            toast.error("Une erreur est survenue lors de l'encaissement");
          }
        }
      });
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'encaissement");
    }
  };

  useEffect(() => {
    if (searchTerm) {
      searchSouscriptions();
    }

    if (!searchTerm) {
      setSelectedSouscription("");
      setSouscriptions([]);
    }
  }, [searchTerm]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      size="large"
      closeIcon
      onUnmount={() => {
        if (!open) {
          setSelectedSouscription("");
          setSouscriptions([]);
          setSearchTerm("");
        }
      }}
    >
      <Modal.Header>Encaissement/Décaissement</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Numéro de proposition/Police</label>
            <Dropdown
              search
              selection
              fluid
              onSearchChange={(e, data) => setSearchTerm(e.target.value)}
              placeholder="Sélectionner une souscription"
              value={selectedSouscription}
              onChange={(e, data) => setSelectedSouscription(data.value)}
              options={souscriptions
                ?.map((souscription) => ({
                  key: souscription.uuid,
                  text:
                    souscription.souscription_polices?.numero_police ||
                    souscription.numero_proposition,
                  value: souscription.uuid,
                  content: (
                    <div>
                      <div>
                        {souscription.souscripteurs.groupe_souscripteur ===
                        "PARTICULIER"
                          ? `${souscription.souscripteurs.last_name} ${souscription.souscripteurs.first_name}`
                          : souscription.souscripteurs.denomination}
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex items-center gap-1">
                          <span className="text-gray-700">#</span>
                          <span>{souscription.souscripteurs.num}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-gray-700">
                            N°{" "}
                            {`${
                              souscription.souscription_polices?.numero_police
                                ? "Police "
                                : "Proposition"
                            }`}
                          </span>
                          <span>
                            {souscription.souscription_polices?.numero_police ||
                              souscription.numero_proposition}
                          </span>
                        </div>
                      </div>
                    </div>
                  ),
                }))
                .concat({
                  key: "",
                  text: "Choir une souscription",
                  value: "",
                })}
            />
          </Form.Field>
          <Divider />
        </Form>
        {selectedSouscription && (
          <>
            <div className="!mb-4 ui segment">
              <h3 className="text-lg font-semibold mb-2">
                Informations sur la souscription
              </h3>
              {souscriptions.find((s) => s.uuid === selectedSouscription) && (
                <List horizontal id="souscription-info-encaissement">
                  <List.Item>
                    <List.Content>
                      <List.Header as="strong">Souscripteur</List.Header>
                      <List.Description>
                        {souscriptions.find(
                          (s) => s.uuid === selectedSouscription
                        ).souscripteurs.groupe_souscripteur === "PARTICULIER"
                          ? `${
                              souscriptions.find(
                                (s) => s.uuid === selectedSouscription
                              ).souscripteurs.last_name
                            } ${
                              souscriptions.find(
                                (s) => s.uuid === selectedSouscription
                              ).souscripteurs.first_name
                            }`
                          : souscriptions.find(
                              (s) => s.uuid === selectedSouscription
                            ).souscripteurs.denomination}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header as="strong">
                        Numéro de souscripteur
                      </List.Header>
                      <List.Description>
                        {
                          souscriptions.find(
                            (s) => s.uuid === selectedSouscription
                          ).souscripteurs.num
                        }
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header as="strong">Numéro de police</List.Header>
                      <List.Description>
                        {souscriptions.find(
                          (s) => s.uuid === selectedSouscription
                        ).souscription_polices?.numero_police ||
                          "Non disponible"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header as="strong">
                        Numéro de proposition
                      </List.Header>
                      <List.Description>
                        {
                          souscriptions.find(
                            (s) => s.uuid === selectedSouscription
                          ).numero_proposition
                        }
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  {souscriptions.find((s) => s.uuid === selectedSouscription)
                    .souscription_identite_vehicule && (
                    <>
                      <List.Item>
                        <List.Content>
                          <List.Header as="strong">Véhicule</List.Header>
                          <List.Description>
                            {
                              souscriptions.find(
                                (s) => s.uuid === selectedSouscription
                              ).souscription_identite_vehicule.marque_vehicule
                            }
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <List.Header as="strong">Immatriculation</List.Header>
                          <List.Description>
                            {souscriptions.find(
                              (s) => s.uuid === selectedSouscription
                            ).souscription_identite_vehicule
                              .immatriculation_vehicule || "Non disponible"}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <List.Header as="strong">
                            Puissance fiscale
                          </List.Header>
                          <List.Description>
                            {
                              souscriptions.find(
                                (s) => s.uuid === selectedSouscription
                              ).souscription_identite_vehicule.puissance_fiscale
                            }
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <List.Header as="strong">
                            Nombre de places
                          </List.Header>
                          <List.Description>
                            {
                              souscriptions.find(
                                (s) => s.uuid === selectedSouscription
                              ).souscription_identite_vehicule.nombre_places
                            }
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </>
                  )}
                </List>
              )}
            </div>
            <Table>
              <TableHeader>
                <Table.Row>
                  <Table.HeaderCell>Type d'avenant </Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>
                    Montant à encaisser/Décaisser
                  </Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </TableHeader>
              <Table.Body>
                {souscriptions
                  .find(
                    (souscription) => souscription.uuid === selectedSouscription
                  )
                  .avenants.map(
                    (avenant) =>
                      (avenant.bills === null ||
                        avenant.bills.payments === null) && (
                        <Table.Row key={avenant.uuid}>
                          <Table.Cell>
                            {getAvenantTypeReadableText(avenant.type_avenant)}
                          </Table.Cell>
                          <Table.Cell>
                            {dayjs(avenant.created_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {avenant.type_avenant === "AFFAIRE_NOUVELLE"
                              ? numeral(
                                  souscriptions.find(
                                    (souscription) =>
                                      souscription.uuid === selectedSouscription
                                  ).prime_ttc
                                ).format("")
                              : avenant.bills.souscription_reimbursements
                              ? numeral(
                                  avenant.bills.souscription_reimbursements
                                    .amount - avenant.bills.amount
                                ).format("")
                              : numeral(avenant.bills.amount).format("")}
                            {avenant?.bills?.souscription_reimbursements ? (
                              <Popup
                                trigger={
                                  <Icon
                                    name="info"
                                    color="blue"
                                    size="tiny"
                                    circular
                                    className="cursor-pointer !ml-2"
                                  />
                                }
                                position="top center"
                              >
                                <PopupContent>
                                  <ul>
                                    <li>
                                      <span className="font-semibold">
                                        Montant nette :
                                      </span>
                                      <span className="font-bold !ml-1">
                                        {numeral(
                                          avenant.bills
                                            ?.souscription_reimbursements
                                            ?.amount
                                        ).format("")}
                                      </span>
                                    </li>
                                    <li className="border-b-2 border-gray-400 pb-2">
                                      <span className="font-semibold">
                                        Frais :
                                      </span>
                                      <span className="font-bold !ml-1 text-red-500">
                                        -{" "}
                                        {numeral(avenant.bills?.amount).format(
                                          ""
                                        )}
                                      </span>
                                    </li>
                                    <li className="pt-2">
                                      <span className="font-semibold">
                                        Montant à Décaisser :
                                      </span>
                                      <span className="font-bold !ml-1">
                                        {numeral(
                                          avenant.bills
                                            ?.souscription_reimbursements
                                            ?.amount - avenant.bills?.amount
                                        ).format("")}
                                      </span>
                                    </li>
                                  </ul>
                                </PopupContent>
                              </Popup>
                            ) : null}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              size="mini"
                              icon="money"
                              color={
                                avenant?.bills?.souscription_reimbursements
                                  ? "red"
                                  : "green"
                              }
                              content={
                                avenant?.bills?.souscription_reimbursements
                                  ? "Décaisser"
                                  : "Encaisser"
                              }
                              onClick={() => makePayment(avenant)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button icon="close" label="Fermer" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
}

export default EncaissementModal;
