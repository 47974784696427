import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { findOne } from "../../../services/gestion_production/policesService";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import PageContentWrapper from "../previewSections/partials/PageContentWrapper";

function RemiseEnVigueurPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  const lastSuspension = (souscription) => {
    const suspensions = souscriptionWithAvenant?.avenants?.filter((avenant) => {
      return avenant.type_avenant === "SUSPENSION";
    });

    if (!suspensions?.length) return null;
    //suspensions[0].date_avenant = dayjs().add(50, "day").format("YYYY-MM-DD");
    return suspensions.sort(
      (a, b) =>
        dayjs(b.date_avenant).valueOf() - dayjs(a.date_avenant).valueOf()
    )[0];
  };

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PageContentWrapper souscription={souscription}>
            <PreviewTemplateLayout souscription={souscription} />
            <PreviewContentWrapper souscription={souscription}>
              <View style={styles.avenantHeaderSection}>
                <Text style={styles.avenantHeaderTitle}>
                  {avenant?.bills?.payments
                    ? "Avenant de remise en vigueur"
                    : "Projet d'avenant de remise en vigueur"}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginTop: 8,
                }}
              >
                <Text>
                  (Concerne la police Automobile n°{" "}
                  {souscriptionWithAvenant?.souscription_polices?.numero_police}
                  )
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 10,
                  marginTop: 15,
                  borderColor: "#CCC",
                  borderWidth: 1,
                  padding: 10,
                }}
              >
                <View style={{ width: "50%" }}>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <Text>Assuré</Text>
                    <Text>:</Text>
                    <Text>
                      {
                        souscriptionWithAvenant?.souscripteur_assure?.assures
                          ?.nom_assure
                      }
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <Text>Adresse</Text>
                    <Text>:</Text>
                    <Text>
                      {
                        souscriptionWithAvenant?.souscripteur_assure?.assures
                          ?.address_assure
                      }
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <Text>Téléphone</Text>
                    <Text>:</Text>
                    <Text>
                      {
                        souscriptionWithAvenant?.souscripteur_assure?.assures
                          ?.phone_assure
                      }
                    </Text>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <Text>Date d'effet du contrat</Text>
                    <Text>:</Text>
                    <Text>
                      {dayjs(
                        souscriptionWithAvenant?.conditions_particulieres
                          ?.date_effet
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <Text>Date d'échéance du contrat</Text>
                    <Text>:</Text>
                    <Text>
                      {dayjs(
                        souscriptionWithAvenant?.conditions_particulieres
                          ?.date_echeance
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 25 }}>
                <Text>
                  A la demande de l'assuré et d'un commun accord entre les
                  parties, il est convenu qu'à compter du{" "}
                  {dayjs(avenant?.date_avenant)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}{" "}
                  , le présent contrat est remis en vigueur pour le véhicule
                  suivant: Marque ,
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.marque_vehicule
                  }{" "}
                  , Type{" "}
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.type_vehicule
                  }{" "}
                  , Genre{" "}
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.genre_vehicule
                  }{" "}
                  , Immatriculation{" "}
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.immatriculation_vehicule
                  }{" "}
                  ,
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.nombre_places
                  }{" "}
                  places ,
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.puissance_fiscale
                  }{" "}
                  CV ,{" "}
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.sources_energie?.libelle
                  }{" "}
                  , Catégorie 04.
                </Text>
              </View>

              {["report_echeance", "ristourne"].includes(
                avenant?.meta_data?.remise_en_vigueur_type
              ) ? (
                avenant?.meta_data?.remise_en_vigueur_type ===
                "report_echeance" ? (
                  <View style={{ marginTop: 25 }}>
                    <Text>
                      Du fait de la suspension du{" "}
                      {dayjs(
                        lastSuspension(souscriptionWithAvenant)?.meta_data
                          ?.date_avenant
                      )
                        .add(1, "day")
                        .format("DD MMMM YYYY")}{" "}
                      au {dayjs(avenant?.date_avenant).format("DD MMMM YYYY")} ,
                      soit{" "}
                      {dayjs(dayjs(avenant?.date_avenant).add(1, "day")).diff(
                        dayjs(
                          lastSuspension(souscriptionWithAvenant)?.meta_data
                            ?.date_avenant
                        ).add(1, "days"),
                        "days"
                      )}{" "}
                      jours de suspension.
                    </Text>
                    <View
                      style={{
                        marginTop: 10,
                        backgroundColor: "#dce1e6",
                        padding: 8,
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        ({" "}
                        {dayjs(dayjs(avenant?.date_avenant).add(1, "day")).diff(
                          dayjs(
                            lastSuspension(souscriptionWithAvenant)?.meta_data
                              ?.date_avenant
                          ).add(1, "day"),
                          "days"
                        )}
                        {" X 3/4"}) ={" "}
                        {(dayjs(
                          dayjs(avenant?.date_avenant).add(1, "day")
                        ).diff(
                          dayjs(
                            lastSuspension(souscriptionWithAvenant)?.meta_data
                              ?.date_avenant
                          ).add(1, "day"),
                          "days"
                        ) *
                          3) /
                          4}{" "}
                        jours
                      </Text>
                      <View style={{ marginTop: 10 }}>
                        <Text>
                          La prochaine date d'échéance est fixée au{" "}
                          {dayjs(avenant?.meta_data?.report_date).format(
                            "DD MMMM YYYY"
                          )}{" "}
                          à minuit, date à laquelle il cessera ses effets de
                          plein droit.
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={{ marginTop: 25 }}>
                    <Text>
                      Du fait de la suspension du{" "}
                      {dayjs(
                        lastSuspension(souscriptionWithAvenant)?.meta_data
                          ?.date_avenant
                      )
                        .add(1, "day")
                        .format("DD MMMM YYYY")}{" "}
                      au {dayjs(avenant?.date_avenant).format("DD MMMM YYYY")} ,
                      soit{" "}
                      {dayjs(dayjs(avenant?.date_avenant).add(1, "day")).diff(
                        dayjs(
                          lastSuspension(souscriptionWithAvenant)?.meta_data
                            ?.date_avenant
                        ).add(1, "day"),
                        "days"
                      )}{" "}
                      jours de suspension.
                    </Text>
                    <View
                      style={{
                        marginTop: 10,
                        backgroundColor: "#dce1e6",
                        padding: 8,
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        ({" "}
                        {dayjs(dayjs(avenant?.date_avenant).add(1, "day")).diff(
                          dayjs(
                            lastSuspension(souscriptionWithAvenant)?.meta_data
                              ?.date_avenant
                          ).add(1, "day"),
                          "days"
                        )}
                        {" X 3/4"}) ={" "}
                        {(dayjs(
                          dayjs(avenant?.date_avenant).add(1, "day")
                        ).diff(
                          dayjs(
                            lastSuspension(souscriptionWithAvenant)?.meta_data
                              ?.date_avenant
                          ).add(1, "day"),
                          "days"
                        ) *
                          3) /
                          4}{" "}
                        jours
                      </Text>
                      <View style={{ marginTop: 10 }}>
                        <Text>
                          L’assuré bénéficie d’une ristourne de prime déterminée
                          de la manière suivante :
                        </Text>
                        <Text>
                          Prime nette à ristourner :{" "}
                          {numeral(avenant?.meta_data?.prime_nette).format("")}{" "}
                          X{" "}
                          {(dayjs(
                            dayjs(avenant?.date_avenant).add(1, "day")
                          ).diff(
                            dayjs(
                              lastSuspension(souscriptionWithAvenant)?.meta_data
                                ?.date_avenant
                            ).add(1, "day"),
                            "days"
                          ) *
                            3) /
                            4}
                          /365 ={" "}
                          {numeral(avenant?.meta_data?.amount).format("")}.
                        </Text>
                        <Text>Frais : -5 000</Text>
                        <Text>
                          Total :{" "}
                          {numeral(avenant?.meta_data?.amount - 5000).format(
                            ""
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                )
              ) : (
                <View style={{ marginTop: 25 }}>
                  <Text>
                    Du fait de la suspension du{" "}
                    {dayjs(
                      lastSuspension(souscription)?.meta_data?.date_avenant
                    )
                      .add(1, "day")
                      .format("DD/MM/YYYY")}{" "}
                    au {dayjs(avenant?.date_avenant).format("DD/MM/YYYY")} Il
                    n’est rien changé d’autres aux conditions tant générales que
                    particulières de la police.
                  </Text>
                </View>
              )}

              <View style={{ marginTop: 25 }}>
                <Text style={{ textAlign: "right" }}>
                  Fait en 02 exemplaires à ___________________________, le{" "}
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
                <View
                  style={{
                    marginTop: 25,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "extrabold",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textDecoration: "underline",
                    }}
                  >
                    Pour l'assuré
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textDecoration: "underline",
                    }}
                  >
                    Pour la compagnie
                  </Text>
                </View>
              </View>
            </PreviewContentWrapper>
          </PageContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default RemiseEnVigueurPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 50,
    fontSize: 10,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 10,
  },
  avenantHeaderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
  columnsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },
  column: {
    width: "45%",
  },
  line: {
    marginBottom: 10,
    padding: 5,
    borderBottom: "1pt solid #000",
  },
});
