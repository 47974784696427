import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  Icon,
  Input,
  Loader,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import * as policesService from "../../services/gestion_production/policesService";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { USERS_ROLE_ENUMS } from "../../constants";
import PermissionWrapper from "../PermissionWrapper";

function PolicesList({ setTotalItems }) {
  const [souscriptions, setSouscriptions] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPage: null,
    previousPage: null,
    limit: 10,
  });
  const userData = useRecoilValue(authState);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const fetchSouscriptions = async () => {
    try {
      setLoading(true);
      const response = await policesService.findAll({
        search,
        page: pagination.currentPage,
        limit: pagination.limit,
      });
      if (
        response.status === 200 &&
        Array.isArray(response.data?.souscriptions)
      ) {
        setSouscriptions(response.data.souscriptions);
        setPagination(response.data.pagination);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (error) {
      toast.error("Erreur lors de la récupération des souscriptions");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSouscriptions();
  }, [pagination.currentPage, search]);

  const handleDeleteSouscription = async (uuid) => {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette souscription ? Cette action est irréversible.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await policesService.deleteSouscription(uuid);
          if (response.status === 200) {
            fetchSouscriptions();
            toast.success("Souscription supprimée avec succès");
          }
        } catch (error) {
          toast.error(
            "Une erreur est survenue lors de la suppression de la souscription"
          );
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <Segment>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Input
          placeholder="Rechercher..."
          onChange={handleSearch}
          className="search"
        />
      </div>
      {loading ? (
        <Loader active inline="centered" size="large" className="!mt-10" />
      ) : (
        <Table striped celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>#</Table.HeaderCell>

              {([
                USERS_ROLE_ENUMS.POOL_USER,
                USERS_ROLE_ENUMS.ROOT_ADMIN,
              ].includes(userData?.user?.user_role) ||
                userData?.user?.intermediaire?.type_intermediaire ===
                  "COURTIER") && <Table.HeaderCell>Compagnie</Table.HeaderCell>}
              {[
                USERS_ROLE_ENUMS.COMPANY_USER,
                USERS_ROLE_ENUMS.COURTIER_USER,
              ].includes(userData?.user?.user_role) && (
                <Table.HeaderCell>Bureau/Intermédiaire</Table.HeaderCell>
              )}

              <Table.HeaderCell>Proposition</Table.HeaderCell>
              <Table.HeaderCell>Police</Table.HeaderCell>
              <Table.HeaderCell>N° Attestation</Table.HeaderCell>
              <Table.HeaderCell>Immatriculation</Table.HeaderCell>
              <Table.HeaderCell>Code client</Table.HeaderCell>
              <Table.HeaderCell>Nom Client</Table.HeaderCell>
              <Table.HeaderCell>Téléphone</Table.HeaderCell>
              <Table.HeaderCell>Création</Table.HeaderCell>
              <Table.HeaderCell textAlign="right" collapsing>
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {souscriptions?.map((souscription, index) => (
              <Table.Row key={`${souscription.uuid}`}>
                <Table.Cell>{index + 1}</Table.Cell>
                {([
                  USERS_ROLE_ENUMS.POOL_USER,
                  USERS_ROLE_ENUMS.ROOT_ADMIN,
                ].includes(userData?.user?.user_role) ||
                  userData?.user?.intermediaire?.type_intermediaire ===
                    "COURTIER") && (
                  <Table.Cell>
                    {
                      souscription.souscription_origine?.compagnies
                        ?.denomination
                    }
                  </Table.Cell>
                )}
                {[
                  USERS_ROLE_ENUMS.COMPANY_USER,
                  USERS_ROLE_ENUMS.COURTIER_USER,
                ].includes(userData?.user?.user_role) && (
                  <Table.Cell>
                    {souscription.souscription_origine?.compagnies_agencies
                      ?.denomination ||
                      `${souscription.souscription_origine?.intermediaires_agencies?.denomination} / ${souscription.souscription_origine?.intermediaires?.denomination}`}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {souscription.numero_proposition || "N/A"}
                </Table.Cell>
                <Table.Cell>
                  {souscription.souscription_polices?.numero_police || "N/A"}
                </Table.Cell>
                <Table.Cell>
                  {souscription.souscription_polices?.numero_attestation ||
                    "N/A"}
                </Table.Cell>
                <Table.Cell>
                  {souscription.souscription_identite_vehicule
                    ?.immatriculation_vehicule || "N/A"}
                </Table.Cell>
                <Table.Cell>{souscription.souscripteurs?.num}</Table.Cell>
                <Table.Cell>
                  {souscription.souscripteurs?.groupe_souscripteur ===
                  "PARTICULIER"
                    ? `${souscription.souscripteurs?.last_name}
                   ${souscription.souscripteurs?.first_name}`
                    : souscription.souscripteurs?.denomination}
                </Table.Cell>
                <Table.Cell>{souscription.souscripteurs?.phone}</Table.Cell>
                <Table.Cell>
                  {dayjs(souscription.created_at).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Dropdown
                    pointing="right"
                    icon={<Icon name="ellipsis vertical" />}
                  >
                    <Dropdown.Menu>
                      <PermissionWrapper
                        requiredPermissions={[
                          "pool.production.polices.details",
                          "company.production.polices.details",
                          "agent_general.production.polices.details",
                          "agency.production.polices.details",
                        ]}
                      >
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() =>
                            navigate(
                              `details/${souscription?.souscription_polices?.uuid}`
                            )
                          }
                        />
                      </PermissionWrapper>
                      <PermissionWrapper
                        requiredPermissions={[
                          "company.production.polices.delete",
                        ]}
                      >
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                          onClick={() =>
                            handleDeleteSouscription(souscription?.uuid)
                          }
                        />
                      </PermissionWrapper>
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {pagination.totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={
                    [
                      USERS_ROLE_ENUMS.POOL_USER,
                      USERS_ROLE_ENUMS.ROOT_ADMIN,
                      USERS_ROLE_ENUMS.COMPANY_USER,
                      USERS_ROLE_ENUMS.COURTIER_USER,
                    ].includes(userData?.user?.user_role)
                      ? 11
                      : 10
                  }
                  textAlign="right"
                >
                  <Pagination
                    className="ui tiny"
                    totalPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    activePage={pagination.currentPage}
                    onPageChange={(e, { activePage }) => {
                      setPagination({
                        ...pagination,
                        currentPage: activePage,
                      });
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      )}
    </Segment>
  );
}

export default PolicesList;
