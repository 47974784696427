// utils.js
import dayjs from "dayjs";
import * as garantiesVolIncendieService from "../services/gestion_production/pool/tableauGarantiesVolIncendieService";
import {
  ASSISTANCE_AUTO_ENUMS,
  BRIS_DE_GLACES_ENUMS,
  GARANTIES_DOMMAGE_VEHICULE_ENUMS,
  GARANTIES_INCENDIE_ENUMS,
  GARANTIES_VOL_ENUMS,
} from "./constants";
import { TYPES_AVENANTS } from "../constants";

export const handleVolRisqueChange = async (
  garanties,
  garantiesIndex,
  risque,
  values,
  setFieldValue
) => {
  try {
    const ageDuVehicule = dayjs().diff(
      dayjs(values.identite_vehicule.date_premiere_mise_en_circulation),
      "year"
    );
    if (
      risque.libelle === GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE ||
      risque.libelle === GARANTIES_VOL_ENUMS.VOL_SIMPLE
    ) {
      const prime = await getPrimeIncendieOrVol(
        ageDuVehicule > 2
          ? values.identite_vehicule.valeur_venale
          : values.identite_vehicule.valeur_a_neuf,
        values.identite_vehicule.date_premiere_mise_en_circulation
      );
      if (!prime) {
        return;
      }
      let updatedRisques = garanties[garantiesIndex].risques.map((r) => {
        if (r.libelle !== risque.libelle) {
          return {
            ...r,
            value: 0,
            checked: false,
          };
        }
        return r;
      });

      if (!risque.checked) {
        let risqueToUpdate = updatedRisques.find(
          (r) => r.libelle === risque.libelle
        );
        risqueToUpdate.value = 0;
        risqueToUpdate.checked = false;
        updatedRisques = updatedRisques.map((r) => {
          if (r.libelle === risque.libelle) {
            return risqueToUpdate;
          }
          return r;
        });

        garanties[garantiesIndex].risques = updatedRisques;
        return setFieldValue("garanties", garanties, true);
      }

      let risqueToUpdate = updatedRisques.find(
        (r) => r.libelle === risque.libelle
      );
      risqueToUpdate.value =
        risque.libelle === GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE
          ? prime.armed_robbery_premium
          : prime.simple_theft_premium;
      risqueToUpdate.checked = true;

      garanties[garantiesIndex].risques = updatedRisques;
      setFieldValue("garanties", garanties, true);
    }
  } catch (error) {}
};

export const handleIncendieRisqueChange = async (
  vehiculePrice,
  firstPutIntoCirculationDate,
  garanties,
  garantiesIndex,
  risque,
  values,
  setFieldValue
) => {
  try {
    if (risque.libelle === GARANTIES_INCENDIE_ENUMS.INCENDIE) {
      const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
      if (!isValidDate) {
        return;
      }

      if (vehiculePrice <= 0) {
        return;
      }
      const prime = await getPrimeIncendieOrVol(
        vehiculePrice,
        firstPutIntoCirculationDate
      );
      if (!prime) {
        return;
      }

      let updatedRisques = garanties[garantiesIndex].risques.map((r) => {
        if (r.libelle !== risque.libelle) {
          return {
            ...r,
            value: 0,
            checked: false,
          };
        }
        return r;
      });

      if (!risque.checked) {
        let risqueToUpdate = updatedRisques.find(
          (r) => r.libelle === risque.libelle
        );
        risqueToUpdate.value = 0;
        risqueToUpdate.checked = false;
        garanties[garantiesIndex].risques = updatedRisques;
        return setFieldValue("garanties", garanties, true);
      }

      let risqueToUpdate = updatedRisques.find(
        (r) => r.libelle === risque.libelle
      );
      risqueToUpdate.value = prime.fire_premium || 0;
      risqueToUpdate.checked = true;

      garanties[garantiesIndex].risques = updatedRisques;
      setFieldValue("garanties", garanties, true);
    }
  } catch (error) {}
};

export const getPrimeIncendieOrVol = async (
  vehiculePrice,
  firstPutIntoCirculationDate
) => {
  const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
  if (!isValidDate) {
    return;
  }

  if (vehiculePrice <= 0) {
    return;
  }

  const requestParams = {
    vehicule_price: vehiculePrice,
    first_put_into_circulation_date: firstPutIntoCirculationDate,
  };

  try {
    const res =
      await garantiesVolIncendieService.getByActiveDirectiveTarifaires(
        requestParams
      );
    return res.data;
  } catch (error) {}
};

//handle vehiculeprince change or first put into circulation date change
export const handleVehiculePriceOrFirstPutIntoCirculationDateChange = async (
  vehiculePrice,
  firstPutIntoCirculationDate,
  values,
  setFieldValue
) => {
  try {
    const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
    if (vehiculePrice <= 0) {
      return;
    }
    if (!isValidDate) {
      return;
    }

    const CONCERNED_RISQUES = [
      GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE,
      GARANTIES_VOL_ENUMS.VOL_SIMPLE,
      GARANTIES_INCENDIE_ENUMS.INCENDIE,
    ];
    const response = await getPrimeIncendieOrVol(
      vehiculePrice,
      firstPutIntoCirculationDate
    );

    if (!response) {
      return;
    }

    const prime = response;

    if (!prime) {
      return;
    }

    CONCERNED_RISQUES.forEach((risqueName) => {
      const garantie = values.garanties.find(
        (g) => g.libelle === GARANTIES_VOL_ENUMS.VOL
      );

      const risque = garantie.risques.find((r) => r.libelle === risqueName);

      if (!risque) {
        return;
      }

      if (!risque.checked) {
        return;
      }

      switch (risqueName) {
        case GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE:
          risque.value = prime.armed_robbery_premium || 0;
          break;
        case GARANTIES_VOL_ENUMS.VOL_SIMPLE:
          risque.value = prime.simple_theft_premium || 0;
          break;
        case GARANTIES_INCENDIE_ENUMS.INCENDIE:
          risque.value = prime.fire_premium || 0;
          break;
        default:
          break;
      }

      const garanties = values.garanties.map((g) => {
        if (g.libelle === GARANTIES_VOL_ENUMS.VOL) {
          return {
            ...g,
            risques: g.risques.map((r) =>
              r.libelle === risqueName ? risque : r
            ),
          };
        }
        return g;
      });
      setFieldValue("garanties", garanties, true);
    });
  } catch (error) {}
};

export const getBriseDeGlacePrime = (tauxBrisDeGlaces, vehiculePrice) => {
  try {
    if (!tauxBrisDeGlaces || !vehiculePrice) {
      return;
    }
    return Math.floor((tauxBrisDeGlaces * vehiculePrice) / 100);
  } catch (error) {}
};

export const changeBrisDeGlaceValue = (
  vehiculePrice,
  tauxBrisDeGlaces,
  values,
  setFieldValue
) => {
  try {
    const prime = getBriseDeGlacePrime(tauxBrisDeGlaces, vehiculePrice);

    const garantie = values.garanties.find(
      (g) => g.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES
    );

    const risque = garantie.risques.find(
      (r) => r.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES
    );

    if (!risque) {
      return;
    }

    risque.value = prime || 0;
    risque.checked = !risque.checked;

    if (risque.checked === false) {
      risque.value = 0;
    }

    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES ? risque : r
      ),
    };

    const garanties = values.garanties.map((g) =>
      g.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES ? updatedGarantie : g
    );

    setFieldValue("garanties", garanties, true);
  } catch (error) {}
};

export const handleAvariesFacultativesRisqueChange = (
  updatedGaranties,
  index,
  updatedRisque,
  values,
  setFieldValue
) => {
  try {
    const garantie = updatedGaranties[index];
    const risque = garantie.risques.find(
      (r) => r.libelle === updatedRisque.libelle
    );

    if (!risque) {
      return;
    }

    if (risque.checked) {
      setFieldValue("pool_avaries_facultatives_uuid", 0, true);
    }

    risque.value = updatedRisque.value;
    risque.checked = updatedRisque.checked;

    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === updatedRisque.libelle ? risque : r
      ),
    };

    const garanties = updatedGaranties.map((g) =>
      g.libelle === garantie.libelle ? updatedGarantie : g
    );

    if (risque.checked === false) {
      risque.value = 0;
    }

    setFieldValue("garanties", garanties, true);
  } catch (error) {}
};

export const isActiveRique = (garanties, garantieName, risqueName) => {
  try {
    const isBrisDeGlaceChecked = garanties
      .find((g) => g.libelle === garantieName)
      .risques.find((r) => r.libelle === risqueName)?.checked;

    return isBrisDeGlaceChecked;
  } catch (error) {
    return false;
  }
};

export const updateGaranties = (
  garanties,
  garantieName,
  risqueName,
  value,
  checked
) => {
  try {
    const garantie = garanties.find((g) => g.libelle === garantieName);
    const risque = garantie.risques.find((r) => r.libelle === risqueName);
    risque.value = value;
    risque.checked = checked;
    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === risqueName ? risque : r
      ),
    };
    return garanties.map((g) =>
      g.libelle === garantieName ? updatedGarantie : g
    );
  } catch (error) {}
};
export const initializeGaranties = (formik) => {
  const garanties = formik.values.garanties.map((garantie) => {
    if (
      !garantie.is_required &&
      garantie.libelle !== ASSISTANCE_AUTO_ENUMS.ASSISTANCE_AUTO
    ) {
      return {
        ...garantie,
        checked: false,
        value: 0,
        risques: garantie.risques.map((risque) => ({
          ...risque,
          checked: false,
          value: 0,
        })),
      };
    }
    return garantie;
  });
  formik.setFieldValue("garanties", garanties, true);
  formik.setFieldValue(
    "conditions_particulieres.pool_avaries_facultatives_uuid",
    0,
    true
  );
};

export const calculateFranchiseDommageVehicule = (
  valeurNeuve,
  selectedAvariesFacultativesUuid,
  companyDirectiveValues,
  avariesFacultatives
) => {
  try {
    const tauxPrimeDommage = companyDirectiveValues?.find(
      (item) => item.name === "taux_prime_dommage"
    )?.value;

    const avariesFacultativesSelected = avariesFacultatives.find(
      (avary) => avary.uuid === selectedAvariesFacultativesUuid
    );

    if (!avariesFacultativesSelected) {
      const primeDommage =
        (companyDirectiveValues.find(
          (item) => item.name === "taux_prime_dommage"
        )?.value *
          valeurNeuve) /
        100;
      return isNaN(primeDommage) ? 0 : primeDommage;
    }
    const primeDommage =
      valeurNeuve *
      (tauxPrimeDommage / 100) *
      (1 - avariesFacultativesSelected.reduction / 100);

    return isNaN(primeDommage) ? 0 : primeDommage;
  } catch (error) {
    return 0;
  }
};

export const calculateTaxes = (
  primeNetteTotale,
  montantGestionPool,
  coutPolice,
  directivesTarifairesValues
) => {
  try {
    const tauxTaxeContratAssurance = directivesTarifairesValues?.find(
      (item) => item.name === "taxe_contrat_assurance"
    )?.value;

    return (
      ((primeNetteTotale + montantGestionPool + coutPolice) *
        tauxTaxeContratAssurance) /
      100
    );
  } catch (error) {
    return 0;
  }
};

export const collectSouscriptionData = (data) => {
  try {
    const withFranchise = data.garanties
      .find(
        (g) =>
          g.libelle ===
          GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE
      )
      ?.risques.find((r) => r.libelle === "Franchise")?.checked
      ? 1
      : 0;

    const souscriptionData = {};
    souscriptionData.souscripteur = {
      idsouscripteurs: data.idsouscripteurs,
      lien_assure: data.assure.lien_assure,
    };
    souscriptionData.assure = {
      address_assure: data.assure.address_assure,
      email_assure: data.assure.email_assure,
      idjobs: data.assure.idjobs,
      nom_assure: data.assure.nom_assure,
      phone_assure: data.assure.phone_assure,
      nui: data.assure.nui || "",
    };

    souscriptionData.conditions_particulieres = {
      date_echeance: data.conditions_particulieres.date_echeance,
      date_effet: data.conditions_particulieres.date_effet,
      date_proposition: data.conditions_particulieres.date_proposition,
      pool_avaries_facultatives_uuid:
        data.conditions_particulieres.pool_avaries_facultatives_uuid,
      type_avenant: data.conditions_particulieres.type_avenant,
      type_renouvellement_uuid:
        data.conditions_particulieres.type_renouvellement,
      idcategories_contrats: data.idcategories_contrats,
      date_souscription: data.date_souscription,
      with_franchise: withFranchise,
    };

    souscriptionData.identite_vehicule = {
      carrosserie: data.identite_vehicule.carrosserie,
      date_premiere_mise_en_circulation:
        data.identite_vehicule.date_premiere_mise_en_circulation,
      genre_vehicule: data.identite_vehicule.genre_vehicule,
      idsources_energie: data.identite_vehicule.idsources_energie,
      idzones_circulation: data.identite_vehicule.idzones_circulation,
      immatriculation_vehicule: data.identite_vehicule.immatriculation_vehicule,
      immatriculation_vehicule_precedente:
        data.identite_vehicule.immatriculation_vehicule_precedente,
      marque_vehicule: data.identite_vehicule.marque_vehicule,
      nombre_places: data.identite_vehicule.nombre_places,
      numero_chassis: data.identite_vehicule.numero_chassis,
      puissance_fiscale: data.identite_vehicule.puissance_fiscale,
      type_vehicule: data.identite_vehicule.type_vehicule,
      usage_vehicule: data.identite_vehicule.usage_vehicule,
      valeur_a_neuf: data.identite_vehicule.valeur_a_neuf,
      valeur_venale: data.identite_vehicule.valeur_venale,
    };

    souscriptionData.conducteur = {
      date_delivrance_permis: data.identite_vehicule.date_delivrance_permis,
      numero_permis_conduire: data.identite_vehicule.numero_permis_conduire,
    };

    souscriptionData.garanties = data.garanties?.map((garantie) => {
      return {
        uuid: garantie.uuid,
        risques: garantie.risques?.map((risque) => {
          return {
            uuid: risque.uuid,
            value: risque.value,
          };
        }),
      };
    });

    souscriptionData.garanties_complementaires =
      data.garanties_complementaires?.map((garantie) => {
        return {
          uuid: garantie.uuid,
          value: garantie.value,
        };
      });

    souscriptionData.carte_rose = data.carte_rose;
    souscriptionData.cout_police = data.cout_police;
    souscriptionData.droit_de_timbre_automobile =
      data.droit_de_timbre_automobile;
    souscriptionData.montant_commission = data.montant_commission;
    souscriptionData.montant_gestion_pool = data.montant_gestion_pool;
    souscriptionData.prime_nette = data.prime_nette;
    souscriptionData.prime_nette_iac = data.prime_nette_iac;
    souscriptionData.prime_nette_totale = data.prime_nette_totale;
    souscriptionData.prime_ttc = data.prime_ttc;
    souscriptionData.taxes = data.taxes;
    souscriptionData.numero_proposition = data.numero_proposition;
    souscriptionData.fichier_carte_grise = data.fichier_carte_grise;

    // Ajout du fichier à soumettre
    if (data.fichier_carte_grise instanceof File) {
      souscriptionData.fichier_carte_grise = data.fichier_carte_grise;
    }

    return souscriptionData;
  } catch (error) {
    return null;
  }
};

export const collectChangementVehiculeData = (souscriptionData) => {
  try {
    const data = {};
    const withFranchise = souscriptionData.garanties
      .find(
        (g) =>
          g.libelle ===
          GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE
      )
      ?.risques.find((r) => r.libelle === "Franchise")?.checked
      ? 1
      : 0;

    data.conditions_particulieres = {
      date_echeance: souscriptionData.conditions_particulieres.date_echeance,
      date_effet: souscriptionData.conditions_particulieres.date_effet,
      date_proposition:
        souscriptionData.conditions_particulieres.date_proposition,
      pool_avaries_facultatives_uuid:
        souscriptionData.conditions_particulieres
          .pool_avaries_facultatives_uuid,
      type_avenant: souscriptionData.conditions_particulieres.type_avenant,
      type_renouvellement_uuid:
        souscriptionData.conditions_particulieres.type_renouvellement,
      idcategories_contrats: souscriptionData.idcategories_contrats,
      date_souscription: souscriptionData.date_souscription,
      with_franchise: withFranchise,
    };

    data.identite_vehicule = {
      carrosserie: souscriptionData.identite_vehicule.carrosserie,
      date_premiere_mise_en_circulation:
        souscriptionData.identite_vehicule.date_premiere_mise_en_circulation,
      genre_vehicule: souscriptionData.identite_vehicule.genre_vehicule,
      idsources_energie: souscriptionData.identite_vehicule.idsources_energie,
      idzones_circulation:
        souscriptionData.identite_vehicule.idzones_circulation,
      immatriculation_vehicule:
        souscriptionData.identite_vehicule.immatriculation_vehicule,
      immatriculation_vehicule_precedente:
        souscriptionData.identite_vehicule.immatriculation_vehicule_precedente,
      marque_vehicule: souscriptionData.identite_vehicule.marque_vehicule,
      nombre_places: souscriptionData.identite_vehicule.nombre_places,
      numero_chassis: souscriptionData.identite_vehicule.numero_chassis,
      puissance_fiscale: souscriptionData.identite_vehicule.puissance_fiscale,
      type_vehicule: souscriptionData.identite_vehicule.type_vehicule,
      usage_vehicule: souscriptionData.identite_vehicule.usage_vehicule,
      valeur_a_neuf: souscriptionData.identite_vehicule.valeur_a_neuf,
      valeur_venale: souscriptionData.identite_vehicule.valeur_venale,
    };

    data.conducteur = {
      date_delivrance_permis:
        souscriptionData.identite_vehicule.date_delivrance_permis,
      numero_permis_conduire:
        souscriptionData.identite_vehicule.numero_permis_conduire,
    };

    data.garanties = souscriptionData.garanties?.map((garantie) => {
      return {
        uuid: garantie.uuid,
        risques: garantie.risques?.map((risque) => {
          return {
            uuid: risque.uuid,
            value: risque.value,
          };
        }),
      };
    });

    data.garanties_complementaires =
      souscriptionData.garanties_complementaires?.map((garantie) => {
        return {
          uuid: garantie.uuid,
          value: garantie.value,
        };
      });

    data.carte_rose = souscriptionData.carte_rose;
    data.cout_police = souscriptionData.cout_police;
    data.droit_de_timbre_automobile =
      souscriptionData.droit_de_timbre_automobile;
    data.montant_commission = souscriptionData.montant_commission;
    data.montant_gestion_pool = souscriptionData.montant_gestion_pool;
    data.prime_nette = souscriptionData.prime_nette;
    data.prime_nette_iac = souscriptionData.prime_nette_iac;
    data.prime_nette_totale = souscriptionData.prime_nette_totale;
    data.prime_ttc = souscriptionData.prime_ttc;
    data.taxes = souscriptionData.taxes;
    data.numero_proposition = souscriptionData.numero_proposition;
    data.fichier_carte_grise = souscriptionData.fichier_carte_grise;

    return data;
  } catch (error) {
    return null;
  }
};

/*
 * Generate a unique number with a given length
 * @param {number} length - The length of the number to generate
 * @returns {number} - The generated number
 */
export function generateUniqueNumber(length) {
  const min = 10 ** (length - 1); // 10^11
  const max = 10 ** length - 1; // 10^12 - 1
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export const isDateBeforeDateEffet = (dateAvenant, dateEffet) => {
  if (!dateAvenant || !dateEffet) {
    return true;
  }
  if (!dayjs(dateAvenant).isValid() || !dayjs(dateEffet).isValid()) {
    return true;
  }
  return dayjs(dateAvenant).isBefore(dayjs(dateEffet));
};

export const collecteDataForExtensionGaranties = (data) => {
  try {
    const withFranchise = data.garanties
      .find(
        (g) =>
          g.libelle ===
          GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE
      )
      ?.risques.find((r) => r.libelle === "Franchise")?.checked
      ? 1
      : 0;

    let extensionGarantiesData = {};

    extensionGarantiesData.garanties = data.garanties?.map((garantie) => {
      return {
        uuid: garantie.uuid,
        risques: garantie.risques?.map((risque) => {
          return {
            uuid: risque.uuid,
            value: risque.value,
          };
        }),
      };
    });

    extensionGarantiesData.conditions_particulieres = {
      with_franchise: withFranchise,
      pool_avaries_facultatives_uuid:
        /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          data.conditions_particulieres.pool_avaries_facultatives_uuid
        )
          ? data.conditions_particulieres.pool_avaries_facultatives_uuid
          : null,
    };

    extensionGarantiesData.date_avenant = data.date_avenant;

    return extensionGarantiesData;
  } catch (error) {
    return null;
  }
};

export const calculateSouscriptionDuration = (conditionsParticulieres) => {
  try {
    const insuranceDuration =
      conditionsParticulieres
        .pool_directives_tarifaires_has_insurances_durations
        ?.insurances_durations?.months_number;
    if (isNaN(insuranceDuration)) {
      return 0;
    }
    const dateEffet = conditionsParticulieres.date_effet;
    return dayjs(dayjs(dateEffet).add(insuranceDuration, "months")).diff(
      dayjs(dateEffet),
      "days"
    );
  } catch (error) {
    return 0;
  }
};

export const calculateCoefficient = (
  dateAvenant,
  dateEcheance,
  souscription
) => {
  try {
    return (
      (dayjs(dateEcheance)
        .add(1, "minutes")
        .subtract(1, "day")
        .diff(
          dayjs(dateAvenant).hour(0).minute(0).second(0).add(1, "day"),
          "days"
        ) +
        1) /
      calculateSouscriptionDuration(souscription?.conditions_particulieres)
    ).toFixed(4);
  } catch (error) {
    return 0;
  }
};

/**
 * Vérifie si la modification de la date de prise d'effet est possible
 * Elle retourne true uniquement si tous les avenants de la souscription sont soit
 * une affaire nouvelle soit une modification de prise d'effet
 * @param {object} souscription - La souscription à vérifier
 * @returns {boolean} - True si la modification de la date de prise d'effet est possible, false sinon
 */
export const canActivatePriseEffet = (souscription) => {
  try {
    const allowedAvenants = [
      TYPES_AVENANTS.AFFAIRE_NOUVELLE,
      TYPES_AVENANTS.MODIFICATION_PRISE_EFFET,
    ];
    return souscription?.avenants?.every((avenant) =>
      allowedAvenants.includes(avenant.type_avenant)
    );
  } catch (error) {
    return false;
  }
};
