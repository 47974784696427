import { Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import React from "react";

function ConditionsParticulieresInfos({ souscription, styles }) {
  const avenantAffaireNouvelle = (souscription) => {
    return souscription?.avenants?.find(
      (avenant) => avenant?.type_avenant === "AFFAIRE_NOUVELLE"
    );
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "50%", padding: 3 }}>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Date d'émission</Text>
            </View>
            <View>
              <Text>
                {dayjs(
                  avenantAffaireNouvelle(souscription)?.created_at ||
                    souscription?.created_at
                ).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>

          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Date d'effet</Text>
            </View>
            <View>
              <Text>
                {dayjs(
                  souscription?.conditions_particulieres?.date_effet
                ).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>

          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Date d'échéance</Text>
            </View>
            <View>
              <Text>
                {dayjs(
                  souscription?.conditions_particulieres?.date_echeance
                ).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Heure d'échéance</Text>
            </View>
            <View>
              <Text>Minuit</Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>
                Durée totale du contrat
              </Text>
            </View>
            <View>
              <Text>
                {dayjs(souscription?.conditions_particulieres?.date_echeance)
                  .add(1, "minute")
                  .diff(
                    dayjs(souscription?.conditions_particulieres?.date_effet),
                    "day"
                  )}{" "}
                jours
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>N° Avenant</Text>
            </View>
            <View>
              <Text>000000</Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>
                {souscription?.souscription_polices?.numero_police
                  ? "N° Attestation"
                  : "Durée de validité de la proposition"}
              </Text>
            </View>
            <View>
              <Text>
                {souscription?.souscription_polices?.numero_police
                  ? souscription?.souscription_polices?.numero_attestation
                  : "60 jours"}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ width: "50%", padding: 3 }}>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Branche</Text>
            </View>
            <View>
              <Text>Automobile</Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>
                Numéro de{" "}
                {souscription?.souscription_polices?.numero_police
                  ? "police"
                  : "proposition"}
              </Text>
            </View>
            <View>
              <Text>
                {souscription?.souscription_polices?.numero_police ||
                  souscription?.numero_proposition}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Statut du document</Text>
            </View>
            <View>
              <Text>
                {souscription?.souscription_polices?.numero_police?.length > 0
                  ? "Contrat Ferme"
                  : "Contrat Non Ferme"}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Bureau de souscription</Text>
            </View>
            <View style={{ marginLeft: 10 }}>
              <Text style={{ marginLeft: 10 }}>
                {souscription?.souscription_origine?.compagnies_agencies
                  ? souscription?.souscription_origine?.compagnies_agencies
                      ?.denomination
                  : souscription?.souscription_origine?.intermediaires_agencies
                      ?.denomination}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Intermédiaire</Text>
            </View>
            <View>
              <Text>
                {souscription?.souscriptions?.compagnies?.denomination}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Mouvement</Text>
            </View>
            <View>
              <Text>
                {
                  souscription?.avenants[souscription?.avenants?.length - 1]
                    ?.type_avenant
                }
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Catégorie</Text>
            </View>
            <View>
              <Text>4</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default ConditionsParticulieresInfos;
