import { View } from "@react-pdf/renderer";
import React from "react";

function PageContentWrapper({ children, souscription }) {
  return (
    <React.Fragment>
      <View
        fixed
        style={{
          height: Number(souscription?.paramsImpression?.top) + 20,
        }}
      ></View>
      {children}
      <View
        fixed
        style={{
          height: Number(souscription?.paramsImpression?.bottom) + 50,
        }}
      ></View>
    </React.Fragment>
  );
}

export default PageContentWrapper;
