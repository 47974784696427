import { View, Image } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

const PreviewTemplateLayout = ({ souscription }) => {
  return (
    <>
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
        fixed
      >
        <Image
          src={`${process.env.REACT_APP_API_URL}uploads/print_templates/${souscription?.paramsImpression?.print_template}`}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </View>
    </>
  );
};

PreviewTemplateLayout.propTypes = {
  souscription: PropTypes.object.isRequired,
};

export default PreviewTemplateLayout;
