import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import CompanyFormModal from "../../components/modalForms/CompanyFormModal";
import Page from "../../components/Page";
import * as compagniesService from "../../services/comagniesService";
import { toast } from "react-toastify";
import PermissionWrapper from "../../components/PermissionWrapper";

function CompagniesList() {
  const [compagnies, setCompagnies] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });

  const fetchCompagnies = async ({ page, search } = {}) => {
    try {
      setLoading(true);
      const response = await compagniesService.fetchAll({ page, search });
      if (response.status === 200) {
        setPagination({
          currentPage: response.data.currentPage,
          totalPages: response.data.totalPages,
          totalCount: response.data.totalCount,
          prevPage: response.data.prevPage,
          nextPage: response.data.nextPage,
        });
        setCompagnies(response.data.compagnies);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseModal = () => {
    setSelectedCompanyUuid(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchCompagnies({ page: pagination.currentPage, search: search });
    return () => {};
  }, [pagination.currentPage, search]);

  return (
    <Page title="Liste des compagnies">
      <GridRow className="Compagnies">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>Compagnies</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to="/pool/administration/compagnies"
              >
                Liste des compagnies
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Compagnies</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <PermissionWrapper
              requiredPermissions={[
                "pool.administration.company.list",
              ]}
            >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" onChange={handleSearchChange} />
            </Input>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                "pool.administration.company.add",
              ]}
            >
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
            </PermissionWrapper>
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Dénomination</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Code</Table.HeaderCell>
                  <Table.HeaderCell>Siège Social</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Téléphones</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right" collapsing>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {compagnies.map((company, index) => {
                  return (
                    <Table.Row key={`${company.idcompagnies}`}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{company.denomination}</Table.Cell>
                      <Table.Cell>{company.code}</Table.Cell>
                      <Table.Cell>{company.siege_social}</Table.Cell>
                      <Table.Cell>{company.email}</Table.Cell>
                      <Table.Cell>
                        {company.phone_1}/{company.phone_2}
                      </Table.Cell>
                      <Table.Cell>
                        <Label
                          size="mini"
                          color={company.is_active === 1 ? "green" : "red"}
                        >
                          {`${company.is_active === 1 ? "Actif" : "Inactif"}`}
                        </Label>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <PermissionWrapper
                              requiredPermissions={[
                                "pool.administration.company.details",
                              ]}
                            >
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() =>
                                navigate(`details/${company.uuid}`)
                              }
                            />
                            </PermissionWrapper>
                            <PermissionWrapper
                              requiredPermissions={[
                                "pool.administration.company.edit",
                              ]}
                            >
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                setSelectedCompanyUuid(company.uuid);
                                setOpen(true);
                              }}
                            />
                            </PermissionWrapper>
                            <PermissionWrapper
                              requiredPermissions={[
                                "pool.administration.company.delete",
                              ]}
                            >
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Supprimer"
                            />
                            </PermissionWrapper>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8" textAlign="right">
                    <Pagination
                      activePage={pagination.currentPage}
                      totalPages={pagination.totalPages}
                      onPageChange={handlePageChange}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>
      <CompanyFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchCompagnies({ page: pagination.currentPage, search: search });
          handleCloseModal();
        }}
        selectedCompanyUuid={selectedCompanyUuid}
        handleClose={handleCloseModal}
      />
    </Page>
  );
}

export default CompagniesList;
