import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";
import PermissionWrapper from "../PermissionWrapper";

function IntermediaireMenus({
  activeIndex,
  activeSubMenu,
  handleItemClick,
  handleSubMenuClick,
}) {
  return (
    <Accordion
      as={Menu}
      fluid
      vertical
      style={{ borderRadius: 0, border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="intermediaire/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Tableau de bord
        </span>
      </Menu.Item>
      <PermissionWrapper requiredPermissions={["agent_general.production.view"]}>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "production"}
          onClick={handleItemClick}
          index="production"
        >
          <Icon name="bus" /> Production
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "production"}>
          <Menu.Menu>
            <PermissionWrapper requiredPermissions={["agent_general.production.dashboard.view"]}>
            <Menu.Item
              as={Link}
              index={"intermediaire/production/dashboard"}
              active={activeSubMenu === "intermediaire/production/dashboard"}
              onClick={handleSubMenuClick}
              to="intermediaire/production/dashboard"
            >
              Tableau de bord
            </Menu.Item>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={["agent_general.production.subscriptors.view"]}>
            <Menu.Item
              as={Link}
              index={"intermediaire/production/souscripteurs"}
              active={
                activeSubMenu === "intermediaire/production/souscripteurs"
              }
              onClick={handleSubMenuClick}
              to="intermediaire/production/souscripteurs"
            >
              Souscripteurs
            </Menu.Item>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={["agent_general.production.polices.view"]}>
            <Menu.Item
              as={Link}
              to="intermediaire/production/polices"
              index={"intermediaire/production/polices"}
              active={activeSubMenu === "intermediaire/production/polices"}
              onClick={handleSubMenuClick}
            >
              Polices
            </Menu.Item>
            </PermissionWrapper>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["agent_general.clams.view"]}>
      <Menu.Item>
        <Accordion.Title
          index="gestion"
          onClick={handleItemClick}
          active={activeIndex === "gestion"}
        >
          <Icon name="car crash" /> Sinistres
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "gestion"}>
          <Menu.Menu>
            <Menu.Item as="a">Déclaration d'un sinistre</Menu.Item>
            <Menu.Item as="a">Estimation</Menu.Item>
            <Menu.Item as="a">Règlement</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["agent_general.attestations.view"]}>
      <Menu.Item>
        <Accordion.Title
          index="stocks"
          active={activeIndex === "stocks"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Attestations
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks"}>
          <Menu.Menu>
            <PermissionWrapper requiredPermissions={["agent_general.attestations.request.view"]}>
            <Menu.Item
              as={Link}
              to="intermediaire/stocks/demandes"
              index={"intermediaire/stocks/demandes"}
              active={activeSubMenu === "intermediaire/stocks/demandes"}
              onClick={handleSubMenuClick}
            >
              Demandes
            </Menu.Item>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={["agent_general.attestations.distribution-requests.view"]}>
            <Menu.Item
              as={Link}
              to="intermediaire/stocks/distributions-requests"
              index={"intermediaire/stocks/distributions-requests"}
              active={
                activeSubMenu === "intermediaire/stocks/distributions-requests"
              }
              onClick={handleSubMenuClick}
            >
              Stock réseaux
            </Menu.Item>
            </PermissionWrapper>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["agent_general.reports.view"]}>
      <Menu.Item>
        <Accordion.Title
          index="etats"
          active={activeIndex === "etats"}
          onClick={handleItemClick}
        >
          <Icon name="file alternate" /> Etats
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "etats"}>
          <Menu.Menu>
            <PermissionWrapper requiredPermissions={["agent_general.reports.production-slips.view"]}>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={["agent_general.reports.claims-settlements.view"]}>
            <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={["agent_general.reports.claims-advices.view"]}>
            <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
            </PermissionWrapper>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["agent_general.agency.view"]}>
      <Menu.Item
        as={Link}
        to="intermediaire/details"
        active={activeIndex === "monEntreprise"}
        index={"details"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="building" /> Entreprise
        </span>
      </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["agent_general.settings-permissions.view"]}>
      <Menu.Item>
        <Accordion.Title
          index="configuration-permissions"
          onClick={handleItemClick}
          active={activeIndex === "configuration-permissions"}
        >
          <Icon name="user secret" /> Config. des permissions
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "configuration-permissions"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="intermediaire/configuration-permissions/groupes-permissions"
              active={
                activeSubMenu ===
                "intermediaire/configuration-permissions/groupes-permissions"
              }
              onClick={handleSubMenuClick}
              index={
                "intermediaire/configuration-permissions/groupes-permissions"
              }
            >
              Groupes de permissions
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      </PermissionWrapper>
    </Accordion>
  );
}

export default IntermediaireMenus;
