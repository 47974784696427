import React from "react";
import Page from "../../components/Page";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import PermissionWrapper from "../../components/PermissionWrapper";
import AccessDeniedPage from "../../components/AccessDeniedPage";

function SinistresList() {
  return (
    <Page title="Sinistres">
      <GridRow className="Sinistres">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Sinistres</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <PermissionWrapper
              requiredPermissions={["company.clams.disaster-list.list"]}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
            </PermissionWrapper>
            {/* <Button
            style={{ marginLeft: "10px" }}
            circular
            icon={<Icon name="add" />}
          /> */}
          </Grid.Column>
        </Grid>
        <Segment>
          <PermissionWrapper
            requiredPermissions={["company.clams.disaster-list.list"]}
            fallback={<AccessDeniedPage resourceName="Liste des Sinistres" />}
          >
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Numéro</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Création</Table.HeaderCell>
                  <Table.HeaderCell>Police</Table.HeaderCell>
                  <Table.HeaderCell>Souscripteur</Table.HeaderCell>
                  <Table.HeaderCell>Paiement</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {/* avenats?.map((avenant, index) => {
              return (
                <Table.Row key={`${avenant.idavenents}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    {generateAvenantserieNumber(avenant.numero_avenant)}
                  </Table.Cell>
                  <Table.Cell>{avenant.types_avenants?.libelle}</Table.Cell>
                  <Table.Cell>
                    {dayjs(avenant.created_at).format("DD/MM/YYYY HH:mm")}
                  </Table.Cell>
                  <Table.Cell>
                    {avenant.polices?.num_police || "Aucune"}
                  </Table.Cell>
                  <Table.Cell>{`${avenant.polices?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}
                  ${avenant.polices?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name}
                  `}</Table.Cell>
                  <Table.Cell>
                    {avenant?.facturation?.payments?.idpayments ? (
                      <>
                        <Icon name="check" color="green" />
                        <span style={{ marginLeft: "5px" }}>
                          {dayjs(
                            avenant?.facturation?.payments?.date_paiement
                          ).format("DD-MM-YYYY HH:mm")}
                        </span>
                      </>
                    ) : (
                      <Icon name="close" color="red" />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() => navigate(`details/${avenant.uuid}`)}
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                        />
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Supprimer"
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              );
            }) */}
              </Table.Body>
            </Table>
          </PermissionWrapper>
        </Segment>
      </GridRow>
    </Page>
  );
}

export default SinistresList;
