import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Grid,
  GridColumn,
  Header,
  Icon,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import * as avariesFacultativesService from "../../../services/gestion_production/pool/avariesFacultativesService";
import * as companyDirectiveValuesService from "../../../services/gestion_production/company/directivesTarifairesValueService";
import * as directivesTarifaireValuesService from "../../../services/gestion_production/directivesTarifairesValueService";
import * as sourcesEnergiesService from "../../../services/sourcesEnergiesService";
import * as zonesCirculationService from "../../../services/zonesCirculationService";
import * as poolInsuranceDurationService from "../../../services/gestion_production/pool/insurancesDurationsService";
import * as categoriesContratsService from "../../../services/categoriesContratsService";
import * as garantiesComplementairesService from "../../../services/gestion_production/company/garantiesComplementairesService";

import GarantieItem from "../../production/souscriptions/components/GarantieItem";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import numeral from "numeral";
import dayjs from "dayjs";
import {
  calculateCoefficient,
  calculateSouscriptionDuration,
  collectChangementVehiculeData,
  collecteDataForExtensionGaranties,
  initializeGaranties,
  isDateBeforeDateEffet,
} from "../../../utils/souscription_helper";
import useCalculateSouscription from "../../../hooks/souscription/useCalculateSouscription";
import GarantiesSection from "../../production/souscriptions/components/GarantiesSection";

const validationSchema = yup.object({
  date_avenant: yup.date().required("valeur requise"),
  montant_commission: yup.number(),
  prime_nette: yup.number(),
  prime_nette_totale: yup.number(),
  prime_nette_iac: yup.number(),
  cout_police: yup.number(),
  taxes: yup.number(),
  montant_gestion_pool: yup.number(),
  carte_rose: yup.number(),
  droit_de_timbre_automobile: yup.number(),
  garanties: yup.array().required("valeur requise").min(1),
  //fichier_carte_grise: yup.mixed().required("valeur requise"),
  conditions_particulieres: yup
    .object({
      date_echeance: yup.date().required("valeur requise"),
      date_effet: yup
        .date()
        .required("valeur requise")
        .default(
          dayjs(yup.ref("conditions_particulieres.date_proposition"))
            .add(1, "day")
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DD HH:mm")
        ),
      type_renouvellement: yup.string().required("valeur requise"),
      pool_avaries_facultatives_uuid: yup
        .mixed()
        .test(
          "valid-uuid-or-zero-or-null-or-empty ",
          "Doit être un UUID valide, 0 ou null ou vide",
          (value) =>
            value === null ||
            value === 0 ||
            (typeof value === "string" &&
              /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                value
              ))
        ),
    })
    .required("valeur requise"),
  identite_vehicule: yup.object({
    idsources_energie: yup.number().required("valeur requise"),
    usage_vehicule: yup
      .string()
      .oneOf(["TRAPUBVOY"])
      .required("valeur requise")
      .default("TRAPUBVOY"),
    puissance_fiscale: yup.number().min(1).required("valeur requise"),
    nombre_places: yup.number().min(3).max(100).required("valeur requise"),
    marque_vehicule: yup.string().required("valeur requise"),
    type_vehicule: yup.string().required("valeur requise"),
    genre_vehicule: yup.string().required("valeur requise"),
    date_premiere_mise_en_circulation: yup.date(),
    valeur_a_neuf: yup.number().required("valeur requise"),
    valeur_venale: yup
      .number()
      .required("valeur requise")
      .test(
        "inferieur-valeur-neuf",
        "La valeur vénale doit être inférieure à la valeur à neuf",
        function (valeur_venale) {
          return valeur_venale < this.parent.valeur_a_neuf;
        }
      ),
    immatriculation_vehicule: yup.string().required("valeur requise"),
    immatriculation_vehicule_precedente: yup.string(),
    numero_chassis: yup.string().required("valeur requise"),
    numero_permis_conduire: yup.string().optional().nullable(),
    numero_police: yup.string(),
    date_delivrance_permis: yup.date().optional().nullable(),
    idzones_circulation: yup.number().required("valeur requise"),
    carrosserie: yup.string(),
  }),
  garanties_complementaires: yup.array(
    yup.object({
      uuid: yup.string().required("valeur requise"),
      value: yup.number().min(0).required("valeur requise").nullable(),
    })
  ),
});

function AvenantChangementVehicule({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const userData = useRecoilValue(authState);
  const [souscription, setSouscription] = useState(null);
  const [sourcesEnergies, setSourcesEnergies] = useState([]);
  const [zonesCirculation, setZonesCirculation] = useState([]);

  const [insuranceDurations, setInsuranceDurations] = useState([]);
  const [garantiesComplementaires, setGarantiesComplementaires] = useState([]);
  const [avariesFacultatives, setAvariesFacultatives] = useState([]);
  const [companyDirectiveValues, setCompanyDirectiveValues] = useState([]);
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState(null);
  const [directivesTarifairesValues, setDirectivesTarifairesValues] = useState(
    []
  );
  const [categoriesContrats, setCategoriesContrats] = useState([]);

  const calculatePrimeAvenantChangementhicule = (dateAvenant, dateEcheance) => {
    // Convertir les dates en objets dayjs
    const dateAvenantObj = dayjs(dateAvenant);
    const dateEcheanceObj = dayjs(dateEcheance).add(1, "minute");
    let oldPrimeAuto =
      Number(souscription?.prime_nette_totale) -
      Number(souscription?.prime_nette_iac);
    let newPrimeAuto =
      Number(values.prime_nette_totale) - Number(values.prime_nette_iac);

    // Calculer le nombre de jours restants jusqu'à l'échéance
    const nbJoursRestants = dateEcheanceObj.diff(dateAvenantObj, "days");
    console.log("nbJoursRestants", nbJoursRestants);
    const nbJoursTotal = calculateSouscriptionDuration(
      souscription?.conditions_particulieres
    );
    console.log("nbJoursTotal", nbJoursTotal);
    let isRistourne = false;

    // Calculer le prorata
    const prorata = calculateCoefficient(
      values?.date_avenant,
      souscription?.conditions_particulieres?.date_echeance,
      souscription
    );
    const oldFraisAretirer =
      Number(souscription?.cout_police) +
      Number(souscription?.montant_gestion_pool);
    const newFraisAretirer =
      Number(values?.cout_police) + Number(values?.montant_gestion_pool);

    // Calculer la différence de prime
    let differencePrime = Number(newPrimeAuto) - Number(oldPrimeAuto);
    if (differencePrime < 0) {
      isRistourne = true;
    }
    differencePrime = isRistourne
      ? Number(newPrimeAuto) -
        newFraisAretirer -
        (Number(oldPrimeAuto) - oldFraisAretirer)
      : differencePrime;

    // Calculer le montant au prorata
    const montantProrata = differencePrime * prorata;

    console.log("montantProrata", montantProrata);

    return {
      primeAPayerOuRistourne: montantProrata,
      isRistourne: isRistourne,
    };
  };

  const formik = useFormik({
    initialValues: {
      date_avenant: dayjs().format("YYYY-MM-DD HH:mm"),
      idsouscriptions: "",
      idsouscripteurs: "",
      numero_police: "",
      numero_proposition: "",
      montant_commission: 0,
      prime_nette_iac: 0,
      prime_nette: 0,
      prime_nette_totale: 0,
      cout_police: 0,
      taxes: 0,
      montant_gestion_pool: 0,
      carte_rose: 0,
      droit_de_timbre_automobile: 0,
      prime_ttc: 0,
      garanties: [],
      conditions_particulieres: {
        date_echeance: dayjs()
          .add(12, "months")
          .add(-1, "day")
          .format("YYYY-MM-DD HH:mm"),
        date_effet: dayjs()
          .add(1, "day")
          .hour(0)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DD HH:mm"),
        type_renouvellement: "",
        pool_avaries_facultatives_uuid: null,
      },
      identite_vehicule: {
        idzones_circulation: "",
        idsources_energie: "",
        usage_vehicule: "TRAPUBVOY",
        puissance_fiscale: "",
        nombre_places: "",
        marque_vehicule: "",
        type_vehicule: "",
        genre_vehicule: "",
        date_premiere_mise_en_circulation: "",
        valeur_a_neuf: "",
        valeur_venale: "",
        immatriculation_vehicule: "",
        immatriculation_vehicule_precedente: "",
        numero_chassis: "",
        numero_permis_conduire: "",
        date_delivrance_permis: "",
        carrosserie: "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { primeAPayerOuRistourne, isRistourne } =
        calculatePrimeAvenantChangementhicule(
          values.date_avenant,
          souscription?.conditions_particulieres?.date_echeance
        );

      let data = {
        prime_nette_a_payer: isRistourne ? 0 : Math.abs(primeAPayerOuRistourne),
        ristourne: isRistourne ? Math.abs(primeAPayerOuRistourne) : 0,
        date_avenant: values.date_avenant,
        ...collectChangementVehiculeData(values),
      };

      try {
        const response = await avenantsService.createAvenantChangementVehicule(
          souscription_uuid,
          data
        );
        toast.success("Avenant créé avec succès");
        onSuccessCallBack();
        setOpen(false);
      } catch (error) {
        toast.error("Une erreur est survenue lors de la création de l'avenant");
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const { values, errors, handleBlur, handleChange, setFieldValue } = formik;

  const calculateSouscription = useCalculateSouscription(
    values,
    setFieldValue,
    insuranceDurations,
    directivesTarifairesValues,
    companyDirectiveValues,
    userData?.user?.intermediaire?.type_intermediaire,
    open
  );

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(
        souscription_uuid,
        null,
        true
      );

      if (response?.status !== 200 || !response?.data) {
        throw new Error(
          "Une erreur est survenue lors de la récupération de la souscription"
        );
      }
      console.log("prime_nette_totale", response?.data?.prime_nette_totale);
      setSouscription(response?.data);

      formik.setValues({
        identite_vehicule: {
          ...response?.data?.souscription_identite_vehicule,
          date_premiere_mise_en_circulation: dayjs(
            response?.data?.souscription_identite_vehicule
              ?.date_premiere_mise_en_circulation
          ).format("YYYY-MM-DD"),
          idzones_circulation: "",
          usage_vehicule: "TRAPUBVOY",
          marque_vehicule: "",
          type_vehicule: "",
          genre_vehicule: "",
          immatriculation_vehicule: "",
          immatriculation_vehicule_precedente: "",
          numero_chassis: "",
          numero_permis_conduire: "",
          date_delivrance_permis: "",
          carrosserie: "",
        },
        conditions_particulieres: {
          date_effet: dayjs(response?.data?.date_effet).format(
            "YYYY-MM-DD HH:mm"
          ),
          date_echeance: dayjs(response?.data?.date_echeance).format(
            "YYYY-MM-DD HH:mm"
          ),
          type_renouvellement:
            response?.data?.conditions_particulieres
              ?.pool_directives_tarifaires_has_insurances_durations?.uuid,
          pool_avaries_facultatives_uuid:
            response?.data?.conditions_particulieres?.pool_avaries_facultatives
              ?.uuid || null,
        },
        carte_rose: Number(response?.data?.carte_rose),
        cout_police: Number(response?.data?.cout_police),
        droit_de_timbre_automobile: Number(
          response?.data?.droit_de_timbre_automobile
        ),
        prime_nette_iac: Number(response?.data?.prime_nette_iac),
      });

      const garanties = [];
      response?.data?.souscription_garanties?.forEach((item) => {
        garanties.push({
          libelle:
            item.company_has_liste_garanties
              .pool_directives_tarifaires_has_listes_garanties.liste_garanties
              .name,
          uuid: item.company_has_liste_garanties.uuid,
          code: item.code || item.company_has_liste_garanties.code,
          is_required:
            item.company_has_liste_garanties
              .pool_directives_tarifaires_has_listes_garanties.is_required ===
            1,
          risques: item.souscription_garanties_risques?.map((risque) => {
            return {
              libelle: risque.liste_garanties_risques.name,
              uuid: risque.liste_garanties_risques.uuid,
              checked:
                item.company_has_liste_garanties
                  .pool_directives_tarifaires_has_listes_garanties
                  .is_required === 1 || Number(risque.value) > 0,
              value: Number(risque.value),
            };
          }),
        });
      });
      formik.setFieldValue("garanties", garanties, true);
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la récupération de la souscription"
      );
    }
  };

  const fetchSourcesEnergies = async () => {
    const response = await sourcesEnergiesService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data?.sources)) {
      const _sourcesEnergies = response?.data.sources;
      _sourcesEnergies?.push({
        idsources_energie: "",
        libelle: "Choisir...",
        description: "",
      });
      setSourcesEnergies(_sourcesEnergies);
    }
  };

  const fetchZonesCirculation = async () => {
    const response = await zonesCirculationService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data.zones)) {
      setZonesCirculation(response?.data?.zones);
    }
  };

  const handleValeurNeufChange = useCallback(
    (value) => {
      try {
        setFieldValue("identite_vehicule.valeur_a_neuf", value, true);
        //initializeGaranties(formik);
      } catch (error) {
        console.error("Erreur lors du changement de valeur à neuf:", error);
      }
    },
    [setFieldValue, formik]
  );

  const handleValeurVenaleChange = async (value) => {
    try {
      setFieldValue("identite_vehicule.valeur_venale", value, true);
      //initializeGaranties(formik);
    } catch (error) {}
  };

  const handleDatePremiereMiseEnCirculationChange = async (value) => {
    try {
      setFieldValue(
        "identite_vehicule.date_premiere_mise_en_circulation",
        value,
        true
      );
      //initializeGaranties(formik);
    } catch (error) {}
  };

  const getDirectivesTarifairesValues = async () => {
    directivesTarifaireValuesService.getByActiveDirectivesTarifaires().then(
      (response) => {
        const directiveValue = (data, name) =>
          data.find((item) => item.name === name);
        setDirectivesTarifairesValues(response.data);
        formik.setFieldValue(
          "prime_nette_iac",
          directiveValue(response.data, "prime_nette_individuelle_conducteur")
            ?.value
        );
        formik.setFieldValue(
          "cout_police",
          directiveValue(response.data, "cout_police")?.value
        );
        formik.setFieldValue(
          "carte_rose",
          directiveValue(response.data, "carte_rose")?.value
        );

        formik.setFieldValue(
          "droit_de_timbre_automobile",
          directiveValue(response.data, "droit_de_timbre_automobile")?.value
        );
      },
      () => {}
    );
  };

  const fetchInsuranceDurations = async () => {
    poolInsuranceDurationService
      .getByActiveDirectiveTarifaires()
      .then(
        (response) => {
          setInsuranceDurations(response.data);
        },
        () => {}
      )
      .finally(() => {});
  };

  const fetchAvariesFacultatives = async () => {
    try {
      const response =
        await avariesFacultativesService.getByActiveDirectiveTarifaires(
          userData?.user
        );
      setAvariesFacultatives(response?.data);
    } catch (error) {}
  };

  const fetchCompanyDirectiveValues = async () => {
    try {
      const response =
        await companyDirectiveValuesService.getByActiveDirectivesTarifaires(
          userData?.user?.intermediaire?.type_intermediaire === "COURTIER"
            ? selectedCompanyUuid || null
            : userData?.user?.intermediaire?.company?.uuid || null
        );
      setCompanyDirectiveValues(response?.data);
    } catch (error) {
      setCompanyDirectiveValues([]);
    }
  };

  const fetchCategoriesContrats = async () => {
    const response = await categoriesContratsService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data?.categories)) {
      setCategoriesContrats(response?.data.categories);
    }
  };

  const fetchGarantiesComplementaires = async () => {
    try {
      const response = await garantiesComplementairesService.getAll({
        active: true,
      });

      if (response?.status === 200 && Array.isArray(response?.data)) {
        setGarantiesComplementaires(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (open === true) {
      fetchSourcesEnergies();
      fetchZonesCirculation();
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    if (open === true) {
      fetchSouscription();
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    const initializeForm = async () => {
      await Promise.all([
        //fetchCategoriesContrats(),
        getDirectivesTarifairesValues(),
        //fetchCompanyGaranties(),
        fetchInsuranceDurations(),
        //fetchGarantiesComplementaires(),
        fetchAvariesFacultatives(),
        fetchCompanyDirectiveValues(),
      ]);
    };
    initializeForm();
    return () => {};
  }, [userData?.user?.intermediaire?.company?.uuid, selectedCompanyUuid]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      closeIcon
      size="fullscreen"
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de changement de vehicule</Modal.Header>
        <Modal.Content className="ui tiny form">
          <Grid divided>
            <Grid.Column width={3}>
              {souscription && (
                <>
                  <Segment raised>
                    <Header as="h5">Informations de la souscription</Header>
                    <List>
                      <List.Item>
                        <List.Icon name="calendar alternate" />
                        <List.Content>
                          <List.Header>Date de prise d'effet</List.Header>
                          <List.Description>
                            {dayjs(
                              souscription?.conditions_particulieres?.date_effet
                            ).format("DD/MM/YYYY")}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="calendar alternate" />
                        <List.Content>
                          <List.Header>Date d'échéance</List.Header>
                          <List.Description>
                            {dayjs(
                              souscription?.conditions_particulieres
                                ?.date_echeance
                            ).format("DD/MM/YYYY")}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="calendar alternate" />
                        <List.Content>
                          <List.Header>Durée du contrat</List.Header>
                          <List.Description>
                            {calculateSouscriptionDuration(
                              souscription?.conditions_particulieres
                            )}{" "}
                            jours
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Segment>
                  <Segment raised>
                    <Form.Field required error={!!errors.date_avenant}>
                      <label>Date de l'avenant</label>
                      <Input
                        type="date"
                        value={values.date_avenant}
                        onChange={handleChange("date_avenant")}
                        onBlur={handleBlur("date_avenant")}
                      />
                      <small className="field-error">
                        {errors.date_avenant}
                      </small>
                    </Form.Field>
                    <Message>
                      <Message.Content>
                        <p>
                          La mise en vigueur de l'avenant est fixée au lendemain
                          de la date de l'avenant.
                        </p>
                      </Message.Content>
                    </Message>
                  </Segment>
                </>
              )}
            </Grid.Column>
            <Grid.Column width={10}>
              <div className="ui form">
                <h3 className="ui dividing header">
                  Informations sur le nouveau vehicule
                </h3>
                <React.Fragment>
                  <Form.Field
                    required
                    error={"identite_vehicule.usage_vehicule" in errors}
                  >
                    <label>Usage du véhicule</label>
                    <Form.Select
                      value={values?.identite_vehicule?.usage_vehicule}
                      readOnly
                      options={[
                        {
                          key: `TRAPUBVOY`,
                          text: "Transport Public Voyageur",
                          value: "TRAPUBVOY",
                        },
                      ]}
                    />
                  </Form.Field>
                  <Form.Group widths={"3"}>
                    <Form.Field
                      error={"identite_vehicule.idzones_circulation" in errors}
                    >
                      <label>Zone de circulation</label>
                      <Form.Select
                        search
                        value={values?.identite_vehicule?.idzones_circulation}
                        options={zonesCirculation.map((zone) => ({
                          key: `${zone.idzones_circulation}`,
                          text: zone.libelle,
                          value: zone.idzones_circulation,
                        }))}
                        onChange={(e, data) =>
                          setFieldValue(
                            "identite_vehicule.idzones_circulation",
                            data.value,
                            true
                          )
                        }
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      error={"identite_vehicule.puissance_fiscale" in errors}
                    >
                      <label>Puissance fiscale</label>
                      <Form.Input
                        min={1}
                        value={values?.identite_vehicule?.puissance_fiscale}
                        onChange={handleChange(
                          "identite_vehicule.puissance_fiscale"
                        )}
                        onBlur={handleBlur(
                          "identite_vehicule.puissance_fiscale"
                        )}
                      />
                    </Form.Field>
                    <Form.Field
                      error={"identite_vehicule.nombre_places" in errors}
                      required
                    >
                      <label>Nombre de places</label>
                      <Input
                        type="number"
                        min={3}
                        max={100}
                        value={values?.identite_vehicule?.nombre_places}
                        onChange={handleChange(
                          "identite_vehicule.nombre_places"
                        )}
                        onBlur={handleBlur("identite_vehicule.nombre_places")}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      error={"identite_vehicule.idsources_energie" in errors}
                    >
                      <label>Source d'énergie</label>
                      <Form.Select
                        value={
                          values?.identite_vehicule?.idsources_energie || ""
                        }
                        onChange={(e, data) =>
                          setFieldValue(
                            "identite_vehicule.idsources_energie",
                            data.value
                          )
                        }
                        options={sourcesEnergies.map((source) => ({
                          key: `${source.idsources_energie}`,
                          text: source.libelle,
                          value: source.idsources_energie,
                        }))}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={"4"}>
                    <Form.Field
                      error={"identite_vehicule.marque_vehicule" in errors}
                      required
                    >
                      <label>Marque</label>
                      <Input
                        type="text"
                        value={values?.identite_vehicule?.marque_vehicule}
                        onChange={handleChange(
                          "identite_vehicule.marque_vehicule"
                        )}
                        onBlur={handleBlur("identite_vehicule.marque_vehicule")}
                      />
                    </Form.Field>
                    <Form.Field
                      error={"identite_vehicule.type_vehicule" in errors}
                      required
                    >
                      <label>Type du véhicule</label>
                      <Input
                        type="text"
                        value={values?.identite_vehicule?.type_vehicule}
                        onChange={handleChange(
                          "identite_vehicule.type_vehicule"
                        )}
                        onBlur={handleBlur("identite_vehicule.type_vehicule")}
                      />
                    </Form.Field>
                    <Form.Field
                      error={"identite_vehicule.genre_vehicule" in errors}
                      required
                    >
                      <label>Genre du véhicule</label>
                      <Input
                        type="text"
                        value={values?.identite_vehicule?.genre_vehicule}
                        onChange={handleChange(
                          "identite_vehicule.genre_vehicule"
                        )}
                        onBlur={handleBlur("identite_vehicule.genre_vehicule")}
                      />
                    </Form.Field>
                    <Form.Field
                      error={"identite_vehicule.carrosserie" in errors}
                    >
                      <label>Carrosserie</label>
                      <Input
                        type="text"
                        value={values?.identite_vehicule?.carrosserie}
                        onChange={handleChange("identite_vehicule.carrosserie")}
                        onBlur={handleBlur("identite_vehicule.carrosserie")}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={"4"}>
                    <Form.Field
                      error={"identite_vehicule.valeur_a_neuf" in errors}
                      required
                    >
                      <label>Valeur à neuf</label>
                      <Input
                        type="number"
                        min={0}
                        value={values?.identite_vehicule?.valeur_a_neuf}
                        onChange={async (e, data) => {
                          handleValeurNeufChange(data.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field
                      error={"identite_vehicule.valeur_venale" in errors}
                      required
                    >
                      <label>Valeur vénale</label>
                      <Input
                        type="number"
                        min={0}
                        value={values?.identite_vehicule?.valeur_venale}
                        onChange={async (e, data) => {
                          handleValeurVenaleChange(data.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field
                      error={
                        "identite_vehicule.date_premiere_mise_en_circulation" in
                        errors
                      }
                      required
                    >
                      <label>Date de 1ère mise en circulation</label>
                      <Input
                        type="date"
                        value={
                          values?.identite_vehicule
                            ?.date_premiere_mise_en_circulation
                        }
                        onChange={async (e, data) => {
                          handleDatePremiereMiseEnCirculationChange(data.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      error={
                        "identite_vehicule.immatriculation_vehicule" in errors
                      }
                    >
                      <label>Immatriculation du véhicule</label>
                      <Input
                        type="text"
                        value={
                          values?.identite_vehicule?.immatriculation_vehicule
                        }
                        onChange={handleChange(
                          "identite_vehicule.immatriculation_vehicule"
                        )}
                        onBlur={handleBlur(
                          "identite_vehicule.immatriculation_vehicule"
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={"4"}>
                    <Form.Field
                      required
                      error={"identite_vehicule.numero_chassis" in errors}
                    >
                      <label>Numéro du châssis</label>
                      <Input
                        type="text"
                        value={values?.identite_vehicule?.numero_chassis}
                        onChange={handleChange(
                          "identite_vehicule.numero_chassis"
                        )}
                        onBlur={handleBlur("identite_vehicule.numero_chassis")}
                      />
                    </Form.Field>
                    <Form.Field
                      error={
                        "identite_vehicule.numero_permis_conduire" in errors
                      }
                    >
                      <label>Numéro de Permis du conducteur</label>
                      <Input
                        type="text"
                        value={
                          values?.identite_vehicule?.numero_permis_conduire
                        }
                        onChange={handleChange(
                          "identite_vehicule.numero_permis_conduire"
                        )}
                        onBlur={handleBlur(
                          "identite_vehicule.numero_permis_conduire"
                        )}
                      />
                    </Form.Field>
                    <Form.Field
                      error={
                        "identite_vehicule.date_delivrance_permis" in errors
                      }
                    >
                      <label>Date du permis du conducteur</label>
                      <Input
                        type="date"
                        value={
                          values?.identite_vehicule?.date_delivrance_permis
                        }
                        onChange={handleChange(
                          "identite_vehicule.date_delivrance_permis"
                        )}
                        onBlur={handleBlur(
                          "identite_vehicule.date_delivrance_permis"
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                  {formik.values?.garanties?.length > 0 ? (
                    <GarantiesSection
                      formik={formik}
                      directivesTarifairesValues={directivesTarifairesValues}
                      avariesFacultatives={avariesFacultatives}
                      companyDirectiveValues={companyDirectiveValues}
                    />
                  ) : (
                    <>
                      <GridColumn width={16}>
                        <Message
                          header="Garanties"
                          content="Aucune garantie sélectionnée! Veuillez sélectionner une compagnie si vous êtes un courtier."
                        />
                      </GridColumn>
                    </>
                  )}
                </React.Fragment>
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <Segment>
                <div className="mb-2">
                  <div className="font-bold">Nombre de jours restants</div>
                  <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2 font-bold">
                    {dayjs(
                      souscription?.conditions_particulieres?.date_echeance
                    )
                      .add(1, "minute")
                      .diff(dayjs(values?.date_avenant).add(1, "day"), "days")}
                  </div>
                </div>
                <div>
                  <div className="font-bold">Prime nette RC</div>
                  <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2 font-bold">
                    {numeral(values?.prime_nette).format("")}
                  </div>
                </div>
                <div className="mt-2">
                  <div className="font-bold">Prime nette IAC</div>
                  <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2 font-bold">
                    {numeral(values?.prime_nette_iac).format("")}
                  </div>
                </div>
                <div className="mt-2">
                  <div className="font-bold">Prime nette</div>
                  <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2 font-bold">
                    {numeral(
                      values?.prime_nette_totale - values?.prime_nette_iac
                    ).format("")}
                  </div>
                </div>
                <div className="mt-2">
                  <div className="font-bold">Prime TTC</div>
                  <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2 font-bold">
                    {numeral(values?.prime_ttc).format("")}
                  </div>
                </div>
              </Segment>

              {Number(values.prime_ttc) > 0 &&
                Number(souscription?.prime_nette_totale) > 0 && (
                  <Segment>
                    {Number(souscription?.prime_nette_totale) ===
                    Number(values?.prime_nette_totale) ? (
                      <div>
                        <Icon name="check" />
                        Le véhicule de remplacement est identique au véhicule
                        initial, l'assuré paiera juste le coût de l'avenant.
                      </div>
                    ) : Number(values?.prime_nette_totale) >
                      Number(souscription?.prime_nette_totale) ? (
                      <div>
                        <Icon name="arrow up" />
                        La prime [{numeral(values.prime_nette_totale - values.prime_nette_iac).format()}] du
                        nouveau véhicule est supérieure. L'assuré devra payer la
                        différence de prime nette au prorata.
                        <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2">
                          {numeral(
                            calculatePrimeAvenantChangementhicule(
                              values.date_avenant,
                              souscription?.conditions_particulieres
                                ?.date_echeance
                            ).primeAPayerOuRistourne
                          ).format("0,0")}{" "}
                          FCFA
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Icon name="arrow down" />
                        La prime [{numeral(values.prime_nette_totale - values.prime_nette_iac).format()}] du
                        nouveau véhicule est inférieure. L'assuré recevra une
                        ristourne de prime nette au prorata.
                        <div className="text-right py-2 px-2 rounded-md bg-gray-100 mt-2">
                          {numeral(
                            Math.abs(
                              calculatePrimeAvenantChangementhicule(
                                values.date_avenant,
                                souscription?.conditions_particulieres
                                  ?.date_echeance
                              ).primeAPayerOuRistourne
                            )
                          ).format("0,0")}{" "}
                          FCFA
                        </div>
                      </div>
                    )}
                  </Segment>
                )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={formik.handleSubmit}
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default AvenantChangementVehicule;
