import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import SouscripteursFormModal from "../../../../components/modalForms/SouscripteursFormModal";

import * as souscripteursService from "../../../../services/souscripteursService";

function SelectSouscriptorModal({
  callback = () => {},
  open = false,
  selectedSouscripteur = null,
  setSelectedSouscripteur,
  setOpen,
}) {
  const [souscripteurs, setSouscripteurs] = useState([]);
  const [souscripteursPagination, setSouscripteursPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });
  const [search, setSearch] = useState("");
  // add souscriptor modal status
  const [openAddSouscriptorModal, setOpenAddSouscriptorModal] = useState(false);
  const [
    addSouscriptorModalSelecttedSouscripteurUuid,
    setAddSouscriptorModalSelecttedSouscripteurUuid,
  ] = useState(null);

  const addSouscriptorModalHideModalCallback = () => {
    setAddSouscriptorModalSelecttedSouscripteurUuid(null);
  };

  const fetchSouscripteurs = async (options = {}) => {
    const { page = 1, limit = 4, search = "" } = options;
    try {
      const response = await souscripteursService.fetchAll({
        page,
        limit,
        search,
      });
      if (response.status === 200) {
        setSouscripteurs(response.data?.souscripteurs || []);
        setSouscripteursPagination({
          currentPage: response?.data?.currentPage,
          totalPages: response?.data?.totalPages,
          totalCount: response?.data?.totalCount,
          prevPage: response?.data?.prevPage,
          nextPage: response?.data?.nextPage,
        });
      }
    } catch (error) {}
  };

  const handleSouscripteursPaginationChange = (e, { activePage }) => {
    setSouscripteursPagination({
      ...souscripteursPagination,
      currentPage: activePage,
    });
  };

  useEffect(() => {
    fetchSouscripteurs({
      page: souscripteursPagination?.currentPage,
      limit: 4,
      search,
    });
    return () => {};
  }, [
    souscripteursPagination?.currentPage,
    search,
    souscripteursPagination?.totalPages,
  ]);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon="check" content="Sélection du souscripteur" />
      <Modal.Content scrolling>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpenAddSouscriptorModal(true)}
            />
          </Grid.Column>
        </Grid>
        <Table striped celled size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>email</Table.HeaderCell>
              <Table.HeaderCell>Téléphone</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {souscripteurs.map((souscripteur, index) => {
              return (
                <Table.Row
                  key={`${souscripteur.num}`}
                  className="cursor-pointer"
                >
                  <Table.Cell>
                    <Checkbox
                      label={`${souscripteur.num}`}
                      onChange={(e, data) => {
                        if (data.checked) {
                          setSelectedSouscripteur(souscripteur);
                        } else {
                          setSelectedSouscripteur(null);
                        }
                      }}
                      checked={
                        souscripteur.idsouscripteurs ===
                        selectedSouscripteur?.idsouscripteurs
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {souscripteur.groupe_souscripteur === "ENTREPRISE"
                      ? souscripteur.denomination
                      : `${souscripteur.last_name} ${souscripteur.first_name}`}
                  </Table.Cell>
                  <Table.Cell>{souscripteur.groupe_souscripteur}</Table.Cell>
                  <Table.Cell>{souscripteur.email}</Table.Cell>
                  <Table.Cell>{souscripteur.phone}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5" textAlign="right">
                <Pagination
                  activePage={souscripteursPagination?.currentPage}
                  totalPages={souscripteursPagination?.totalPages}
                  onPageChange={handleSouscripteursPaginationChange}
                  size="mini"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => setOpen(false)} size="mini">
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>

      <SouscripteursFormModal
        callback={() => {
          fetchSouscripteurs();
        }}
        open={openAddSouscriptorModal}
        setOpen={setOpenAddSouscriptorModal}
        uuid={addSouscriptorModalSelecttedSouscripteurUuid}
        hideModalCallback={addSouscriptorModalHideModalCallback}
        onSuccess={(data) => {
          if (data) {
            setSelectedSouscripteur(data);
          }
        }}
      />
    </Modal>
  );
}

export default SelectSouscriptorModal;
