import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import IntermediaireDetails from "../pages/intermediaires/IntermediaireDetails";
import IntermediaireAgencyDetails from "../pages/intermediaires_agencies/IntermediaireAgencyDetails";
import PartnersCompagniesList from "../pages/partenaires/PartnersCompagniesList";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import RequestsList from "../pages/module_gestion_attestation/intermediaire/requests/RequestsList";
import RequestDetails from "../pages/module_gestion_attestation/intermediaire/requests/RequestsDetails";
import DistributionsRequestsList from "../pages/module_gestion_attestation/intermediaire/distributions_requests/DistributionsRequestsList";
import DistributionsRequestsDetails from "../pages/module_gestion_attestation/intermediaire/distributions_requests/DistributionsRequestsDetails";
import IntermediereProductionMono from "../pages/intermediaires/IntermediereProductionMono";
import PolicesList from "../components/production/PolicesList";
import PoliceDetails from "../pages/module_production/polices/PoliceDetails";
import SouscripteurDetails from "../pages/souscripteurs/SouscripteurDetails";
import ProductionDashboard from "../pages/dashboard/ProductionIntermediaireDashboard";
import GroupsPermissionsList from "../pages/configuration_permissions/GroupsPermissionsList";
import CompanyUserDetails from "../pages/comagnies/CompanyUserDetails";

function IntermediaireRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<IntermediaireDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="intermediaire/*">
          <Route
            path="souscriptionsMono"
            element={<IntermediereProductionMono />}
          />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="users/*">
          <Route path="details/:id" element={<CompanyUserDetails />} />
        </Route>
        <Route path="agences/*">
          <Route path=":agence_id/users/:id" element={<CompanyUserDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="agences/*">
          <Route path="details/:id" element={<IntermediaireAgencyDetails />} />
        </Route>
        <Route path="stocks/*">
          <Route path="demandes/*">
            <Route index element={<RequestsList />} />
            <Route path="details/:uuid" element={<RequestDetails />} />
          </Route>
          <Route path="distributions-requests/*">
            <Route index element={<DistributionsRequestsList />} />
            <Route
              path="details/:uuid"
              element={<DistributionsRequestsDetails />}
            />
          </Route>
        </Route>
        <Route path="partenaires/*">
          <Route index element={<PartnersCompagniesList />} />
        </Route>
        <Route path="production/*">
          <Route path="dashboard/*">
            <Route index element={<ProductionDashboard />} />
          </Route>
          <Route path="souscripteurs/*">
            <Route index element={<SouscripteursList />} />
            <Route path="details/:uuid" element={<SouscripteurDetails />} />
          </Route>
          <Route path="polices/*">
            <Route index element={<PolicesList />} />
            <Route path="details/:uuid" element={<PoliceDetails />} />
          </Route>
        </Route>
        <Route path="configuration-permissions/*">
          <Route
            path="groupes-permissions"
            element={<GroupsPermissionsList />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default IntermediaireRoutes;
