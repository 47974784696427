import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import CompanyAgencyDetails from "../pages/compagnies_agencies/CompanyAgencyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CompanyPartnersDetails from "../pages/partenaires/CompanyPartnersDetails";
import CompanyPartnersList from "../pages/partenaires/CompanyPartnersList";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import AvenantsList from "../pages/avenants/AvenantsList";
import SinistresList from "../pages/sinistres/SinistresList";
import SinistreAdd from "../pages/sinistres/SinistreAdd";
import BordereauProduction from "../pages/etats/BordereauProduction";
import CommandesList from "../pages/module_gestion_attestation/company/commandes/CommandesList";
import MagasinsList from "../pages/module_gestion_attestation/company/magasins/MagasinsList";
import CommandesDetails from "../pages/module_gestion_attestation/company/commandes/CommandeDetails";
import DeliveryDetails from "../pages/module_gestion_attestation/company/deliveries/DeliveryDetails";
import RequestsList from "../pages/module_gestion_attestation/company/requests/RequestsList";
import RequestDetails from "../pages/module_gestion_attestation/company/requests/RequestsDetails";
import DistributionsRequestsList from "../pages/module_gestion_attestation/company/distributions_requests/DistributionsRequestsList";
import DistributionsRequestsDetails from "../pages/module_gestion_attestation/company/distributions_requests/DistributionsRequestsDetails";
import DirectivesTarificationsList from "../pages/module_production/company/parametres/tarification/directives_tarifications/DirectivesTarificationsList";
import DirectivesTarificationsDetails from "../pages/module_production/company/parametres/tarification/directives_tarifications/DirectivesTarificationsDetails";
import GarantiesComplementairesListPage from "../pages/module_production/company/parametres/tarification/garanties_complementaires/GarantiesComplementairesListPage";
import MagasinStocks from "../pages/module_gestion_attestation/company/magasins/stocks/MagasinStocks";
import MagasinStockDetails from "../pages/module_gestion_attestation/company/magasins/stocks/MagasinStockDetails";
import PolicesList from "../pages/module_production/polices/PolicesList";
import PoliceDetails from "../pages/module_production/polices/PoliceDetails";
import MargesImpression from "../pages/settings/MargesImpression";
import CompanyUserDetails from "../pages/comagnies/CompanyUserDetails";
import PermissionWrapper from "../components/PermissionWrapper";
import AccessDeniedPage from "../components/AccessDeniedPage";
import ProductionDashboard from "../pages/dashboard/ProductionCompagnieDashboard";
import GroupsPermissionsList from "../pages/configuration_permissions/GroupsPermissionsList";

function CompanyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route
          path="details"
          element={
            <PermissionWrapper
              requiredPermissions={["company.company.view"]}
              fallback={<AccessDeniedPage resourceName="Liste des Sinistres" />}
            >
              <CompanyDetails />
            </PermissionWrapper>
          }
        />
        <Route
          path="companyusers/details/:id"
          element={
            <PermissionWrapper
              requiredPermissions={[
                "company.company.list.details",
                "pool.administration.company.users.details",
              ]}
              fallback={
                <AccessDeniedPage resourceName="aux details de l'utilisateur" />
              }
            >
              <CompanyUserDetails />
            </PermissionWrapper>
          }
        />
        <Route path="agences/*">
          <Route
            path="details/:id"
            element={
              <PermissionWrapper
                requiredPermissions={["company.company.agences.details"]}
                fallback={
                  <AccessDeniedPage resourceName="aux details de l'agence" />
                }
              >
                <CompanyAgencyDetails />
              </PermissionWrapper>
            }
          />
          <Route
            path="agence_utilisateur/details/:id"
            element={<CompanyUserDetails />}
          />
        </Route>
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="partenaires/*">
          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["company.partners.view"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des partenaires" />
                }
              >
                <CompanyPartnersList />
              </PermissionWrapper>
            }
          />
          <Route path="details/:uuid" element={<CompanyPartnersDetails />} />
        </Route>
        <Route path="avenants/*">
          <Route index element={<AvenantsList />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="sinistres/*">
          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["company.clams.disaster-list.view"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des Sinistres" />
                }
              >
                <SinistresList />
              </PermissionWrapper>
            }
          />
          <Route path="create" element={<SinistreAdd />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="etats/*">
          <Route
            path="bordereau-de-production"
            element={
              <PermissionWrapper
                requiredPermissions={["company.reports.production-slips.view"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des bordereaux de production" />
                }
              >
                <BordereauProduction />
              </PermissionWrapper>
            }
          />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="stocks/*">
          <Route path="demandes/*">
            <Route index element={<RequestsList />} />
            <Route path="details/:uuid" element={<RequestDetails />} />
          </Route>
          <Route path="commandes/*">
            <Route
              index
              element={
                <PermissionWrapper
                  requiredPermissions={[
                    "company.attestations.stock.orders.view",
                  ]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des commandes" />
                  }
                >
                  <CommandesList />
                </PermissionWrapper>
              }
            />
            <Route
              path="details/:uuid"
              element={
                <PermissionWrapper
                  requiredPermissions={[
                    "company.attestations.stock.orders.details",
                  ]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des commandes" />
                  }
                >
                  <CommandesDetails />
                </PermissionWrapper>
              }
            />
            <Route path="delivery/:uuid" element={<DeliveryDetails />} />
          </Route>
          <Route path="distributions-requests/*">
            <Route index element={<DistributionsRequestsList />} />
            <Route
              path="details/:uuid"
              element={<DistributionsRequestsDetails />}
            />
          </Route>
          <Route path="magasins/*">
            <Route
              index
              element={
                <PermissionWrapper
                  requiredPermissions={[
                    "company.attestations.stock.store.view",
                  ]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des magasins" />
                  }
                >
                  <MagasinsList />
                </PermissionWrapper>
              }
            />
            <Route path="stocks/*">
              <Route path=":uuid" element={<MagasinStocks />} />
              <Route
                path="stocks-magasins/:uuid"
                element={<MagasinStockDetails />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="production/*">
          <Route path="dashboard/*">
            <Route index element={<ProductionDashboard />} />
          </Route>
          <Route path="polices/*">
            <Route
              index
              element={
                <PermissionWrapper
                  requiredPermissions={["company.production.polices.list"]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des souscripteurs" />
                  }
                >
                  <PolicesList />
                </PermissionWrapper>
              }
            />
            <Route
              path="details/:uuid"
              element={
                <PermissionWrapper
                  requiredPermissions={["company.production.polices.details"]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des souscripteurs" />
                  }
                >
                  <PoliceDetails />
                </PermissionWrapper>
              }
            />
          </Route>
          <Route path="souscripteurs/*">
            <Route
              index
              element={
                <PermissionWrapper
                  requiredPermissions={["company.production.subscription.list"]}
                  fallback={
                    <AccessDeniedPage resourceName="Liste des souscripteurs" />
                  }
                >
                  <SouscripteursList />
                </PermissionWrapper>
              }
            />
          </Route>
          <Route path="parametres-tarification/*">
            <Route path="directives/*">
              <Route
                index
                element={
                  <PermissionWrapper
                    requiredPermissions={[
                      "company.pricing-settings.pricing-guidelines.view",
                    ]}
                    fallback={
                      <AccessDeniedPage resourceName="Directives de tarification" />
                    }
                  >
                    <DirectivesTarificationsList />
                  </PermissionWrapper>
                }
              ></Route>
              <Route
                path="details/:uuid"
                element={<DirectivesTarificationsDetails />}
              />
            </Route>
            <Route path="garanties-complementaires/*">
              <Route
                index
                element={
                  <PermissionWrapper
                    requiredPermissions={[
                      "company.pricing-settings.additional-guarantees.view",
                    ]}
                    fallback={
                      <AccessDeniedPage resourceName="Liste des garanties complémentaires" />
                    }
                  >
                    <GarantiesComplementairesListPage />
                  </PermissionWrapper>
                }
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route path="configuration/*">
          {/*  <Route
          <Route
            path="marges-impressions"
            element={
              <PermissionWrapper
                requiredPermissions={["company.settings.print-margins.view"]}
                fallback={
                  <AccessDeniedPage resourceName="Marges d'impression" />
                }
              >
                <MargesImpression />
              </PermissionWrapper>
            }
          /> */}
          <Route path="marges-impressions" element={<MargesImpression />} />
        </Route>
      </Route>
      <Route path="configuration-permissions/*">
        <Route path="groupes-permissions" element={<GroupsPermissionsList />} />
      </Route>
    </Routes>
  );
}

export default CompanyRoutes;
