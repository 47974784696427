import React from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  GridRow,
  Header,
  Tab,
} from "semantic-ui-react";
import PermissionWrapper from "../../PermissionWrapper";

function PaneInfos() {
  return (
    <Tab.Pane>
      <GridRow as={Form}>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Circonstances/causes</label>
            <Form.Select
              placeholder="Selectionner une circonstance"
              fluid
              search
              selection
              options={[]}
            />
          </Form.Field>
          <Form.Field>
            <label>Possibilité de paiement</label>
            <Form.Select
              placeholder="Possibilité de paiement"
              fluid
              search
              selection
              options={[]}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox options={[]} label="Franchise" />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Probabilité de paiement</label>
            <Form.Select fluid search selection options={[]} />
          </Form.Field>
          <Form.Field>
            <label>Dommages apparents</label>
            <Form.Select fluid search selection options={[]} />
          </Form.Field>
          <Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>N° de cas de barème</label>
                <Form.Input placeholder="Nombre de morts" />
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Checkbox options={[]} label="Règle proportionnelle" />
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Identification risque</label>
            <Form.Input />
          </Form.Field>
          <Form.Field>
            <label>Lieu du sinistre</label>
            <Form.Input placeholder="Nombre de morts" />
          </Form.Field>
          <Form.Field></Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Nombre de bléssés</label>
            <Form.Input placeholder="Nombre de bléssés" />
          </Form.Field>
          <Form.Field>
            <label>Nombre de morts</label>
            <Form.Input placeholder="Nombre de morts" />
          </Form.Field>
          <Form.Field>
            <label>Grande ampleur</label>
            <Form.Select
              placeholder="Grande ampleur"
              fluid
              search
              selection
              options={[]}
            />
          </Form.Field>
        </Form.Group>

        <Header dividing>Informations sur le véhicule</Header>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Marque</label>
            <Form.Input />
          </Form.Field>
          <Form.Field>
            <label>Modèle</label>
            <Form.Input />
          </Form.Field>
          <Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Date debut visite technique</label>
                <Form.Input type="date" />
              </Form.Field>
              <Form.Field>
                <label>Date fin visite technique</label>
                <Form.Input type="date" />
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form.Group>
        <Header dividing>Informations sur le conducteur</Header>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Nom</label>
            <Form.Input />
          </Form.Field>
          <Form.Field>
            <label>Sexe</label>
            <Form.Select />
          </Form.Field>
          <Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Date naissance</label>
                <Form.Input type="date" />
              </Form.Field>
              <Form.Field>
                <label>Situation matrimoniale</label>
                <Form.Input type="date" />
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Qaulité</label>
            <Form.Select />
          </Form.Field>
          <Form.Field>
            <label>N° Permis</label>
            <Form.Select />
          </Form.Field>
          <Form.Field>
            <label>Date de permis</label>
            <Form.Input type="date" />
          </Form.Field>
        </Form.Group>
      </GridRow>

      <GridRow>
        <Grid>
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16}>
            <PermissionWrapper
              requiredPermissions={[
                "company.clams.disaster-reporting.save",
              ]}
            >
              <Button color="green" floated="right">
                Enregistrer
              </Button>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                "company.clams.disaster-reporting.cancel",
              ]}
            >
              <Button color="red" floated="right">
                Annuler
              </Button>
            </PermissionWrapper>
          </Grid.Column>
        </Grid>
      </GridRow>
    </Tab.Pane>
  );
}

export default PaneInfos;
