import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as agencyRequestService from "../../../../services/gestion_attestations/agency/requestsService";
import AgencyRequestAttestationFormModal from "../../../../components/modalForms/gestion_attestations/AgencyRequestAttestationFormModal";
import PermissionWrapper from "../../../../components/PermissionWrapper";

function RequestsList(s) {
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [pagination, setPagination] = useState({});

  const [selectedPage, setSelectedPage] = useState(1);

  const fetchCommandes = async () => {
    try {
      const { data } = await agencyRequestService.fetchAll({
        page: selectedPage,
      });
      setRequests(data.requests || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des requests"
      );
    }
  };

  const handleUpdateClick = (request) => {
    setselectedRequestId(request.uuid);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setselectedRequestId(null);
    setOpen(false);
  };

  const handleDeleteClick = (request) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette demande ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        agencyRequestService
          .deleteOne(request.uuid)
          .then(() => {
            toast.success("Demande supprimée avec succes");
            fetchCommandes();
          })
          .catch((error) => {
            toast.error(
              "Une erreur est survenue lors de la suppression de la demande d'attestation"
            );
          });
      }
    });
  };

  useEffect(() => {
    fetchCommandes();
  }, [selectedPage]);

  return (
    <Page title="Liste des demandes d'attestations">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Agence</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/agency/stocks/demandes">
                Demandes d'attestions
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des demandes d'attestations [{pagination.total || 0}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <PermissionWrapper
              requiredPermissions={["agency.production.attestations.list"]}
            >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={["agency.production.attestations.add"]}
            >
            <Button
              className="!ml-4"
              onClick={() => {
                setselectedRequestId(null);
                setOpen(true);
              }}
            >
              <Icon name="add" /> Ajouter
            </Button>
            </PermissionWrapper>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Qté demandée</Table.HeaderCell>
                  <Table.HeaderCell>Qté reçue</Table.HeaderCell>
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell>Etat</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {requests.map((request, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {request?.quantity &&
                        new Intl.NumberFormat("fr-FR").format(request.quantity)}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_distributions?.start_serial &&
                        request?.attestations_distributions?.end_serial &&
                        new Intl.NumberFormat().format(
                          parseInt(
                            request.attestations_distributions.end_serial
                          ) -
                            parseInt(
                              request.attestations_distributions.start_serial
                            ) +
                            1
                        )}
                    </Table.Cell>

                    <Table.Cell>
                      {dayjs(request.created_at).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_distributions
                        ? "Livrée"
                        : "En attente"}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <PermissionWrapper
                            requiredPermissions={["agency.production.attestations.details"]}
                          >
                          <Dropdown.Item
                            as={Link}
                            icon={<Icon name="eye" />}
                            content="Détails"
                            to={`/agency/stocks/demandes/details/${request.uuid}`}
                          />
                          </PermissionWrapper>
                          <PermissionWrapper
                            requiredPermissions={["agency.production.attestations.edit"]}
                          >
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                            onClick={() => handleUpdateClick(request)}
                            disabled={request?.attestations_distributions}
                          />
                          </PermissionWrapper>
                          <PermissionWrapper
                            requiredPermissions={["agency.production.attestations.delete"]}
                          >
                          <Dropdown.Item
                            icon={<Icon name="trash" />}
                            content="Supprimer"
                            onClick={() => handleDeleteClick(request)}
                            disabled={request?.attestations_distributions}
                          />
                          </PermissionWrapper>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {requests.length > 0 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="8">
                      <Menu floated="right" pagination>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.prevPage)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">
                          {pagination?.currentPage} sur {pagination?.totalPages}
                        </Menu.Item>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.nextPage)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>

      <AgencyRequestAttestationFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchCommandes();
          setselectedRequestId(null);
        }}
        selectedRequestId={selectedRequestId}
        handleCloseModal={handleCloseModal}
      />
    </Page>
  );
}

export default RequestsList;
