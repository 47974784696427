import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";
import PermissionWrapper from "../PermissionWrapper";

function PoolMenus({
  activeIndex,
  activeSubMenu,
  handleItemClick,
  handleSubMenuClick,
}) {
  return (
    <Accordion
      as={Menu}
      fluid
      vertical
      style={{ borderRadius: "0px", border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="pool/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Tableau de bord
        </span>
      </Menu.Item>
      <PermissionWrapper requiredPermissions={["pool.production.view"]}>
        <Menu.Item>
          <Accordion.Title
            active={activeIndex === "production"}
            index={"production"}
            onClick={handleItemClick}
          >
            <Icon name="bus" /> Production
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "production"}>
            <Menu.Menu>
              <PermissionWrapper
                requiredPermissions={["pool.production.dashboard.view"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/production/dashboard"}
                  active={activeSubMenu === "pool/production/dashboard"}
                  onClick={handleSubMenuClick}
                  to="pool/production/dashboard"
                >
                  Tableau de bord
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.production.subscriptor.list"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/production/souscripteurs"}
                  active={activeSubMenu === "pool/production/souscripteurs"}
                  onClick={handleSubMenuClick}
                  to="pool/production/souscripteurs"
                >
                  Souscripteurs
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.production.polices.list"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/production/polices"}
                  active={activeSubMenu === "pool/production/polices"}
                  onClick={handleSubMenuClick}
                  to="pool/production/polices"
                >
                  Polices
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.production.clams.declaration"]}
              >
                <Menu.Item as="a">Déclaration de sinistre</Menu.Item>
              </PermissionWrapper>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["pool.attestation.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="stocks"
            active={activeIndex === "stocks"}
            onClick={handleItemClick}
          >
            <Icon name="box" /> Attestations
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "stocks"}>
            <Menu.Menu>
              <Menu.Item
                as={Link}
                index={"pool/stocks/dashboard"}
                active={activeSubMenu === "pool/stocks/dashboard"}
                onClick={handleSubMenuClick}
                to="pool/stocks/dashboard"
              >
                Tableau de bord
              </Menu.Item>

              <PermissionWrapper
                requiredPermissions={["pool.attestation.orders.view"]}
              >
                <Menu.Item
                  as={Link}
                  to="pool/stocks"
                  active={activeSubMenu === "pool/stocks"}
                  index={"pool/stocks"}
                  onClick={handleSubMenuClick}
                >
                  Commandes d'attestations
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.attestation.requests.view"]}
              >
                <Menu.Item
                  as={Link}
                  to="pool/stocks/demandes"
                  index={"pool/stocks/demandes"}
                  active={activeSubMenu === "pool/stocks/demandes"}
                  onClick={handleSubMenuClick}
                >
                  Demandes d'attestations
                </Menu.Item>
              </PermissionWrapper>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.clams.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="gestion"
            onClick={handleItemClick}
            active={activeIndex === "gestion"}
          >
            <Icon name="car crash" /> Sinistres
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "gestion"}>
            <Menu.Menu>
              <Menu.Item as="a">Déclaration d'un sinistre</Menu.Item>
              <Menu.Item as="a">Estimation</Menu.Item>
              <Menu.Item as="a">Règlement</Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.reports.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="etats"
            active={activeIndex === "etats"}
            onClick={handleItemClick}
          >
            <Icon name="file alternate" /> Etats & Rapports
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "etats"}>
            <Menu.Menu>
              <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
              <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
              <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
              <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper
        requiredPermissions={["pool.disposals-retrocessions.view"]}
      >
        <Menu.Item>
          <Accordion.Title
            index="cession"
            active={activeIndex === "cession"}
            onClick={handleItemClick}
          >
            <Icon name="expand alternate" /> Cessions & Rétrocessions
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "cession"}>
            <Menu.Menu>
              <Menu.Item as="a">Cessions</Menu.Item>
              <Menu.Item as="a">Rétrocessions</Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.pricing-parameters.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="parametres-tarification"
            onClick={handleItemClick}
            active={activeIndex === "parametres-tarification"}
          >
            <Icon name="cogs" /> Paramètres de tarification
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "parametres-tarification"}>
            <Menu.Menu>
              <Menu.Item
                as={Link}
                to="pool/production/parametres-tarification/directives"
                active={
                  activeSubMenu ===
                  "pool/production/parametres-tarification/directives"
                }
                onClick={handleSubMenuClick}
                index={"pool/production/parametres-tarification/directives"}
              >
                Directives de tarification
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/production/parametres-tarification/durees-assurances"
                active={
                  activeSubMenu ===
                  "pool/production/parametres-tarification/durees-assurances"
                }
                onClick={handleSubMenuClick}
                index={
                  "pool/production/parametres-tarification/durees-assurances"
                }
              >
                Durées d'assurance
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/production/parametres-tarification/liste-garanties"
                active={
                  activeSubMenu ===
                  "pool/production/parametres-tarification/liste-garanties"
                }
                onClick={handleSubMenuClick}
                index={
                  "pool/production/parametres-tarification/liste-garanties"
                }
              >
                Liste des garanties
              </Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.logs.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="journalisation"
            onClick={handleItemClick}
            active={activeIndex === "journalisation"}
          >
            <Icon name="history" /> Journalisation
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "journalisation"}>
            <Menu.Menu>
              <Menu.Item
                as={Link}
                to="pool/journalisation/auth-logs"
                index={"pool/journalisation/auth-logs"}
                active={activeSubMenu === "pool/journalisation/auth-logs"}
                onClick={handleSubMenuClick}
              >
                Journal d'authentification
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/journalisation/activity-logs"
                index={"pool/journalisation/activity-logs"}
                active={activeSubMenu === "pool/journalisation/activity-logs"}
                onClick={handleSubMenuClick}
              >
                Journal des activités
              </Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.config-permissions.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="configuration-permissions"
            onClick={handleItemClick}
            active={activeIndex === "configuration-permissions"}
          >
            <Icon name="user secret" /> Config. des permissions
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === "configuration-permissions"}
          >
            <Menu.Menu>
              <Menu.Item
                as={Link}
                to="pool/configuration-permissions/groupes-permissions"
                active={
                  activeSubMenu ===
                  "pool/configuration-permissions/groupes-permissions"
                }
                onClick={handleSubMenuClick}
                index={"pool/configuration-permissions/groupes-permissions"}
              >
                Groupes de permissions
              </Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={["pool.dictionnary.view"]}>
        <Menu.Item>
          <Accordion.Title
            index="dictionnaire"
            onClick={handleItemClick}
            active={activeIndex === "dictionnaire"}
          >
            <Icon name="book" /> Dictionnaire
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "dictionnaire"}>
            <Menu.Menu>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/categories-contrats"
                index={"pool/dictionnaire/categories-contrats"}
                active={
                  activeSubMenu === "pool/dictionnaire/categories-contrats"
                }
                onClick={handleSubMenuClick}
              >
                Catégories de contrats
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/sources-energies"
                index={"pool/dictionnaire/sources-energies"}
                active={activeSubMenu === "pool/dictionnaire/sources-energies"}
                onClick={handleSubMenuClick}
              >
                Sources d'énergie
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/zones-circulation"
                index={"pool/dictionnaire/zones-circulation"}
                active={activeSubMenu === "pool/dictionnaire/zones-circulation"}
                onClick={handleSubMenuClick}
              >
                Zones de circulation
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/civilites"
                index={"pool/dictionnaire/civilites"}
                active={activeSubMenu === "pool/dictionnaire/civilites"}
                onClick={handleSubMenuClick}
              >
                Civilités
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/professions"
                index={"pool/dictionnaire/professions"}
                active={activeSubMenu === "pool/dictionnaire/professions"}
                onClick={handleSubMenuClick}
              >
                Professions
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/pays"
                index={"pool/dictionnaire/pays"}
                active={activeSubMenu === "pool/dictionnaire/pays"}
                onClick={handleSubMenuClick}
              >
                pays
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/car-brands"
                index={"pool/dictionnaire/car-brands"}
                active={activeSubMenu === "pool/dictionnaire/car-brands"}
                onClick={handleSubMenuClick}
              >
                Marques de voiture
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="pool/dictionnaire/pieces-officielles"
                index={"pool/dictionnaire/pieces-officielles"}
                active={
                  activeSubMenu === "pool/dictionnaire/pieces-officielles"
                }
                onClick={handleSubMenuClick}
              >
                Pièces officielles
              </Menu.Item>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>
      <PermissionWrapper requiredPermissions={["pool.administration.view"]}>
        <Menu.Item>
          <Accordion.Title
            active={activeIndex === "administration"}
            onClick={handleItemClick}
            index="administration"
          >
            <Icon name="briefcase" /> Administration
            <i className="right dropdown icon"></i>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === "administration"}>
            <Menu.Menu>
              <PermissionWrapper
                requiredPermissions={["pool.administration.company.view"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/administration/compagnies"}
                  active={activeSubMenu === "pool/administration/compagnies"}
                  onClick={handleSubMenuClick}
                  to="pool/administration/compagnies"
                >
                  Compagnies
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.administration.intermediaire.view"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/administration/intermediaires"}
                  active={
                    activeSubMenu === "pool/administration/intermediaires"
                  }
                  onClick={handleSubMenuClick}
                  to="pool/administration/intermediaires"
                >
                  Intermédiaires
                </Menu.Item>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={["pool.administration.users.view"]}
              >
                <Menu.Item
                  as={Link}
                  index={"pool/administration/users"}
                  active={activeSubMenu === "pool/administration/users"}
                  onClick={handleSubMenuClick}
                  to="pool/administration/users"
                >
                  Utilisateurs
                </Menu.Item>
              </PermissionWrapper>
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      </PermissionWrapper>
    </Accordion>
  );
}

export default PoolMenus;
