import React, { Fragment, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import dayjs from "dayjs";
import ChangementImmatriculationModal from "../modalForms/ActesDeGestion/ChangementImmatriculationModal";
import ChangementDeNomModal from "../modalForms/ActesDeGestion/ChangementDeNomModal";
import ImpressionModal from "./ImpressionModal";
import GenerationPoliceModal from "./GenerationPoliceModal";
import RetraitsDeGarantiesModal from "../modalForms/ActesDeGestion/RetraitsDeGarantiesModal";

import ExtensionDeGarantiesModal from "../modalForms/ActesDeGestion/ExtensionDeGarantiesModal";
import { deleteAvenant } from "../../services/gestion_production/avenantsService";
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import DuplicataAttestestationModal from "../modalForms/ActesDeGestion/DuplicataAttestestationModal";
import AvenantResialiationModal from "../modalForms/ActesDeGestion/AvenantResialiationModal";
import AvenantSuspensionModal from "../modalForms/ActesDeGestion/AvenantSuspensionModal";
import AvenantRemiseEnVigueurModal from "../modalForms/ActesDeGestion/AvenantRemiseEnVigueurModal";
import AvenantChangementPriseEffet from "../modalForms/ActesDeGestion/AvenantChangementPriseEffet";
import PermissionWrapper from "../PermissionWrapper";
import AccessDeniedPage from "../AccessDeniedPage";
import {
  getAvenantTypeReadableText,
  getRemiseEnVigueurReadableText,
} from "../../utils/commons";
import AvenantChangementVehicule from "../modalForms/ActesDeGestion/AvenantChangementVehicule";
import { AVENANTS_NECESSITANT_EDITION_ATTESTATION } from "../../constants";
import EditAttestionNumberModal from "../modalForms/EditAttestionNumberModal";
import { canActivatePriseEffet } from "../../utils/souscription_helper";

function ActesDeGestionPane({
  souscription = null,
  avenants = [],
  idpolices = null,
  onSuccessPaymentCallBack = () => {},
  onSuccessCallBack = () => {},
  openConditionParticuliereModal = () => {},
}) {
  const [openGenerationPoliceModal, setOpenGenerationPoliceModal] =
    useState(false);
  const [openExtensionDeGarantiesModal, setOpenExtensionDeGarantiesModal] =
    useState(false);
  const [
    openChangementImmatriculationModal,
    setOpenChangementImmatriculationModal,
  ] = useState(false);
  const [
    openDuplicataAttestestationModal,
    setOpenDuplicataAttestestationModal,
  ] = useState(false);
  const [openChangementDeNomModal, setOpenChangementDeNomModal] =
    useState(false);
  const [selectedAvenantUuid, setSelectedAvenantUuid] = useState();
  const [openAvenantResialiationModal, setOpenAvenantResialiationModal] =
    useState(false);
  const [openAvenantSuspensionModal, setOpenAvenantSuspensionModal] =
    useState(false);
  const [openAvenantRemiseEnVigueurModal, setOpenAvenantRemiseEnVigueurModal] =
    useState(false);
  const [showModifPriseEffetModal, setShowModifPriseEffetModal] =
    useState(false);
  const [
    openAvenantChangementVehiculeModal,
    setOpenAvenantChangementVehiculeModal,
  ] = useState(false);
  const [openEditionAttestationModal, setOpenEditionAttestationModal] =
    useState(false);

  const authValue = useRecoilValue(authState);
  const navigate = useNavigate();

  const [openImpressionModal, setOpenImpressionModal] = useState(false);
  const [openRetraitModal, setOpenRetraitModal] = useState(false);

  const handleDeleteAvenant = async (uuid) => {
    try {
      Swal.fire({
        title: "Suppression de l'avenant",
        text: "Voulez-vous vraiment supprimer cet avenant ? Cette action est irréversible",
        icon: "warning",
        confirmButtonText: "Oui",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAvenant(uuid);
            toast.success("Avenant supprimé avec succès");
            onSuccessCallBack();
          } catch (error) {
            toast.error("Impossible de supprimer l'avenant");
          }
        }
      });
    } catch (error) {
      toast.error("Impossible de supprimer l'avenant");
    }
  };

  const isAffaireNouvellePaid = () => {
    const affaireNouvelle = avenants?.find(
      (avenant) => avenant.type_avenant === "AFFAIRE_NOUVELLE"
    );
    return affaireNouvelle?.bills?.payments;
  };

  const canCreateAvenant = () => {
    return (
      isAffaireNouvellePaid() &&
      souscription?.souscription_polices?.numero_police
    );
  };

  return (
    <Fragment>
      <GridRow>
        <>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <PermissionWrapper
                requiredPermissions={[
                  "pool.production.polices.management-act.list",
                  "company.production.polices.management-act.list",
                  "agency.production.polices.management-act.list",
                  "agent_general.production.polices.management-act.list",
                ]}
              >
                <Header>Liste des actes</Header>
              </PermissionWrapper>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <PermissionWrapper
                requiredPermissions={[
                  "pool.production.polices.management-act.list",
                  "company.production.polices.management-act.list",
                  "agency.production.polices.management-act.list",
                  "agent_general.production.polices.management-act.list",
                ]}
              >
                <Input placeholder="Rechercher..." className="search">
                  <input name="search" />
                </Input>
              </PermissionWrapper>
              <PermissionWrapper
                requiredPermissions={[
                  "company.production.polices.management-act.add",
                  "agency.production.polices.management-act.add",
                ]}
              >
                <Dropdown
                  pointing="right"
                  style={{ zIndex: 999 }}
                  icon={null}
                  trigger={
                    <Button
                      style={{ marginLeft: "10px" }}
                      circular
                      icon={<Icon name="add" />}
                    />
                  }
                >
                  <Dropdown.Menu>
                    {dayjs(
                      souscription?.conditions_particulieres?.date_echeance
                    ).diff(
                      dayjs(souscription?.conditions_particulieres?.date_effet),
                      "months"
                    ) > 9 ? (
                      <>
                        <Dropdown.Item
                          content="Suspension"
                          onClick={() => setOpenAvenantSuspensionModal(true)}
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Modification de prise d'effet"
                          onClick={() => setShowModifPriseEffetModal(true)}
                          disabled={
                            !canCreateAvenant() ||
                            !canActivatePriseEffet(souscription)
                          }
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Remise en vigueur"
                          onClick={() =>
                            setOpenAvenantRemiseEnVigueurModal(true)
                          }
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Changement de véhicule"
                          onClick={() =>
                            setOpenAvenantChangementVehiculeModal(true)
                          }
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Retrait de garanties"
                          onClick={() => setOpenRetraitModal(true)}
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Duplicata d'attestation"
                          onClick={() =>
                            setOpenDuplicataAttestestationModal(true)
                          }
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                        <Dropdown.Item
                          content="Extension des garanties"
                          onClick={() => setOpenExtensionDeGarantiesModal(true)}
                          disabled={!canCreateAvenant()}
                          icon={
                            !canCreateAvenant() ? <Icon name="lock" /> : null
                          }
                        />
                      </>
                    ) : null}
                    <Dropdown.Item
                      content="Avenant de changement de nom"
                      onClick={() => setOpenChangementDeNomModal(true)}
                      disabled={!canCreateAvenant()}
                      icon={!canCreateAvenant() ? <Icon name="lock" /> : null}
                    />
                    <Dropdown.Item
                      content="Changement d'immatriculation"
                      onClick={() =>
                        setOpenChangementImmatriculationModal(true)
                      }
                      disabled={!canCreateAvenant()}
                      icon={!canCreateAvenant() ? <Icon name="lock" /> : null}
                    />
                    <Dropdown.Item
                      content="Résiliation"
                      onClick={() => setOpenAvenantResialiationModal(true)}
                      disabled={!canCreateAvenant()}
                      icon={!canCreateAvenant() ? <Icon name="lock" /> : null}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </PermissionWrapper>
            </Grid.Column>
          </Grid>
          <PermissionWrapper
            requiredPermissions={[
              "pool.production.polices.management-act.list",
              "company.production.polices.management-act.list",
              "agency.production.polices.management-act.list",
              "agent_general.production.polices.management-act.list",
            ]}
            fallback={<AccessDeniedPage resourceName="La liste des actes" />}
          >
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Numéro</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Période</Table.HeaderCell>
                  <Table.HeaderCell>Création</Table.HeaderCell>
                  <Table.HeaderCell>Paiement</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {avenants?.map((avenant, index) => {
                  return (
                    <Table.Row key={`${avenant.idavenants}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {(avenants?.length - 1 - index)
                          ?.toString()
                          ?.padStart(6, "0")}
                      </Table.Cell>
                      <Table.Cell>
                        {getAvenantTypeReadableText(avenant.type_avenant)}
                        {`${
                          avenant.type_avenant === "REMISE_EN_VIGUEUR"
                            ? " - "
                            : ""
                        } ${getRemiseEnVigueurReadableText(avenant)}`}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(
                          souscription?.conditions_particulieres?.date_effet
                        ).format("DD/MM/YYYY")}
                        {" - "}
                        {dayjs(
                          souscription?.conditions_particulieres?.date_echeance
                        )
                          .add(1, "minute")
                          .subtract(1, "day")
                          .format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(avenant.created_at).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {avenant?.bills?.payments ? (
                          <>
                            <Icon name="check" color="green" />
                            <span style={{ marginLeft: "5px" }}>
                              {dayjs(
                                avenant?.bills?.payments?.created_at
                              ).format("DD/MM/YYYY HH:mm")}
                            </span>
                          </>
                        ) : (
                          <Icon name="close" color="red" />
                        )}
                      </Table.Cell>

                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            {/*                             <PermissionWrapper
                              requiredPermissions={[
                                "pool.production.polices.management-act.details",
                                "company.production.polices.management-act.details",
                                "agency.production.polices.management-act.details",
                                "agent_general.production.polices.management-act.details",
                              ]}
                            >
                              <Dropdown.Item
                                icon={<Icon name="eye" />}
                                content="Détails"
                                onClick={() =>
                                  navigate(`details/${avenant?.idavenants}`)
                                }
                              />
                            </PermissionWrapper> */}
                            {AVENANTS_NECESSITANT_EDITION_ATTESTATION.includes(
                              avenant.type_avenant
                            ) &&
                              !avenant.numero_attestation &&
                              (avenant.type_avenant !== "REMISE_EN_VIGUEUR" ||
                                avenant.remise_en_vigueur_type ===
                                  "report_date") && (
                                <PermissionWrapper
                                  requiredPermissions={[
                                    "pool.production.polices.management-act.details",
                                    "company.production.polices.management-act.details",
                                    "agency.production.polices.management-act.details",
                                    "agent_general.production.polices.management-act.details",
                                  ]}
                                >
                                  <Dropdown.Item
                                    icon={<Icon name="barcode" />}
                                    content="Edition d'attestation"
                                    disabled={!avenant?.bills?.payments}
                                    onClick={() => {
                                      setSelectedAvenantUuid(avenant.uuid);
                                      setOpenEditionAttestationModal(true);
                                    }}
                                  />
                                </PermissionWrapper>
                              )}

                            {souscription?.avenants?.length === 1 &&
                              avenant.type_avenant === "AFFAIRE_NOUVELLE" &&
                              !avenant?.bills?.payments && (
                                <Dropdown.Item
                                  icon={<Icon name="edit" />}
                                  content="Modifier"
                                  as={Link}
                                  to={`/agency/production/souscriptions/mono/edit/${souscription?.uuid}`}
                                />
                              )}

                            {avenant.type_avenant !== "AFFAIRE_NOUVELLE" &&
                              !avenant?.bills?.payments && (
                                <>
                                  <PermissionWrapper
                                    requiredPermissions={[
                                      "company.production.polices.management-act.delete",
                                      "agency.production.polices.management-act.delete",
                                    ]}
                                  >
                                    <Dropdown.Item
                                      icon={<Icon name="trash" />}
                                      content="Supprimer"
                                      onClick={() =>
                                        handleDeleteAvenant(avenant.uuid)
                                      }
                                    />
                                  </PermissionWrapper>
                                </>
                              )}
                            {!souscription?.souscription_polices
                              ?.numero_police &&
                              avenant.type_avenant === "AFFAIRE_NOUVELLE" && (
                                <PermissionWrapper
                                  requiredPermissions={[
                                    "company.production.polices.management-act.generate",
                                    "agency.production.polices.management-act.generate",
                                  ]}
                                >
                                  <Dropdown.Item
                                    icon={<Icon name="plus" />}
                                    content="Générer la police"
                                    disabled={!avenant?.bills?.payments}
                                    onClick={() => {
                                      setSelectedAvenantUuid(avenant.uuid);
                                      setOpenGenerationPoliceModal(true);
                                    }}
                                  />
                                </PermissionWrapper>
                              )}
                            <PermissionWrapper
                              requiredPermissions={[
                                "pool.production.polices.management-act.print",
                                "company.production.polices.management-act.print",
                                "agency.production.polices.management-act.print",
                                "agent_general.production.polices.management-act.print",
                              ]}
                            >
                              <Dropdown.Item
                                icon={<Icon name="print" />}
                                content="Imprimer"
                                onClick={() => {
                                  if (
                                    avenant.type_avenant !== "AFFAIRE_NOUVELLE"
                                  ) {
                                    setSelectedAvenantUuid(avenant.uuid);
                                    setOpenImpressionModal(true);
                                  } else {
                                    openConditionParticuliereModal();
                                  }
                                }}
                              />
                            </PermissionWrapper>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </PermissionWrapper>
        </>
      </GridRow>
      <ExtensionDeGarantiesModal
        open={openExtensionDeGarantiesModal}
        setOpen={setOpenExtensionDeGarantiesModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <GenerationPoliceModal
        open={openGenerationPoliceModal}
        setOpen={setOpenGenerationPoliceModal}
        souscription={souscription}
        avenant_uuid={selectedAvenantUuid}
        onSuccessPaymentCallBack={onSuccessPaymentCallBack}
        onCloseCallBack={() => {
          setSelectedAvenantUuid(null);
        }}
      />
      <ImpressionModal
        open={openImpressionModal}
        setOpen={setOpenImpressionModal}
        souscription={souscription}
        avenant_uuid={selectedAvenantUuid}
        onCloseCallBack={() => {
          setSelectedAvenantUuid(null);
        }}
      />

      {dayjs(souscription?.conditions_particulieres?.date_echeance).diff(
        dayjs(souscription?.conditions_particulieres?.date_effet),
        "months"
      ) > 9 ? (
        <>
          <RetraitsDeGarantiesModal
            open={openRetraitModal}
            setOpen={setOpenRetraitModal}
            souscription_uuid={souscription?.uuid}
            onSuccessCallBack={onSuccessCallBack}
          />
          <DuplicataAttestestationModal
            open={openDuplicataAttestestationModal}
            setOpen={setOpenDuplicataAttestestationModal}
            souscription_uuid={souscription?.uuid}
            onSuccessCallBack={onSuccessCallBack}
          />

          <AvenantSuspensionModal
            open={openAvenantSuspensionModal}
            setOpen={setOpenAvenantSuspensionModal}
            souscription_uuid={souscription?.uuid}
            onSuccessCallBack={onSuccessCallBack}
          />
          <AvenantRemiseEnVigueurModal
            open={openAvenantRemiseEnVigueurModal}
            setOpen={setOpenAvenantRemiseEnVigueurModal}
            souscription_uuid={souscription?.uuid}
            onSuccessCallBack={onSuccessCallBack}
          />
          <AvenantChangementPriseEffet
            open={showModifPriseEffetModal}
            setOpen={setShowModifPriseEffetModal}
            souscription_uuid={souscription?.uuid}
            onSuccessCallBack={onSuccessCallBack}
          />
        </>
      ) : null}

      <AvenantResialiationModal
        open={openAvenantResialiationModal}
        setOpen={setOpenAvenantResialiationModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <ChangementImmatriculationModal
        open={openChangementImmatriculationModal}
        setOpen={setOpenChangementImmatriculationModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <ChangementDeNomModal
        open={openChangementDeNomModal}
        setOpen={setOpenChangementDeNomModal}
        uuid={souscription?.uuid}
        avenants={avenants}
        onSuccessCallBack={onSuccessCallBack}
      />
      <AvenantChangementVehicule
        open={openAvenantChangementVehiculeModal}
        setOpen={setOpenAvenantChangementVehiculeModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <EditAttestionNumberModal
        open={openEditionAttestationModal}
        setOpen={setOpenEditionAttestationModal}
        onSuccessCallBack={onSuccessCallBack}
        selectedAvenantUuid={selectedAvenantUuid}
      />
    </Fragment>
  );
}

export default ActesDeGestionPane;
