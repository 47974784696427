import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as avenantService from "../../services/gestion_production/avenantsService";

const validationSchema = yup.object().shape({
  numero_attestation: yup
    .string()
    .required("Le numéro d'attestation est requis")
    .max(16, "Le numéro d'attestation doit contenir 16 caractères")
    .min(5, "Le numéro d'attestation doit contenir 5 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function EditAttestionNumberModal({
  callback = async () => {},
  open = false,
  setOpen,
  selectedAvenantUuid,
  handleClose = () => {},
  onSuccessCallBack = async () => {},
}) {
  const fetchAvenant = useCallback(async () => {
    if (!selectedAvenantUuid) {
      return;
    }

    try {
      const response = await avenantService.getOneAvenant(selectedAvenantUuid);
      const avenantData = response.data.avenant || response.data;

      if (avenantData) {
        formik.setValues({
          numero_attestation: avenantData.numero_attestation || "",
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de l'avenant: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedAvenantUuid]);

  const formik = useFormik({
    initialValues: {
      numero_attestation: "",
    },
    validationSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const serviceCall = avenantService.setNumeroAttestation(
          selectedAvenantUuid,
          { numero_attestation: values.numero_attestation }
        );

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          toast.success(
            "Numéro d'attestation modifié avec succès",
            TOAST_CONFIG
          );
          await onSuccessCallBack();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedAvenantUuid) {
      fetchAvenant();
    } else {
      formik.resetForm();
    }
  }, [selectedAvenantUuid, fetchAvenant]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
    >
      <Modal.Header>Edition du numéro d'attestation</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.numero_attestation}>
            <label>Numéro d'attestation</label>
            <Input
              placeholder="Entrez le numéro d'attestation"
              value={formik.values.numero_attestation}
              onChange={(e, { value }) => {
                formik.setFieldValue("numero_attestation", value);
              }}
              onBlur={formik.handleBlur("numero_attestation")}
            />

            {formik.touched.numero_attestation &&
              formik.errors.numero_attestation && (
                <small className="field-error" style={{ color: "red" }}>
                  {formik.errors.numero_attestation}
                </small>
              )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          type="button"
          content={selectedAvenantUuid ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            formik.handleSubmit();
          }}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditAttestionNumberModal;
