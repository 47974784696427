import { View, Text } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

function PreviewContentWrapper({ children, souscription }) {
  const paddingTop = Number(souscription?.paramsImpression?.top) + 10;
  const marginBottom = Number(souscription?.paramsImpression?.bottom) + 50;
  const PAGE_HEIGHT = 842;

  const availableHeight = PAGE_HEIGHT - paddingTop - marginBottom;
  return (
    <View
      style={{
        paddingBottom: marginBottom,
        paddingLeft: souscription?.paramsImpression?.left,
        paddingRight: souscription?.paramsImpression?.right,
        flexGrow: 1,
        minHeight: availableHeight,
      }}
    >
      {children}      
    </View>
  );
}

PreviewContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  souscription: PropTypes.object.isRequired,
};

export default PreviewContentWrapper;
