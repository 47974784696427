import { api } from "../axios/api";

export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`compagnies`, { params: { page, search } });
};

export const fetchOne = async (id) => {
  return api.get(`compagnies/${id}`);
};

export const fetchOneUser = async (id) => {
  return api.get(`compagnies/compagnyuser/${id}`);
};

export const fetchOwner = async () => {
  return api.get(`compagnies/details/owner`);
};

export const fetchDetails = async (id) => {
  return api.get(`compagnies/details/${id}`);
};

export const create = async (data) => {
  return api.post(`compagnies`, data);
};

export const update = async (uuid, data) => {
  return api.put(`compagnies/${uuid}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addUser = async (data) => {
  return api.post(`compagnies/user/add`, data);
};

export const createNewUser = async (data) => {
  return api.post(`compagnies/user/create-new`, data);
};

export const fetchUserLogs = async (id, { page = 1, limit = 10 }) => {
  return api.get(`compagnies/${id}/logs`, {
    params: {
      page,
      limit,
    },
  });
};
