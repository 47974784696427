import React, { useState } from "react";
import { Grid, GridRow, Header, Input } from "semantic-ui-react";
import Page from "../../../components/Page";
import PolicesTable from "../../../components/production/PolicesList";
import numeral from "numeral";

function PolicesList() {
  const [totalItems, setTotalItems] = useState(0);
  return (
    <Page title="Liste des polices">
      <GridRow className="Souscriptions">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des polices [{numeral(totalItems).format("")}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <PolicesTable setTotalItems={setTotalItems} />
      </GridRow>
    </Page>
  );
}

export default PolicesList;
