import React, { useEffect, useState } from "react";
import {
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  List,
  Segment,
  Table,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  Pagination,
  Input,
} from "semantic-ui-react";
import numeral from "numeral";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import Page from "../../../../../components/Page";
import * as magasinService from "../../../../../services/gestion_attestations/company/magasinsService";
import PermissionWrapper from "../../../../../components/PermissionWrapper";

function MagasinStocks() {
  const { uuid } = useParams();
  const [magasin, setMagasin] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    nextPage: 1,
    previousPage: 1,
  });

  const fetchMagasin = async () => {
    try {
      const response = await magasinService.fetchOne(uuid);
      setMagasin(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMagasinStocks = async ({
    page = 1,
    limit = 10,
    search = "",
  } = {}) => {
    try {
      const response = await magasinService.fetchStocks(uuid, {
        page,
        limit,
        search,
      });
      setStocks(response.data.stocks);
      setPagination({
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        nextPage: response.data.nextPage,
        previousPage: response.data.previousPage,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (uuid) {
      fetchMagasin();
      fetchMagasinStocks();
    }
  }, [uuid]);
  return (
    <Page title="Stocks du magasin">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection as={Link} to="/company/stocks/magasins">
                Magasins
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Stocks</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>

        <GridRow as={Segment}>
          <Label ribbon>Détails du magasin</Label>
          <Grid stackable columns={4} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Nom</List.Header>
                    <List.Description>{magasin?.name}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Lieu</List.Header>
                    <List.Description>{magasin?.location}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Téléphone</List.Header>
                    <List.Description>{magasin?.phone}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Type</List.Header>
                    <List.Description>{magasin?.type_magasin}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <div className="flex justify-between items-center mb-3">
            <Header as="h3" className="!mb-0">
              Stocks
            </Header>
            <Input
              icon="search"
              placeholder="Rechercher..."
              className="search"
              onChange={(e) => {
                fetchMagasinStocks({ search: e.target.value });
              }}
            />
          </div>
          <Divider />
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Quantité livrée</Table.HeaderCell>
                <Table.HeaderCell>Plage</Table.HeaderCell>
                <Table.HeaderCell>Qté disponible</Table.HeaderCell>
                <Table.HeaderCell>Date de livraison</Table.HeaderCell>
                <Table.HeaderCell collapsing>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stocks?.map((stock) => (
                <Table.Row>
                  <Table.Cell>
                    {stock.parent_stock?.attestations_magasins.name ||
                      "Pool TPV"}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(stock.usage?.totalQty).format("0,0")}
                  </Table.Cell>
                  <Table.Cell>
                    {`${stock.start_serial} - ${stock.end_serial}`}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(stock.usage?.remainingQty).format("0,0")}
                  </Table.Cell>

                  <Table.Cell>
                    {dayjs(stock.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                      <PermissionWrapper
                        requiredPermissions={[
                          "company.attestations.stock.store.details",
                        ]}
                      >
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          as={Link}
                          to={`/company/stocks/${
                            stock?.parent_stock === null
                              ? `commandes/delivery/${stock.attestations_deliveries?.uuid}`
                              : `magasins/stocks/stocks-magasins/${stock.uuid}`
                          }`}
                        />
                      </PermissionWrapper>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              {pagination.totalPages > 1 && (
                <Table.Row>
                  <Table.Cell colSpan="6" textAlign="right">
                    <Pagination
                      totalPages={pagination.totalPages}
                      currentPage={pagination.currentPage}
                      nextPage={pagination.nextPage}
                      previousPage={pagination.previousPage}
                      onPageChange={(e, { activePage }) => {
                        fetchMagasinStocks({ page: activePage });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Footer>
          </Table>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default MagasinStocks;
