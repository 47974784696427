import { Container, Icon, Header } from "semantic-ui-react";
import Page from "./Page";

const AccessDeniedPage = ({ resourceName }) => {
  return (
    <Page
      title={resourceName ? `Accès non autorisé à ${resourceName}` : "Accès non autorisé"}
    >
      <div className="h-full flex flex-col items-center justify-center">
        <Container textAlign="center">
          <Icon name="ban" size="huge" color="red" />
          <Header as="h1" className="!mt-4">
            Accès non autorisé
          </Header>
          <p className="text-lg text-slate-500">
            {resourceName
              ? `Vous n'avez pas la permission d'accéder à ${resourceName}.`
              : "Vous n'avez pas la permission d'accéder à cette page."}
            <br />
            Si vous pensez que c'est une erreur, contactez l'administrateur.
          </p>
        </Container>
      </div>
    </Page>
  );
};

export default AccessDeniedPage;
