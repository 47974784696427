import { Page } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  LabelDetail,
  Modal,
  Table,
} from "semantic-ui-react";
import * as policesService from "../../services/gestion_production/policesService";
//import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import currency from "currency.js";
import PermissionWrapper from "../../components/PermissionWrapper";
import AccessDeniedPage from "../../components/AccessDeniedPage";

function BordereauProduction() {
  const [polices, setPolices] = useState([]);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const navigate = useNavigate();
  const fetchPolices = async (params = {}) => {
    try {
      const response = await policesService.findAll(params);
      if (response.status === 200 && Array.isArray(response.data)) {
        setPolices(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchPolices({
      startDate: dayjs(range[0].startDate).format("YYYY-MM-DD"),
      endDate: dayjs(range[0].endDate).format("YYYY-MM-DD"),
    });
    return () => {};
  }, [range]);
  return (
    <Page title="Bordereau de production">
      <GridRow className="BordereauProduction">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Production</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div className="ui inline form ">
              <div
                className="fields flex justify-center items-center mb-0"
                style={{ marginBottom: 0 }}
              >
                <PermissionWrapper
                  requiredPermissions={[
                    "company.reports.production-slips.list",
                  ]}
                >
                  <div className="field inline">
                    <label>Du</label>
                    <input
                      type="date"
                      name=""
                      value={dayjs(range[0].startDate).format("YYYY-MM-DD")}
                      readOnly
                    />
                  </div>

                  <div className="field inline">
                    <label>Au</label>
                    <input
                      type="date"
                      name=""
                      value={dayjs(range[0].endDate).format("YYYY-MM-DD")}
                      readOnly
                    />
                  </div>
                </PermissionWrapper>
                <PermissionWrapper
                  requiredPermissions={[
                    "company.reports.production-slips.filter",
                  ]}
                >
                  <div
                    className="field inline"
                    onClick={() => setOpenDateRangePicker(true)}
                  >
                    <button className="ui icon button">
                      <i className="filter icon"></i>
                    </button>
                  </div>
                </PermissionWrapper>
                <PermissionWrapper
                  requiredPermissions={[
                    "company.reports.production-slips.print",
                  ]}
                >
                  <div className="field inline">
                    <button className="ui icon button">
                      <i className="print icon"></i>
                    </button>
                  </div>
                </PermissionWrapper>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Divider />
        <>
          <PermissionWrapper
            requiredPermissions={["company.reports.production-slips.list"]}
            fallback={
              <AccessDeniedPage resourceName="Liste des bordereaux de production" />
            }
          >
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell> Nom Souscripteur</Table.HeaderCell>
                  <Table.HeaderCell>Police</Table.HeaderCell>
                  <Table.HeaderCell>Immatriculation</Table.HeaderCell>
                  <Table.HeaderCell>Durée</Table.HeaderCell>
                  <Table.HeaderCell>Date d'effet</Table.HeaderCell>
                  <Table.HeaderCell>Date d'échéance</Table.HeaderCell>
                  <Table.HeaderCell>Prime nette</Table.HeaderCell>
                  <Table.HeaderCell>Taxes</Table.HeaderCell>
                  <Table.HeaderCell>Cout de Police</Table.HeaderCell>
                  <Table.HeaderCell>Prime TTC</Table.HeaderCell>
                  <Table.HeaderCell>Commission</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {polices.map((police, index) => {
                  return (
                    <Table.Row key={`${police.idpolices}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {police.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.groupe_souscripteur === "PARTICULIER"
                          ? `${police.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name}
                     ${police.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}`
                          : police.souscriptions
                              ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                              ?.denomination}

                        <Label size="mini" style={{ marginLeft: 10 }}>
                          <LabelDetail>
                            {
                              police.souscriptions
                                ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                                ?.num
                            }
                          </LabelDetail>
                        </Label>
                      </Table.Cell>
                      <Table.Cell>{police.num_police || "N/A"}</Table.Cell>
                      <Table.Cell>
                        {police.avenants[0]?.vehicules?.immatriculation}
                      </Table.Cell>
                      <Table.Cell>
                        {-dayjs(police?.avenants[0]?.date_effet).diff(
                          dayjs(police?.avenants[0]?.date_effet).add(
                            police?.avenants[0]?.renouvellement?.substr(-2),
                            "months"
                          ),
                          "month"
                        ) + " mois"}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(police?.avenants[0]?.date_effet).format(
                          "DD/MM/YYYY"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(police?.avenants[0]?.date_effet)
                          .add(
                            Number(
                              police?.avenants[0]?.renouvellement.slice(-2)
                            ),
                            "months"
                          )
                          .add(-1, "day")
                          .format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          police?.avenants[police?.avenants?.length - 1]
                            ?.facturation?.prime_nette
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {
                          police?.avenants[police?.avenants?.length - 1]
                            ?.facturation?.taxes
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {
                          police?.avenants[police?.avenants?.length - 1]
                            ?.facturation?.cout_police
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {
                          police?.avenants[police?.avenants?.length - 1]
                            ?.facturation?.prime_nette_totale
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {police?.avenants[police?.avenants?.length - 1]
                          ?.facturation?.prime_nette_totale * 0.18}
                      </Table.Cell>

                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <PermissionWrapper
                              requiredPermissions={[
                                "company.reports.production-slips.details",
                              ]}
                            >
                              <Dropdown.Item
                                icon={<Icon name="eye" />}
                                content="Détails"
                                onClick={() =>
                                  navigate(
                                    `/company/polices/details/${police?.idpolices}`
                                  )
                                }
                              />
                            </PermissionWrapper>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan="13"
                    style={{ background: "#767676", marginTop: 10 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      <div>
                        <span>Nombre de police: {polices.length}</span>
                      </div>
                      <div>
                        <span>
                          Prime nette:{" "}
                          {currency(
                            polices.reduce(
                              (acc, police) =>
                                acc +
                                police?.avenants[police?.avenants?.length - 1]
                                  ?.facturation?.prime_nette_totale,
                              0
                            ),
                            { separator: " ", symbol: "" }
                          ).format()}
                        </span>
                      </div>
                      <div>
                        <span>
                          Taxes:{" "}
                          {currency(
                            polices.reduce(
                              (acc, police) =>
                                acc +
                                police?.avenants[police?.avenants?.length - 1]
                                  ?.facturation?.taxes,
                              0
                            ),
                            { separator: " ", symbol: "" }
                          ).format()}
                        </span>
                      </div>
                      <div>
                        <span>
                          Cout de police:{" "}
                          {currency(
                            polices.reduce(
                              (acc, police) =>
                                acc +
                                police?.avenants[police?.avenants?.length - 1]
                                  ?.facturation?.cout_police,
                              0
                            ),
                            { separator: " ", symbol: "" }
                          ).format()}
                        </span>
                      </div>
                      <div>
                        <span>
                          Prime TTC:{" "}
                          {currency(
                            polices.reduce(
                              (acc, police) =>
                                acc +
                                police?.avenants[police?.avenants?.length - 1]
                                  ?.facturation?.prime_nette_totale,
                              0
                            ),
                            { separator: " ", symbol: "" }
                          ).format()}
                        </span>
                      </div>
                      <div>
                        <span>
                          Commission:{" "}
                          {currency(
                            polices.reduce(
                              (acc, police) =>
                                acc +
                                police?.avenants[police?.avenants?.length - 1]
                                  ?.facturation?.prime_nette_totale *
                                  0.18,
                              0
                            ),
                            { separator: " ", symbol: "" }
                          ).format()}
                        </span>
                      </div>
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </PermissionWrapper>
        </>
      </GridRow>
      <Modal
        onClose={() => setOpenDateRangePicker(false)}
        open={openDateRangePicker}
        size="large"
      >
        <Modal.Header>Filtre de date</Modal.Header>
        <Modal.Content>
          {/*  <DateRangePicker
            onChange={(item) => {
              setRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
          /> */}
        </Modal.Content>
        <Modal.Actions>
          <button
            className="ui button"
            onClick={() => setOpenDateRangePicker(false)}
          >
            Fermer
          </button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
}

export default BordereauProduction;
