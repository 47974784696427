import React, { Fragment } from "react";
import { Chart as ChartJS, Tooltip, Legend, Title, ArcElement } from "chart.js";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Pie } from "react-chartjs-2";
import dayjs from "dayjs";

// Register chart components
ChartJS.register(ArcElement, Title, Tooltip, Legend);

function DailyProductionChart({
  height = 250,
  statisticsData = null,
  start_date = null,
  end_date = null,
  data_type = null,
}) {
  // Validate date inputs
  const startDate = dayjs(start_date);
  const endDate = dayjs(end_date);

  // Generate labels for the specified period
  const labels = [];
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    labels.push(currentDate.format("DD MMM"));
    currentDate = currentDate.add(1, "day");
  }

  // Helper function to prepare datasets for the specified period
  const getPieChartData = () => {
    // Dynamic custom colors for each company
    const customColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#A6CEE3",
      "#1F78B4",
      "#B2DF8A",
      "#33A02C",
      "#FB9A99",
      "#E31A1C",
      "#FDBF6F",
      "#FF7F00",
      "#CAB2D6",
      "#6A3D9A",
      "#B15928",
      "#8DD3C7",
    ];

    if (
      data_type ===
      "souscriptions_companies_and_intermediaires_chiffresaffaires"
    ) {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize variables for total sums
      let totalCompanyPaid = 0;
      let totalCourtierPaid = 0;

      // Loop through the labels and accumulate the data
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          // Sum company statistics
          matchingData.companyStatistics.forEach((company) => {
            totalCompanyPaid += company.totalSouscriptionsAmountPaid || 0;
          });

          // Sum courtier statistics
          matchingData.courtierStatistics.forEach((courtier) => {
            totalCourtierPaid += courtier.totalSouscriptionsAmountPaid || 0;
          });
        }
      }

      // Return pie chart data
      return {
        labels: [
          "Chiffre d’affaires (Companies)",
          "Chiffre d’affaires (Courtiers)",
        ],
        datasets: [
          {
            data: [totalCompanyPaid, totalCourtierPaid],
            backgroundColor: ["#DA498D", "#08C2FF"],
          },
        ],
      };
    } else if (
      data_type === "souscriptions_companies_and_intermediaires_fraisgestion"
    ) {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize variables for total sums
      let totalCompanyFeesPaid = 0;
      let totalCourtierFeesPaid = 0;

      // Loop through the labels and accumulate the data
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          // Sum company fees
          matchingData.companyStatistics.forEach((company) => {
            totalCompanyFeesPaid +=
              company.totalSouscriptionsFeesAmountPaid || 0;
          });

          // Sum courtier fees
          matchingData.courtierStatistics.forEach((courtier) => {
            totalCourtierFeesPaid +=
              courtier.totalSouscriptionsFeesAmountPaid || 0;
          });
        }
      }

      // Return pie chart data
      return {
        labels: [
          "Frais Totals des Souscriptions (Companies)",
          "Frais Totals des Souscriptions (Courtiers)",
        ],
        datasets: [
          {
            data: [totalCompanyFeesPaid, totalCourtierFeesPaid],
            backgroundColor: ["#80C4E9", "#DA498D"],
          },
        ],
      };
    } else if (
      data_type === "souscriptions_companies_and_intermediaires_vehicules"
    ) {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize variables for total sums
      let totalCompanyCars = 0;
      let totalCourtierCars = 0;

      // Loop through the labels and accumulate the data
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          // Sum company fees
          matchingData.companyStatistics.forEach((company) => {
            totalCompanyCars += company.totalVehiculesCount || 0;
          });

          // Sum courtier fees
          matchingData.courtierStatistics.forEach((courtier) => {
            totalCourtierCars += courtier.totalVehiculesCount || 0;
          });
        }
      }

      // Return pie chart data
      return {
        labels: [
          "Nombre de vehicules (Companies)",
          "Nombre de vehicules (Courtiers)",
        ],
        datasets: [
          {
            data: [totalCompanyCars, totalCourtierCars],
            backgroundColor: ["#80C4E9", "#DA498D"],
          },
        ],
      };
    } else if (data_type === "chiffresaffaire1_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.primeNette;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    //
    else if (data_type === "used_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.attestationsUsedCount;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    //
    else if (data_type === "ordered_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.attestationsOrderedCount;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    //
    else if (data_type === "requested_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.attestationsRequestedCount;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    //
    else if (data_type === "ordered_received_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.attestationsOrderedReceivedCount;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    ///
    else if (data_type === "chiffresaffaire1_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // chiffres 1 / compagnies
    else if (data_type === "chiffresaffaire2_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination; // Company name
          const amount = company.primeNette + company.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // Fees / compangies
    else if (data_type === "fees_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts

      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    //  / total cars
    else if (data_type === "cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    //  / assured cars
    else if (data_type === "assured_cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    //  / echeance cars
    else if (data_type === "echeance_cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // Avenants
    else if (data_type === "avenants_counts") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.avenantStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.count;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // Chiffres 1 / groups
    else if (data_type === "chiffresaffaire1_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // Chiffres 2 / groups
    else if (data_type === "chiffresaffaire2_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette + item.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // Fees / groups
    else if (data_type === "fees_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // cars / groups
    else if (data_type === "cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // assured cars / groups
    else if (data_type === "assured_cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // echeance cars / groups
    else if (data_type === "echeance_cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      // Assign colors based on labels dynamically
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    // AGENCES
    else if (data_type === "chiffresaffaire2_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette + item.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    } else if (data_type === "chiffresaffaire2_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette + item.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // chiffresaffaire1 / agences
    else if (data_type === "chiffresaffaire1_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // fees
    else if (data_type === "fees_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // cars
    else if (data_type === "cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalVehiculesCount || 0;
          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // assured cars
    else if (data_type === "assured_cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalVoituresAssuredCount || 0;
          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    // echeance cars
    else if (data_type === "echeance_cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalSouscriptionsAEcheanceCount || 0;
          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    // INTERMEDIAIRES
    else if (data_type === "chiffresaffaire2_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette + item.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts

      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // chiffres 2
    else if (data_type === "chiffresaffaire1_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.primeNette;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // fees
    else if (data_type === "fees_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // cars
    else if (data_type === "cars_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalVehiculesCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    // assured cars
    else if (data_type === "assured_cars_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalVoituresAssuredCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }
    // echeance cars
    else if (data_type === "echeance_cars_par_intermediaires") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize sums per company
      const companyTotals = {};

      // Loop through each day's data and accumulate totals for each company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination; // Company name
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values for pie chart
      const labels = Object.keys(companyTotals); // Company names
      const dataValues = Object.values(companyTotals); // Total amounts
      const backgroundColors = labels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      return {
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }

    return {};
  };

  const data = getPieChartData();

  // Chart options
  const optionso = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    maintainAspectRatio: false, // Ensure the height is respected
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((sum, value) => sum + value, 0); // Calculate total amount
            const value = dataset.data[tooltipItem.dataIndex]; // Get the current value
            const percentage = ((value / total) * 100).toFixed(2); // Calculate percentage

            return `${value} (${percentage}%)`; // Display value and percentage
          },
        },
      },
    },
    maintainAspectRatio: false, // Ensure the height is respected
  };

  return (
    <Grid>
      <Grid.Column>
        <div style={{ height: `${height}px` }}>
          <Pie data={data} options={options} />
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default DailyProductionChart;
