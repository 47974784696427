import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { calculateSouscriptionDuration } from "../../../utils/souscription_helper";
dayjs.extend(minMax);

const validationSchema = yup.object().shape({
  date_avenant: yup.date().required("La date d'avenant est obligatoire"),
});

function AvenantSuspensionModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);

  const formik = useFormik({
    initialValues: {
      date_avenant: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        date_avenant: values.date_avenant,
      };
      avenantsService
        .createAvenantSuspension(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Suspension effectuée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(souscription_uuid);
      setSouscription(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (souscription_uuid && open === true) {
      fetchSouscription();
    }
  }, [souscription_uuid, open]);

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean" ? setOpen(true) : setOpen("retrait-garantie");
      }}
      open={typeof open === "string" ? open === "retrait-garantie" : open}
      closeIcon
      onUnmount={() => {
        if (open !== true) {
          handleReset();
        }
      }}
      size="small"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de suspension</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui tiny form">
            <Grid divided columns={2}>
              <Grid.Column width={6}>
                {souscription && (
                  <>
                    <Segment raised>
                      <Header as="h5">Informations de la souscription</Header>
                      <List>
                        <List.Item>
                          <List.Icon name="calendar alternate" />
                          <List.Content>
                            <List.Header>Date de prise d'effet</List.Header>
                            <List.Description>
                              {dayjs(
                                souscription?.conditions_particulieres
                                  ?.date_effet
                              ).format("DD/MM/YYYY")}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name="calendar alternate" />
                          <List.Content>
                            <List.Header>Date d'échéance</List.Header>
                            <List.Description>
                              {dayjs(
                                souscription?.conditions_particulieres
                                  ?.date_echeance
                              ).format("DD/MM/YYYY")}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name="calendar alternate" />
                          <List.Content>
                            <List.Header>Durée du contrat</List.Header>
                            <List.Description>
                              {calculateSouscriptionDuration(
                                souscription?.conditions_particulieres
                              )}{" "}
                              jours
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Segment>
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={10}>
                <Segment raised>
                  <Form.Field>
                    <label>Date de l'avenant</label>
                    <Input
                      type="date"
                      min={dayjs(
                        souscription?.conditions_particulieres?.date_effet
                      ).format("YYYY-MM-DD")}
                      max={dayjs(
                        souscription?.conditions_particulieres?.date_echeance
                      ).format("YYYY-MM-DD")}
                      name="date_avenant"
                      value={values.date_avenant}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.date_avenant}
                    />
                  </Form.Field>
                  <Message>
                    <Message.Content>
                      <p>
                        La mise en vigueur de l'avenant est fixée au lendemain
                        de la date de l'avenant.
                      </p>
                    </Message.Content>
                  </Message>
                </Segment>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              values.date_avenant === "" ||
              values.date_avenant <
                dayjs(
                  souscription?.conditions_particulieres?.date_effet
                ).format("YYYY-MM-DD") ||
              values.date_avenant >
                dayjs(
                  souscription?.conditions_particulieres?.date_echeance
                ).format("YYYY-MM-DD")
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default AvenantSuspensionModal;
