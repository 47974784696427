import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DaylyProductionChart({
  height = 100,
  statisticsData = null,
  start_date = null,
  end_date = null,
  data_type = null,
}) {
  const labels = [];

  // Validate date inputs
  const startDate = dayjs(start_date);
  const endDate = dayjs(end_date);

  // Generate labels for the specified period
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    labels.push(currentDate.format("DD MMM"));
    currentDate = currentDate.add(1, "day");
  }

  // Helper function to prepare datasets for the specified period
  const getDatasets = () => {
    console.log("HEY ", data_type);
    if (data_type == "souscriptions_infos") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize arrays for datasets
      const totalSouscriptionsCountValues = [];
      const totalPaidSouscriptionsCountValues = [];
      const totalUnPaidSouscriptionsCountValues = [];
      const totalResiliatedSouscriptionsCountValues = [];
      const totalSuspendedSouscriptionsCountValues = [];

      // Populate dataset values based on the specified period
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          totalSouscriptionsCountValues.push(
            matchingData.totalSouscriptionsCount || 0
          );
          totalPaidSouscriptionsCountValues.push(
            matchingData.totalPaidSouscriptionsCount || 0
          );
          totalUnPaidSouscriptionsCountValues.push(
            matchingData.totalUnPaidSouscriptionsCount || 0
          );
          totalResiliatedSouscriptionsCountValues.push(
            matchingData.totalResiliatedSouscriptionsCount || 0
          );
          totalSuspendedSouscriptionsCountValues.push(
            matchingData.totalSuspendedSouscriptionsCount || 0
          );
        } else {
          // Fill missing data with 0
          totalSouscriptionsCountValues.push(0);
          totalPaidSouscriptionsCountValues.push(0);
          totalUnPaidSouscriptionsCountValues.push(0);
          totalResiliatedSouscriptionsCountValues.push(0);
          totalSuspendedSouscriptionsCountValues.push(0);
        }
      }

      // Return datasets for the line chart
      return [
        {
          label: "Souscriptions Totales",
          data: totalSouscriptionsCountValues,
          borderColor: "#2196F3", // Blue
          backgroundColor: "rgba(33, 150, 243, 0.2)", // Transparent fill
          fill: true,
          tension: 0.4,
        },
        {
          label: "Souscriptions Payées",
          data: totalPaidSouscriptionsCountValues,
          borderColor: "#81C784", // Vert pastel
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          fill: true,
          tension: 0.4,
        },
        {
          label: "Souscriptions Impayées",
          data: totalUnPaidSouscriptionsCountValues,
          borderColor: "#F44336", // Red
          backgroundColor: "rgba(244, 67, 54, 0.2)",
          fill: true,
          tension: 0.4,
        },
        {
          label: "Souscriptions Résiliées",
          data: totalResiliatedSouscriptionsCountValues,
          borderColor: "#81C784", // Orange
          backgroundColor: "rgba(255, 152, 0, 0.2)",
          fill: true,
          tension: 0.4,
        },
        {
          label: "Souscriptions Suspendues",
          data: totalSuspendedSouscriptionsCountValues,
          borderColor: "#9E9E9E", // Grey
          backgroundColor: "rgba(158, 158, 158, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ];
    } else if (
      data_type == "souscriptions_companies_and_intermediaires_chiffresaffaires"
    ) {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize arrays for datasets
      const companyTotalPaidValues = [];
      const courtierTotalPaidValues = [];

      // Populate dataset values based on the specified period
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          let totalCompanyPaid = 0;
          let totalCourtierPaid = 0;

          // Sum company statistics
          matchingData.companyStatistics.forEach((company) => {
            totalCompanyPaid += company.totalSouscriptionsAmountPaid || 0;
          });

          // Sum courtier statistics
          matchingData.courtierStatistics.forEach((courtier) => {
            totalCourtierPaid += courtier.totalSouscriptionsAmountPaid || 0;
          });

          companyTotalPaidValues.push(totalCompanyPaid);
          courtierTotalPaidValues.push(totalCourtierPaid);
        } else {
          // Fill missing data with 0
          companyTotalPaidValues.push(0);
          courtierTotalPaidValues.push(0);
        }
      }

      // Return datasets for the line chart
      return [
        {
          label: "Chiffre d’affaires (Companies)",
          data: companyTotalPaidValues,
          borderColor: "#DA498D", //
          backgroundColor: "rgba(33, 150, 243, 0.2)", // Transparent fill
          fill: true,
          tension: 0.4,
        },
        {
          label: "Chiffre d’affaires (Courtiers)",
          data: courtierTotalPaidValues,
          borderColor: "#08C2FF", // Gray
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ];
    } else if (
      data_type == "souscriptions_companies_and_intermediaires_fraisgestion"
    ) {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize arrays for datasets
      const companyTotalFeesPaidValues = [];
      const courtierTotalFeesPaidValues = [];

      // Populate dataset values based on the specified period
      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          let totalCompanyFeesPaid = 0;
          let totalCourtierFeesPaid = 0;

          // Sum company fees
          matchingData.companyStatistics.forEach((company) => {
            totalCompanyFeesPaid +=
              company.totalSouscriptionsFeesAmountPaid || 0;
          });

          // Sum courtier fees
          matchingData.courtierStatistics.forEach((courtier) => {
            totalCourtierFeesPaid +=
              courtier.totalSouscriptionsFeesAmountPaid || 0;
          });

          companyTotalFeesPaidValues.push(totalCompanyFeesPaid);
          courtierTotalFeesPaidValues.push(totalCourtierFeesPaid);
        } else {
          // Fill missing data with 0
          companyTotalFeesPaidValues.push(0);
          courtierTotalFeesPaidValues.push(0);
        }
      }

      // Return datasets for the line chart
      return [
        {
          label: "Frais Totals des Souscriptions (Companies)",
          data: companyTotalFeesPaidValues,
          borderColor: "#80C4E9", //
          backgroundColor: "rgba(33, 150, 243, 0.2)", // Transparent fill
          fill: true,
          tension: 0.4,
        },
        {
          label: "Frais Totals des Souscriptions (Courtiers)",
          data: courtierTotalFeesPaidValues,
          borderColor: "#DA498D", //
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ];
    } else if (data_type === "souscriptions_chiffresaffaires_and_fees") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize arrays for datasets
      const totalAmountPaidValues = [];
      const totalFeesPaidValues = [];

      for (let label of labels) {
        const matchingData = productionData.find(
          (item) => dayjs(item.day).format("DD MMM") === label
        );

        if (matchingData) {
          totalAmountPaidValues.push(
            matchingData.totalSouscriptionsAmountPaid || 0
          );
          totalFeesPaidValues.push(
            matchingData.totalSouscriptionsFeesAmountPaid || 0
          );
        } else {
          totalAmountPaidValues.push(0);
          totalFeesPaidValues.push(0);
        }
      }

      return [
        {
          label: "Chiffre d’affaires Total",
          data: totalAmountPaidValues,
          borderColor: "#4A90E2", // Blue
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          fill: true,
          tension: 0.4,
        },
        {
          label: "Frais de Gestion",
          data: totalFeesPaidValues,
          borderColor: "#81C784", // Orange
          backgroundColor: "rgba(245, 166, 35, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ];
    } else if (data_type == "paid_souscriptions_count") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize arrays for labels and dataset
      const labels = [];
      const totalPaidSouscriptionsCountValues = [];

      // Loop through the production data to extract `day` and calculate paid souscriptions
      productionData.forEach((item) => {
        const dayLabel = dayjs(item.day).format("DD MMM"); // Format day as needed
        labels.push(dayLabel); // Add the formatted day to labels

        let totalPaidSouscriptions = item.totalPaidSouscriptionsCount;

        // Push the calculated value to the dataset
        totalPaidSouscriptionsCountValues.push(totalPaidSouscriptions);
      });

      // Return the updated datasets and labels
      return {
        labels, // Dynamic labels from data
        datasets: [
          {
            label: "Souscriptions Payées",
            data: totalPaidSouscriptionsCountValues,
            borderColor: "#81C784", // Gray
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            fill: true,
            tension: 0.4,
          },
        ],
      };
    } else if (data_type == "attestations_used_count") {
      const productionData =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize arrays for labels and dataset
      const labels = [];
      const attestationsUsedCountValues = [];

      // Loop through the production data to extract `day` and calculate paid souscriptions
      productionData.forEach((item) => {
        const dayLabel = dayjs(item.day).format("DD MMM"); // Format day as needed
        labels.push(dayLabel); // Add the formatted day to labels

        let attestationsUsedCount = item.attestationsUsedCount;

        // Push the calculated value to the dataset
        attestationsUsedCountValues.push(attestationsUsedCount);
      });

      // Return the updated datasets and labels
      return {
        labels, // Dynamic labels from data
        datasets: [
          {
            label: "",
            data: attestationsUsedCountValues,
            borderColor: "#81C784", // Gray
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            fill: true,
            tension: 0.4,
          },
        ],
      };
    }

    return [];
  };

  // Retrieve the chart data
  const chartData = getDatasets();

  // Chart data configuration
  const data = {
    labels: chartData.labels, // Use labels returned from getDatasets()
    datasets: chartData.datasets, // Use datasets returned from getDatasets()
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
        //position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return (
    <Fragment>
      <Line data={data} height={height} options={options} />
    </Fragment>
  );
}

export default DaylyProductionChart;
