import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  GridRow,
  Header,
  Input,
  Button,
  Segment,
  Dimmer,
  Loader,
  Form,
  Divider,
} from "semantic-ui-react";
import DaylyProductionChart from "../../components/charts/DaylyProductionLineChart";
import DaylyProductionPieChart from "../../components/charts/DaylyProductionPieChart";
import DaylyProductionBarChart from "../../components/charts/DaylyProductionBarChart";
import ChartRow from "../../components/charts/ChartRow";
import ValidatedSouscriptionsChart from "../../components/charts/ValidatedSouscriptionsChart";
import { authState } from "../../atoms/authState";
import Page from "../../components/Page";
import { useFormik } from "formik";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import * as statisticsService from "../../services/statisticsService";
import dayjs from "dayjs";
import currency from "currency.js";
import { USERS_ROLE_ENUMS } from "../../constants";
import styles from "../../styles/dashboard.module.css";

const validationSchema = yup.object().shape({
  company: yup.number().notRequired(),
  start_date: yup.date().required("La selection de date est obligatoire"),
  end_date: yup.date().required("La selection de date est obligatoire"),
});

function Dashboard() {
  const [statisticsData, setStatisticsData] = useState();
  const [intermediare, setIntermediaire] = useState();
  const [agency, setAgency] = useState();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([
    {
      key: "Toutes les compagnies",
      text: "Toutes les compagnies",
      value: 0,
    },
  ]);
  const [intermediaires, setIntermediaires] = useState([
    {
      key: "Toutes les intermediaires",
      text: "Toutes les intermediaires",
      value: 0,
    },
  ]);
  const [agencies, setAgencies] = useState([
    {
      key: "Toutes les agences",
      text: "Toutes les agences",
      value: 0,
    },
  ]);
  const [entities, setEntities] = useState();
  const authValue = useRecoilValue(authState);

  // Get default dates
  const today = dayjs().format("YYYY-MM-DD"); // Current date
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD"); // First day of the current month
  const lastDayOfMonth = dayjs().endOf("month").format("YYYY-MM-DD"); // Last day of the current month

  const formik = useFormik({
    initialValues: {
      start_date: firstDayOfMonth, // Default to the first day of the current month
      end_date: lastDayOfMonth, // Default to today
      company: 0, // Default to today
      intermediaire: 0, // Default to today
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchStatisticsData = async () => {
    setStatisticsData();
    try {
      let requestData = {
        type: "PRODUCTION",
        start_date: formik.values.start_date,
        end_date: formik.values.end_date,
      };
      if (formik.values.company) {
        requestData.company_id = formik.values.company;
      }
      if (formik.values.intermediaire) {
        requestData.intermediaire_id = formik.values.intermediaire;
      }
      setLoading(true);
      const response = await statisticsService.fetchStatistics(requestData);
      let data = response.data;
      setLoading(false);
      if (response.status === 200 && data) {
        setStatisticsData(data);

        if (data?.production?.entities?.companies) {
          const formattedCompanies = data.production.entities.companies.map(
            (company) => ({
              key: `comp-${company.idcompagnies}`, // Unique key
              text: company.denomination, // Display text
              value: company.idcompagnies, // Value
            })
          );

          // Add default 'N/A' option
          setCompanies([
            {
              key: "Toutes les compagnies",
              text: "Toutes les compagnies",
              value: 0,
            },
            // ...formattedCompanies,
          ]);
        }

        if (data?.production?.entities?.courtiers) {
          const formattedIntermediaires =
            data.production.entities.courtiers.map((courtier) => ({
              key: `court-${courtier.idintermediaires}`, // Unique key
              text: courtier.denomination, // Display text
              value: courtier.idintermediaires, // Value
            }));

          // Add default 'N/A' option
          setIntermediaires([
            {
              key: "Tous les intermediaires",
              text: "Tous les intermediaires",
              value: 0,
            },
            //  ...formattedIntermediaires,
          ]);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCompaniesData = () => {
    let companies = entities?.companies;
    let newItems = [
      {
        key: "Toutes les compagnies",
        text: "Toutes les compagnies",
        value: 0,
      },
    ];
    if (companies?.length) {
      const formattedCompanies = companies.map((company) => ({
        key: `comp-${company.idcompagnies}`, // Unique key
        text: company.denomination, // Display text
        value: company.idcompagnies, // Value
      }));

      // Add default 'N/A' option

      newItems = [
        {
          key: "Toutes les compagnies",
          text: "Toutes les compagnies",
          value: 0,
        },
        ...formattedCompanies,
      ];
      //setCompanies(newItems);
    }
    return newItems;
  };

  const getIntermediairesData = () => {
    let intermediaires = entities?.intermediaires;
    let newItems = [
      {
        key: "Toutes les intermediares",
        text: "Toutes les intermediares",
        value: 0,
      },
    ];
    if (intermediaires?.length) {
      const formattedItems = intermediaires.map((item) => ({
        key: `inter-${item.idintermediaires}`, // Unique key
        text: item.denomination, // Display text
        value: item.idintermediaires, // Value
      }));

      newItems = [
        {
          key: "Toutes les intermediares",
          text: "Toutes les intermediares",
          value: 0,
        },
        ...formattedItems,
      ];
      //setIntermediaires(newItems);
    }
  };

  const getAgenciesData = () => {
    let agencies = entities?.agencies;
    let newItems = [
      {
        key: "Toutes les agences",
        text: "Toutes les agences",
        value: 0,
      },
    ];
    if (agencies?.length) {
      const formattedItems = agencies.map((item) => ({
        key: `inter-${
          item.idcompagnies_agencies
            ? item?.idcompagnies_agencies
            : item?.idintermediaires_agencies
        }`, // Unique key
        text: item.denomination, // Display text
        value: item.idcompagnies_agencies
          ? item?.idcompagnies_agencies
          : item?.idintermediaires_agencies, // Value
      }));

      newItems = [
        {
          key: "Toutes les agences",
          text: "Toutes les agences",
          value: 0,
        },
        ...formattedItems,
      ];

      //setAgencies(newItems);
    }
    return newItems;
  };

  const fetchStatisticsEntities = async () => {
    try {
      let requestData = {};
      const response = await statisticsService.fetchStatisticsEntities(
        requestData
      );
      let data = response.data;

      if (response.status === 200 && data) {
        setEntities(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchStatisticsData();
    fetchStatisticsEntities();
  }, []);

  return (
    <Page title="Dashboard">
      <GridRow className="DashBoard">
        <Grid stackable>
          <Grid.Column
            width={5}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Header>Tableau de bord</Header>
          </Grid.Column>

          <Grid.Column
            width={11}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Form>
              <Form.Group>
                <Form.Field error={"start_date" in errors}>
                  <label>Date de début</label>
                  <Input
                    type="date"
                    value={values.start_date}
                    onChange={handleChange("start_date")}
                    onBlur={handleBlur("start_date")}
                  />
                  <small className="field-error">{errors.start_date}</small>
                </Form.Field>

                <Form.Field error={"end_date" in errors}>
                  <label>Date de fin</label>
                  <Input
                    type="date"
                    value={values.end_date}
                    onChange={handleChange("end_date")}
                    onBlur={handleBlur("end_date")}
                  />
                  <small className="field-error">{errors.end_date}</small>
                </Form.Field>
                {[
                  USERS_ROLE_ENUMS.POOL_USER,
                  USERS_ROLE_ENUMS.ROOT_ADMIN,
                ].includes(authValue?.user?.user_role) ? (
                  <>
                    <Form.Field error={"company" in errors}>
                      <label>Compagnie d'assurance</label>
                      <Form.Select
                        search
                        value={values.company}
                        fluid
                        onChange={(e, data) =>
                          setFieldValue("company", data.value)
                        }
                        placeholder="Choisr..."
                        options={getCompaniesData()}
                      />
                    </Form.Field>
                    {/*<Form.Field error={"intermediaire" in errors}>
                      <label>Intermediaire (Courtier)</label>
                      <Form.Select
                        search
                        value={values.intermediaire}
                        fluid
                        onChange={(e, data) =>
                          setFieldValue("intermediaire", data.value)
                        }
                        placeholder="Choisr..."
                        options={intermediaires}
                      />
                    </Form.Field>*/}
                    <Form.Field error={"company" in errors}>
                      <label></label>

                      <Button
                        icon="search"
                        disabled={loading}
                        onClick={() => {
                          fetchStatisticsData();
                        }}
                      />
                    </Form.Field>
                  </>
                ) : null}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>

        {/*
        

        */}
        {loading ? (
          <Segment
            loading={loading}
            style={{ height: "100px", border: "none", boxShadow: "none" }}
          ></Segment>
        ) : null}

        {statisticsData && (
          <>
            <Grid>
              <Grid.Column width={16}>
                <ChartRow
                  header_label="Nombre de production"
                  data={statisticsData}
                  header_value={
                    statisticsData?.production?.meta
                      ?.totalPaidSouscriptionsCount || 0
                  }
                  data_type="paid_souscriptions_count"
                  chart_type="line"
                />

                {!formik.values.company ? (
                  <>
                    <ChartRow
                      header_label="Chiffre d'affaire (Sans garantie facultative) par compagnies"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta?.primeNette || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="chiffresaffaire1_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="chiffresaffaire1_par_compagnies"
                    />

                    <ChartRow
                      header_label="Chiffre d'affaire (Avec garantie facultative) par compagnies"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta?.primeNette +
                          statisticsData?.production?.meta?.primeNetteIac || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="chiffresaffaire2_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="chiffresaffaire2_par_compagnies"
                    />

                    <ChartRow
                      header_label="Frais de gestion du pool"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsFeesAmountPaid || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="fees_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="fees_par_compagnies"
                    />
                    <ChartRow
                      header_label="Nombre total de vehicules"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta?.totalVoituresCount ||
                        0
                      }
                      data_type="cars_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="cars_par_compagnies"
                    />
                    <ChartRow
                      header_label="Nombre de véhicules assurées"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta
                          ?.totalVoituresAssuredCount || 0
                      }
                      data_type="assured_cars_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="assured_cars_par_compagnies"
                    />

                    <ChartRow
                      header_label="Nombre de véhicules à l’échéance"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsAEcheanceCount || 0
                      }
                      data_type="echeance_cars_par_compagnies"
                      chart_type="bar"
                      pie_chart_data_type="echeance_cars_par_compagnies"
                    />
                  </>
                ) : null}
                {formik.values.company && !intermediare && !agency ? (
                  <>
                    <ChartRow
                      header_label="Chiffre d'affaire (Sans garantie facultative)"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta?.primeNette || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="chiffresaffaire1_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="chiffresaffaire1_par_groups"
                    />

                    <ChartRow
                      header_label="Chiffre d'affaire (Avec garantie facultative)"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta?.primeNette +
                          statisticsData?.production?.meta?.primeNetteIac || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="chiffresaffaire2_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="chiffresaffaire2_par_groups"
                    />

                    <ChartRow
                      header_label="Frais de gestion du pool"
                      data={statisticsData}
                      header_value={currency(
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsFeesAmountPaid || 0,
                        { separator: " ", symbol: "" }
                      ).format()}
                      data_type="fees_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="fees_par_groups"
                    />

                    <ChartRow
                      header_label="Nombre total de vehicules"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta?.totalVoituresCount ||
                        0
                      }
                      data_type="cars_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="cars_par_groups"
                    />

                    <ChartRow
                      header_label="Nombre de véhicules assurée"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta
                          ?.totalVoituresAssuredCount || 0
                      }
                      data_type="assured_cars_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="assured_cars_par_groups"
                    />

                    <ChartRow
                      header_label="Nombre de véhicules à l’échéance"
                      data={statisticsData}
                      header_value={
                        statisticsData?.production?.meta
                          ?.totalSouscriptionsAEcheanceCount || 0
                      }
                      data_type="echeance_cars_par_groups"
                      chart_type="bar"
                      pie_chart_data_type="echeance_cars_par_groups"
                    />
                  </>
                ) : null}
                <ChartRow
                  header_label="Nombre d'avenants sur les contrats d'assurance"
                  data={statisticsData}
                  header_value={
                    statisticsData?.production?.meta?.totalAvenantsCount || 0
                  }
                  data_type="avenants_counts"
                  chart_type="bar"
                  pie_chart_data_type="avenants_counts"
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </GridRow>
    </Page>
  );
}

export default Dashboard;
