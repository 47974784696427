import { useEffect } from "react";
import { getByActiveDirectivesTarifaires } from "../../services/gestion_production/tableauGarantiesRcService";

const useCalculateSouscription = (
  values,
  setFieldValue,
  insuranceDurations,
  directivesTarifairesValues,
  companyDirectiveValues,
  intermediaireType,
  open = undefined
) => {
  const calculateData = async () => {
    try {
      let primeNetteAuto = 0;
      let primeNetteIAC = 0;
      let primeNetteTotale = 0;
      let fraisGestionPool = 0;
      let coutPolice = 0;
      let taxeContratAssurance = 0;
      let carteRose = 0;
      let droitTimbreAuto = 0;
      let totaleToutesGaranties = 0;
      let commisions = 0;

      let taux_commision = companyDirectiveValues?.find(
        (item) => item.name === "commision"
      )?.value;

      // Calcul de la prime nette automobile (A)
      if (
        !values.identite_vehicule?.nombre_places ||
        !values.identite_vehicule?.puissance_fiscale ||
        !values.identite_vehicule?.idsources_energie ||
        isNaN(Number(values.identite_vehicule.nombre_places)) ||
        isNaN(Number(values.identite_vehicule.puissance_fiscale)) ||
        isNaN(Number(values.identite_vehicule.idsources_energie))
      ) {
        console.table({
          nombre_places: values.identite_vehicule?.nombre_places,
          puissance_fiscale: values.identite_vehicule?.puissance_fiscale,
          idsources_energie: values.identite_vehicule?.idsources_energie
        });
        throw new Error("Identité du véhicule invalide");
      }

      const response = await getByActiveDirectivesTarifaires({
        cv: Number(values.identite_vehicule.puissance_fiscale),
        places: Number(values.identite_vehicule.nombre_places),
        id_source_energie: values.identite_vehicule.idsources_energie,
      });

      if (response?.status !== 200 || !response?.data) {
        throw new Error(
          "Erreur lors de la récupération des directives tarifaires"
        );
      }

      const primeNetteAutoAnnuelle = Number(response.data.value);

      let insuranceDuration = insuranceDurations.find(
        (duration) =>
          duration.uuid === values.conditions_particulieres.type_renouvellement
      );

      console.log("insuranceDuration", insuranceDuration);
      if (!insuranceDuration) {
        setFieldValue("type_renouvellement", "", false);
        throw new Error("Type de renouvellement invalide");
      }

      primeNetteAuto = Math.round(
        (primeNetteAutoAnnuelle * insuranceDuration?.percentage) / 100
      );

      // Calcul de la prime nette IAC  (B)
      primeNetteIAC = Number(values.prime_nette_iac);

      // Calcul des total des garanties  (C)
      const totalGaranties = values?.garanties?.reduce((acc, garantie) => {
        garantie.risques.forEach((risque) => {
          acc += Number(risque.value);
        });
        console.log("garantie", garantie);
        return acc;
      }, 0);

      console.log("totalGaranties", totalGaranties);

      const totalGarantiesComplementaires =
        values?.garanties_complementaires?.reduce((acc, garantie) => {
          return acc + Number(garantie.value);
        }, 0) || 0;

      console.log(
        "totalGarantiesComplementaires",
        totalGarantiesComplementaires
      );

      totaleToutesGaranties =
        Number(totalGaranties) + Number(totalGarantiesComplementaires);

      // Calcule de la prime nette totale (D)=> (A+B+C)
      primeNetteTotale =
        Number(primeNetteAuto) +
        Number(primeNetteIAC) +
        Number(totaleToutesGaranties);

      console.log("primeNetteTotale", primeNetteTotale);

      // Calcule des frais de gestion du pool (E)=> (A + B) * frais_gestion_pool
      fraisGestionPool = Math.round(
        (directivesTarifairesValues.find(
          (item) => item.name === "frais_gestion_pool"
        ).value /
          100) *
          (primeNetteAuto + primeNetteIAC)
      );

      console.log("fraisGestionPool", fraisGestionPool);

      //Cout de la police (F)
      coutPolice = Number(values.cout_police);

      // Calcul des taxes (G) => (D + E + F) * taux_taxe_contrat_assurance
      taxeContratAssurance = Math.round(
        (directivesTarifairesValues.find(
          (item) => item.name === "taxe_contrat_assurance"
        ).value /
          100) *
          (primeNetteTotale + fraisGestionPool + coutPolice)
      );

      console.log("taxeContratAssurance", taxeContratAssurance);

      // Carte rose (H)
      carteRose = Number(values.carte_rose);

      console.log("carteRose", carteRose);

      // Droit de timbre (I)
      droitTimbreAuto = Number(values.droit_de_timbre_automobile);

      // Total prime nette (J) => (D + E + F + G + H + I)
      const totalPrimeNetteTTC =
        primeNetteTotale +
        fraisGestionPool +
        coutPolice +
        taxeContratAssurance +
        carteRose +
        droitTimbreAuto;

      console.log("totalPrimeNetteTTC", totalPrimeNetteTTC);

      //Calcul de la commission
      if (["COURTIER", "AGENT_GENERAL"].includes(intermediaireType)) {
        commisions = (taux_commision / 100) * primeNetteTotale;

        setFieldValue("montant_commission", Math.round(commisions), true);
      }

      console.log("commisions", commisions);

      // set values

      setFieldValue(
        "prime_nette",
        isNaN(parseFloat(primeNetteAuto)) ? 0 : Math.ceil(primeNetteAuto),
        true
      );

      console.log("primeNetteAuto", primeNetteAuto);

      setFieldValue(
        "taxes",
        isNaN(parseFloat(taxeContratAssurance))
          ? 0
          : Math.round(taxeContratAssurance),
        true
      );

      console.log("taxeContratAssurance", taxeContratAssurance);

      setFieldValue(
        "montant_gestion_pool",
        isNaN(parseFloat(fraisGestionPool)) ? 0 : Math.round(fraisGestionPool),
        true
      );

      console.log("fraisGestionPool", fraisGestionPool);

      setFieldValue(
        "prime_nette_totale",
        isNaN(parseFloat(primeNetteTotale)) ? 0 : Math.round(primeNetteTotale),
        true
      );

      console.log("primeNetteTotale", primeNetteTotale);

      setFieldValue("prime_ttc", totalPrimeNetteTTC);
      console.log("totalPrimeNetteTTC", totalPrimeNetteTTC);
    } catch (error) {
      console.log(error?.response?.data);
      return 0;
    }
  };

  useEffect(() => {
    if (open === undefined || open === true) {
      console.log("open", open);
      calculateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.identite_vehicule?.nombre_places,
    values.identite_vehicule?.puissance_fiscale,
    values.cout_police,
    values.identite_vehicule?.idsources_energie,
    values.conditions_particulieres?.type_renouvellement,
    values.garanties,
    values.garanties_complementaires,
    values.identite_vehicule?.idsources_energie,
    values.identite_vehicule?.nombre_places,
    values.identite_vehicule?.puissance_fiscale,
    values.identite_vehicule?.date_mise_en_circulation,
    values.identite_vehicule?.valeur_a_neuf,
    values.identite_vehicule?.valeur_venale,
    open,
  ]);

  return calculateData;
};

export default useCalculateSouscription;
